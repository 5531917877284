// components/admin/FamilyForm.js
import React, { useState, useEffect } from "react";
import { fetchMaterials, fetchTechnicalTypes } from "../../api";
import { materialManager } from "../../materialManager";
import TypeLinksComponent from "./TypeLinksComponent";

const FamilyForm = ({ category, family, isEditing, onSave, onCancel }) => {
  // Material management state
  const [allMaterials, setAllMaterials] = useState([]);
  const [collections, setCollections] = useState([]);
  const [customTypes, setCustomTypes] = useState([]);
  const [activeType, setActiveType] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState("all");
  const [typeLinks, setTypeLinks] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    defaultMaterials: {},
    typeLinks: [], // Initialize typeLinks in formData too
  });
  // UI state
  const [loading, setLoading] = useState(false);
  const [showTypeForm, setShowTypeForm] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);
  const [editingTypeIndex, setEditingTypeIndex] = useState(null);
  const [view, setView] = useState("types"); // types, typeLinks

  // Type form data
  const [typeFormData, setTypeFormData] = useState({
    technicalName: "",
    displayLabel: "",
    materials: [],
    standardMaterials: [],
    optionalMaterials: [],
  });

  // Import form data
  const [importJson, setImportJson] = useState("");

  // Initialize form data if editing
  useEffect(() => {
    if (isEditing && family) {
      setFormData({
        name: family,
        defaultMaterials: {},
        typeLinks: [], // Initialize typeLinks array
      });

      // Load existing family defaults
      loadFamilyDefaults();
    }

    // Load all materials and collections
    loadAllMaterials();
  }, [isEditing, family, category]);

  // Load all available materials
  const loadAllMaterials = async () => {
    setLoading(true);
    try {
      const materialsData = await fetchMaterials();
      setAllMaterials(materialsData.materials || []);

      // Extract unique collections
      const uniqueCollections = new Set();
      materialsData.materials.forEach((material) => {
        if (material.collection) {
          uniqueCollections.add(material.collection);
        }
      });
      setCollections(Array.from(uniqueCollections).sort());

      // Initialize material manager with all materials if needed
      if (materialManager.allMaterials.length === 0) {
        materialManager.initializeAllMaterials();
      }
    } catch (error) {
      console.error("Failed to load materials:", error);
    } finally {
      setLoading(false);
    }
  };

  // Load existing family defaults (for editing)
  const loadFamilyDefaults = async () => {
    setLoading(true);
    try {
      if (isEditing && family) {
        const types = [];
        let familyTypeLinks = [];

        // Debug info
        console.log(`Loading family defaults for family "${family}"`);
        console.log(
          "All stored family settings:",
          materialManager.familySettings
        );

        // Check in a case-insensitive way
        let exactMatch = materialManager.familySettings[family];

        if (!exactMatch) {
          // Try to find a match with different case
          const familyLowerCase = family.toLowerCase();
          for (const storedFamily in materialManager.familySettings) {
            if (storedFamily.toLowerCase() === familyLowerCase) {
              exactMatch = materialManager.familySettings[storedFamily];
              console.log(
                `Found family match using case-insensitive comparison: "${storedFamily}"`
              );
              break;
            }
          }
        }

        // If no match found in materialManager, try to find the family in the products data
        if (!exactMatch) {
          console.log(
            "Family not found in materialManager, checking products..."
          );
          try {
            const response = await fetch(
              `/api-proxy/products.php?family=${encodeURIComponent(family)}`
            );
            if (response.ok) {
              const familyData = await response.json();
              console.log("Found family data from API:", familyData);

              if (familyData && familyData.family) {
                // Use API data
                if (familyData.family.defaultMaterials) {
                  console.log("Using family defaults from API response");
                  exactMatch = {
                    types: familyData.family.defaultMaterials,
                    typeLinks: familyData.family.typeLinks || [],
                  };
                }
              }
            }
          } catch (apiError) {
            console.error("Error fetching family data from API:", apiError);
          }
        }

        // If we found settings
        if (exactMatch) {
          console.log(`Loaded family settings:`, exactMatch);

          // Load type links if available
          if (exactMatch.typeLinks && Array.isArray(exactMatch.typeLinks)) {
            familyTypeLinks = exactMatch.typeLinks;
            console.log(`Loaded ${familyTypeLinks.length} type links`);
          }

          // Load material types from JSON structure
          if (exactMatch.types) {
            console.log(
              "Processing types from family settings:",
              Object.keys(exactMatch.types)
            );

            for (const [techName, config] of Object.entries(exactMatch.types)) {
              console.log(`Processing type "${techName}":`, config);

              const standardMaterialNames = (config.standard || []).map(
                (slug) => {
                  const material = materialManager.findMaterialBySlug(slug);
                  return material ? material.name : slug;
                }
              );

              const optionalMaterialNames = (config.optional || []).map(
                (slug) => {
                  const material = materialManager.findMaterialBySlug(slug);
                  return material ? material.name : slug;
                }
              );

              types.push({
                technicalName: techName,
                displayLabel: config.label || techName,
                materials: [...standardMaterialNames, ...optionalMaterialNames],
                standardMaterials: standardMaterialNames,
                optionalMaterials: optionalMaterialNames,
              });

              console.log(
                `Added type "${techName}" with ${standardMaterialNames.length} standard and ${optionalMaterialNames.length} optional materials`
              );
            }
          } else {
            console.warn(`No types found in family settings for "${family}"`);
          }
        } else {
          // If no settings found, create example data
          console.warn(
            `No family settings found for "${family}", creating default type`
          );

          // Create a default type
          types.push({
            technicalName: "struttura",
            displayLabel: "Structure",
            materials: ["V1 Pure White", "V38 Signal Black"],
            standardMaterials: ["V1 Pure White"],
            optionalMaterials: ["V38 Signal Black"],
          });
        }

        setCustomTypes(types);

        // Set typeLinks in state
        setTypeLinks(familyTypeLinks);

        // Format as default materials for the form data
        const defaultMaterials = {};
        types.forEach((type) => {
          defaultMaterials[type.technicalName] = {
            label: type.displayLabel,
            standard: type.standardMaterials.map((name) => {
              const material = materialManager.findMaterialByName(name);
              return material
                ? material.slug
                : materialManager.generateSlug(name);
            }),
            optional: type.optionalMaterials.map((name) => {
              const material = materialManager.findMaterialByName(name);
              return material
                ? material.slug
                : materialManager.generateSlug(name);
            }),
          };
        });

        setFormData((prev) => ({
          ...prev,
          defaultMaterials,
          typeLinks: familyTypeLinks,
        }));

        // Set active type if we have any
        if (types.length > 0) {
          setActiveType(types[0].technicalName);
        }
      } else {
        // Just set some example custom types for new families
        const types = [
          {
            technicalName: "struttura",
            displayLabel: "Structure",
            materials: ["V1 Pure White", "V38 Signal Black"],
            standardMaterials: ["V1 Pure White"],
            optionalMaterials: ["V38 Signal Black"],
          },
        ];

        setCustomTypes(types);

        // Initialize empty typeLinks
        setTypeLinks([]);

        // Set active type
        setActiveType(types[0].technicalName);
      }
    } catch (error) {
      console.error("Failed to load family defaults:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle basic form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle creating a new type
  const handleAddType = () => {
    setEditingTypeIndex(null);
    setTypeFormData({
      technicalName: "",
      displayLabel: "",
      materials: [],
      standardMaterials: [],
      optionalMaterials: [],
    });
    setShowTypeForm(true);
  };

  // Handle editing an existing type
  const handleEditType = (index) => {
    const type = customTypes[index];
    setEditingTypeIndex(index);
    setTypeFormData({
      technicalName: type.technicalName,
      displayLabel: type.displayLabel,
      materials: type.materials,
      standardMaterials: type.standardMaterials,
      optionalMaterials: type.optionalMaterials,
    });
    setShowTypeForm(true);
  };

  // Handle deleting a type
  const handleDeleteType = (index) => {
    if (!window.confirm("Are you sure you want to delete this type?")) return;

    const updatedTypes = [...customTypes];
    updatedTypes.splice(index, 1);
    setCustomTypes(updatedTypes);

    // Update form data
    const updatedDefaultMaterials = { ...formData.defaultMaterials };
    const deletedType = customTypes[index].technicalName;
    delete updatedDefaultMaterials[deletedType];

    setFormData((prev) => ({
      ...prev,
      defaultMaterials: updatedDefaultMaterials,
    }));

    // Set active type to first remaining type or null
    if (updatedTypes.length > 0) {
      setActiveType(updatedTypes[0].technicalName);
    } else {
      setActiveType(null);
    }
  };

  // Handle duplicating a type
  const handleDuplicateType = (index) => {
    const typeToDuplicate = customTypes[index];
    const newType = {
      ...typeToDuplicate,
      technicalName: `${typeToDuplicate.technicalName}_copy`,
      displayLabel: `${typeToDuplicate.displayLabel} (Copy)`,
    };

    setCustomTypes((prev) => [...prev, newType]);

    // Update form data
    const updatedDefaultMaterials = { ...formData.defaultMaterials };
    updatedDefaultMaterials[newType.technicalName] = {
      label: newType.displayLabel,
      standard: newType.standardMaterials.map((name) => {
        const material = materialManager.findMaterialByName(name);
        return material ? material.slug : materialManager.generateSlug(name);
      }),
      optional: newType.optionalMaterials.map((name) => {
        const material = materialManager.findMaterialByName(name);
        return material ? material.slug : materialManager.generateSlug(name);
      }),
    };

    setFormData((prev) => ({
      ...prev,
      defaultMaterials: updatedDefaultMaterials,
    }));
  };

  // Handle type form field changes
  const handleTypeFormChange = (e) => {
    const { name, value } = e.target;
    setTypeFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle material status change (standard, optional, or none)
  const handleMaterialStatusChange = (materialName, status) => {
    setTypeFormData((prev) => {
      const newState = { ...prev };

      // Remove from all statuses first
      newState.standardMaterials = newState.standardMaterials.filter(
        (m) => m !== materialName
      );
      newState.optionalMaterials = newState.optionalMaterials.filter(
        (m) => m !== materialName
      );

      // Add to the appropriate status
      if (status === "standard") {
        newState.standardMaterials.push(materialName);
      } else if (status === "optional") {
        newState.optionalMaterials.push(materialName);
      }

      // Make sure it's in the materials list if not already
      if (!newState.materials.includes(materialName)) {
        newState.materials.push(materialName);
      }

      return newState;
    });
  };

  // Handle adding a material to the type
  const handleAddMaterial = (materialName) => {
    setTypeFormData((prev) => ({
      ...prev,
      materials: prev.materials.includes(materialName)
        ? prev.materials
        : [...prev.materials, materialName],
    }));
  };

  // Handle bulk add of materials
  const handleBulkAddMaterials = (
    materialsToAdd,
    asStandard = false,
    asOptional = false
  ) => {
    setTypeFormData((prev) => {
      const newMaterials = [...prev.materials];
      const newStandardMaterials = [...prev.standardMaterials];
      const newOptionalMaterials = [...prev.optionalMaterials];

      materialsToAdd.forEach((materialName) => {
        // Add to materials list if not already there
        if (!newMaterials.includes(materialName)) {
          newMaterials.push(materialName);
        }

        // Add to standard or optional based on parameters
        if (asStandard && !newStandardMaterials.includes(materialName)) {
          newStandardMaterials.push(materialName);
          // Remove from optional if it was there
          const optionalIndex = newOptionalMaterials.indexOf(materialName);
          if (optionalIndex > -1) {
            newOptionalMaterials.splice(optionalIndex, 1);
          }
        } else if (asOptional && !newOptionalMaterials.includes(materialName)) {
          newOptionalMaterials.push(materialName);
          // Remove from standard if it was there
          const standardIndex = newStandardMaterials.indexOf(materialName);
          if (standardIndex > -1) {
            newStandardMaterials.splice(standardIndex, 1);
          }
        }
      });

      return {
        ...prev,
        materials: newMaterials,
        standardMaterials: newStandardMaterials,
        optionalMaterials: newOptionalMaterials,
      };
    });
  };

  // Handle removing a material from the type
  const handleRemoveMaterial = (materialName) => {
    setTypeFormData((prev) => ({
      ...prev,
      materials: prev.materials.filter((name) => name !== materialName),
      standardMaterials: prev.standardMaterials.filter(
        (name) => name !== materialName
      ),
      optionalMaterials: prev.optionalMaterials.filter(
        (name) => name !== materialName
      ),
    }));
  };

  // Handle saving the type form
  const handleSaveType = () => {
    // Validate form
    if (!typeFormData.technicalName || !typeFormData.displayLabel) {
      alert("Technical name and display label are required");
      return;
    }

    // Check for duplicate technical name when adding
    if (editingTypeIndex === null) {
      const exists = customTypes.some(
        (type) => type.technicalName === typeFormData.technicalName
      );
      if (exists) {
        alert("A type with this technical name already exists");
        return;
      }
    }

    // Update custom types
    if (editingTypeIndex !== null) {
      // Update existing type
      const updatedTypes = [...customTypes];
      updatedTypes[editingTypeIndex] = { ...typeFormData };
      setCustomTypes(updatedTypes);

      // Update active type if it was the one being edited
      if (activeType === customTypes[editingTypeIndex].technicalName) {
        setActiveType(typeFormData.technicalName);
      }
    } else {
      // Add new type
      setCustomTypes((prev) => [...prev, { ...typeFormData }]);
      setActiveType(typeFormData.technicalName);
    }

    // Update form data
    const updatedDefaultMaterials = { ...formData.defaultMaterials };

    // If we're editing, remove the old technical name entry if it changed
    if (
      editingTypeIndex !== null &&
      customTypes[editingTypeIndex].technicalName !== typeFormData.technicalName
    ) {
      delete updatedDefaultMaterials[
        customTypes[editingTypeIndex].technicalName
      ];
    }

    // Add/update the entry for this technical name
    updatedDefaultMaterials[typeFormData.technicalName] = {
      label: typeFormData.displayLabel,
      standard: typeFormData.standardMaterials.map((name) => {
        try {
          const material = materialManager.findMaterialByName(name);
          return material ? material.slug : materialManager.generateSlug(name);
        } catch (e) {
          console.error("Error processing material:", name, e);
          return materialManager.generateSlug(name);
        }
      }),
      optional: typeFormData.optionalMaterials.map((name) => {
        try {
          const material = materialManager.findMaterialByName(name);
          return material ? material.slug : materialManager.generateSlug(name);
        } catch (e) {
          console.error("Error processing material:", name, e);
          return materialManager.generateSlug(name);
        }
      }),
    };

    setFormData((prev) => ({
      ...prev,
      defaultMaterials: updatedDefaultMaterials,
    }));

    // Close the form
    setShowTypeForm(false);
  };

  // Handle import JSON
  const handleImportJson = () => {
    try {
      // Parse the JSON
      const importData = JSON.parse(importJson);
      const newTypes = [];

      // Process each technical type
      Object.keys(importData).forEach((technicalName) => {
        const materialNames = importData[technicalName];

        // Create a new type
        newTypes.push({
          technicalName,
          displayLabel:
            technicalName.charAt(0).toUpperCase() + technicalName.slice(1),
          materials: materialNames,
          standardMaterials: [], // Initially, no materials are standard
          optionalMaterials: [], // Initially, no materials are optional
        });
      });

      // Add the new types
      setCustomTypes((prev) => [...prev, ...newTypes]);

      // Update form data
      const updatedDefaultMaterials = { ...formData.defaultMaterials };

      newTypes.forEach((type) => {
        updatedDefaultMaterials[type.technicalName] = {
          label: type.displayLabel,
          standard: type.standardMaterials.map((name) => {
            const material = materialManager.findMaterialByName(name);
            return material
              ? material.slug
              : materialManager.generateSlug(name);
          }),
          optional: type.optionalMaterials.map((name) => {
            const material = materialManager.findMaterialByName(name);
            return material
              ? material.slug
              : materialManager.generateSlug(name);
          }),
        };
      });

      setFormData((prev) => ({
        ...prev,
        defaultMaterials: updatedDefaultMaterials,
      }));

      // Set active type to the first new type if we don't have an active type
      if (!activeType && newTypes.length > 0) {
        setActiveType(newTypes[0].technicalName);
      }

      // Close the import form
      setShowImportForm(false);
      setImportJson("");
    } catch (error) {
      console.error("Failed to import JSON:", error);
      alert("Invalid JSON format");
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Format the data for saving
    const formattedDefaultMaterials = {};

    // Convert custom types to the structure expected by the backend
    customTypes.forEach((type) => {
      formattedDefaultMaterials[type.technicalName] = {
        label: type.displayLabel,
        standard: type.standardMaterials.map((name) => {
          // First try to find by name
          const material = materialManager.findMaterialByName(name);
          // If found, use its slug
          if (material) {
            return material.slug;
          }
          // If not found by name, try to convert directly (might already be a slug)
          return name.toLowerCase().includes("-")
            ? name
            : materialManager.generateSlug(name);
        }),
        optional: type.optionalMaterials.map((name) => {
          // First try to find by name
          const material = materialManager.findMaterialByName(name);
          // If found, use its slug
          if (material) {
            return material.slug;
          }
          // If not found by name, try to convert directly (might already be a slug)
          return name.toLowerCase().includes("-")
            ? name
            : materialManager.generateSlug(name);
        }),
      };
    });

    // Prepare complete data for saving
    const saveData = {
      name: formData.name,
      defaultMaterials: formattedDefaultMaterials,
      typeLinks: formData.typeLinks, // Use formData.typeLinks instead of typeLinks state
    };

    console.log("Saving family data with typeLinks:", formData.typeLinks);
    onSave(saveData);
  };

  // Get filtered materials based on selected collection
  const getFilteredMaterials = () => {
    if (selectedCollection === "all") {
      return allMaterials;
    }
    return allMaterials.filter(
      (material) => material.collection === selectedCollection
    );
  };

  return (
    <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
      <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-5xl ay-max-h-screen ay-overflow-auto ay-flex ay-flex-col">
        <h2 className="ay-text-xl ay-font-bold ay-mb-4">
          {isEditing ? "Edit Family" : "Add Family"} in Category: {category}
        </h2>

        {/* Fixed bottom actions bar */}
        <div className="ay-sticky ay-bottom-0 ay-bg-white ay-border-t ay-border-gray-200 ay-mt-6 ay-p-4 ay-flex ay-justify-end ay-gap-3">
          <button
            type="button"
            onClick={onCancel}
            className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-shadow-sm ay-bg-blue-500 ay-text-white ay-hover:ay-bg-blue-600"
          >
            {isEditing ? "Update" : "Create"}
          </button>
        </div>

        <form className="ay-flex-grow ay-overflow-auto ay-pb-16">
          <div className="ay-mb-4">
            <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
              Family Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
              required
            />
          </div>

          {/* Navigation Tabs */}
          <div className="ay-border-b ay-border-gray-200 ay-mb-4">
            <nav className="ay-flex ay-space-x-4">
              <button
                type="button"
                onClick={() => setView("types")}
                className={`ay-py-2 ay-px-3 ay-text-sm ay-font-medium ${
                  view === "types"
                    ? "ay-border-b-2 ay-border-blue-500 ay-text-blue-600"
                    : "ay-text-gray-500 ay-hover:ay-text-gray-700"
                }`}
              >
                Material Types
              </button>
              <button
                type="button"
                onClick={() => setView("typeLinks")}
                className={`ay-py-2 ay-px-3 ay-text-sm ay-font-medium ${
                  view === "typeLinks"
                    ? "ay-border-b-2 ay-border-blue-500 ay-text-blue-600"
                    : "ay-text-gray-500 ay-hover:ay-text-gray-700"
                }`}
              >
                Type Links
              </button>
            </nav>
          </div>

          {/* Types View */}
          {view === "types" && (
            <div className="ay-mb-6">
              <div className="ay-flex ay-justify-between ay-items-center ay-mb-2">
                <h3 className="ay-text-lg ay-font-medium">Material Types</h3>
                <div className="ay-flex ay-gap-2">
                  <button
                    type="button"
                    onClick={() => setShowImportForm(true)}
                    className="ay-px-3 ay-py-1 ay-bg-purple-500 ay-text-white ay-rounded ay-text-sm"
                  >
                    Import JSON
                  </button>
                  <button
                    type="button"
                    onClick={handleAddType}
                    className="ay-px-3 ay-py-1 ay-bg-blue-500 ay-text-white ay-rounded ay-text-sm"
                  >
                    Add Type
                  </button>
                </div>
              </div>

              {/* Types List */}
              <div className="ay-bg-gray-50 ay-p-3 ay-rounded-md ay-mb-4">
                {customTypes.length > 0 ? (
                  <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-2 lg:ay-grid-cols-3 ay-gap-3">
                    {customTypes.map((type, index) => (
                      <div
                        key={index}
                        className={`ay-border ay-rounded-md ay-p-3 ay-cursor-pointer ay-transition-all ${
                          activeType === type.technicalName
                            ? "ay-border-blue-500 ay-bg-blue-50"
                            : "ay-border-gray-300 ay-hover:ay-border-gray-400"
                        }`}
                        onClick={() => setActiveType(type.technicalName)}
                      >
                        <div className="ay-flex ay-justify-between ay-items-start">
                          <div>
                            <h4 className="ay-font-medium">
                              {type.displayLabel}
                            </h4>
                            <p className="ay-text-xs ay-text-gray-500">
                              {type.technicalName}
                            </p>
                            <p className="ay-text-sm ay-mt-1">
                              {type.materials.length} Materials (
                              {type.standardMaterials.length} Standard,{" "}
                              {type.optionalMaterials.length} Optional)
                            </p>
                          </div>
                          <div className="ay-flex ay-flex-col ay-gap-1">
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditType(index);
                              }}
                              className="ay-text-xs ay-text-blue-600 ay-hover:ay-text-blue-800"
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDuplicateType(index);
                              }}
                              className="ay-text-xs ay-text-green-600 ay-hover:ay-text-green-800"
                            >
                              Duplicate
                            </button>
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteType(index);
                              }}
                              className="ay-text-xs ay-text-red-600 ay-hover:ay-text-red-800"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="ay-text-center ay-py-4 ay-text-gray-500">
                    No material types defined. Add a type or import from JSON.
                  </div>
                )}
              </div>

              {/* Active Type Materials Table */}
              {activeType && (
                <div>
                  <div className="ay-flex ay-justify-between ay-items-center ay-mb-2">
                    <h4 className="ay-font-medium">
                      {customTypes.find((t) => t.technicalName === activeType)
                        ?.displayLabel || activeType}{" "}
                      Materials
                    </h4>
                    <button
                      type="button"
                      onClick={() => {
                        // Show material selector dialog
                        const typeIndex = customTypes.findIndex(
                          (t) => t.technicalName === activeType
                        );
                        if (typeIndex !== -1) {
                          handleEditType(typeIndex);
                        }
                      }}
                      className="ay-px-3 ay-py-1 ay-bg-green-500 ay-text-white ay-rounded ay-text-sm"
                    >
                      Manage Materials
                    </button>
                  </div>

                  {/* Materials List with Standard/Optional grouping */}
                  <div className="ay-space-y-4">
                    {/* Standard Materials */}
                    <div className="ay-border ay-rounded-md ay-overflow-hidden">
                      <div className="ay-bg-green-50 ay-px-4 ay-py-2 ay-border-b ay-border-green-200">
                        <h5 className="ay-font-medium ay-text-green-800">
                          Standard Materials
                        </h5>
                      </div>
                      <table className="ay-min-w-full ay-divide-y ay-divide-gray-200">
                        <thead className="ay-bg-gray-50">
                          <tr>
                            <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                              Material
                            </th>
                            <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                              Preview
                            </th>
                            <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                              Collection
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ay-bg-white ay-divide-y ay-divide-gray-200">
                          {customTypes
                            .find((t) => t.technicalName === activeType)
                            ?.standardMaterials.map((materialName, index) => {
                              const material =
                                materialManager.findMaterialByName(
                                  materialName
                                ) ||
                                allMaterials.find(
                                  (m) => m.name === materialName
                                );

                              return (
                                <tr key={index}>
                                  <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                    <div className="ay-text-sm ay-font-medium ay-text-gray-900">
                                      {materialName}
                                    </div>
                                  </td>
                                  <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                    {material?.thumbnail ? (
                                      material.thumbnail.startsWith("#") ? (
                                        <div
                                          className="ay-w-8 ay-h-8 ay-rounded-full ay-border ay-border-gray-300"
                                          style={{
                                            backgroundColor: material.thumbnail,
                                          }}
                                        ></div>
                                      ) : (
                                        <img
                                          src={material.thumbnail}
                                          alt={materialName}
                                          className="ay-w-8 ay-h-8 ay-rounded-full ay-object-cover"
                                        />
                                      )
                                    ) : (
                                      <div className="ay-w-8 ay-h-8 ay-bg-gray-200 ay-rounded-full"></div>
                                    )}
                                  </td>
                                  <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                    {material?.collection || "Unknown"}
                                  </td>
                                </tr>
                              );
                            })}
                          {(customTypes.find(
                            (t) => t.technicalName === activeType
                          )?.standardMaterials.length || 0) === 0 && (
                            <tr>
                              <td
                                colSpan="3"
                                className="ay-px-6 ay-py-4 ay-text-center ay-text-gray-500"
                              >
                                No standard materials defined for this type.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {/* Optional Materials */}
                    <div className="ay-border ay-rounded-md ay-overflow-hidden">
                      <div className="ay-bg-yellow-50 ay-px-4 ay-py-2 ay-border-b ay-border-yellow-200">
                        <h5 className="ay-font-medium ay-text-yellow-800">
                          Optional Materials (Extra Cost)
                        </h5>
                      </div>
                      <table className="ay-min-w-full ay-divide-y ay-divide-gray-200">
                        <thead className="ay-bg-gray-50">
                          <tr>
                            <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                              Material
                            </th>
                            <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                              Preview
                            </th>
                            <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                              Collection
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ay-bg-white ay-divide-y ay-divide-gray-200">
                          {customTypes
                            .find((t) => t.technicalName === activeType)
                            ?.optionalMaterials.map((materialName, index) => {
                              const material =
                                materialManager.findMaterialByName(
                                  materialName
                                ) ||
                                allMaterials.find(
                                  (m) => m.name === materialName
                                );

                              return (
                                <tr key={index}>
                                  <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                    <div className="ay-text-sm ay-font-medium ay-text-gray-900">
                                      {materialName}
                                    </div>
                                  </td>
                                  <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                    {material?.thumbnail ? (
                                      material.thumbnail.startsWith("#") ? (
                                        <div
                                          className="ay-w-8 ay-h-8 ay-rounded-full ay-border ay-border-gray-300"
                                          style={{
                                            backgroundColor: material.thumbnail,
                                          }}
                                        ></div>
                                      ) : (
                                        <img
                                          src={material.thumbnail}
                                          alt={materialName}
                                          className="ay-w-8 ay-h-8 ay-rounded-full ay-object-cover"
                                        />
                                      )
                                    ) : (
                                      <div className="ay-w-8 ay-h-8 ay-bg-gray-200 ay-rounded-full"></div>
                                    )}
                                  </td>
                                  <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                    {material?.collection || "Unknown"}
                                  </td>
                                </tr>
                              );
                            })}
                          {(customTypes.find(
                            (t) => t.technicalName === activeType
                          )?.optionalMaterials.length || 0) === 0 && (
                            <tr>
                              <td
                                colSpan="3"
                                className="ay-px-6 ay-py-4 ay-text-center ay-text-gray-500"
                              >
                                No optional materials defined for this type.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Type Links View */}
          {view === "typeLinks" && (
            <div>
              <h3 className="ay-text-lg ay-font-medium ay-mb-4">Type Links</h3>
              <div className="ay-mb-4 ay-bg-blue-50 ay-p-4 ay-rounded">
                <h4 className="ay-font-medium ay-mb-2">What are Type Links?</h4>
                <p className="ay-text-sm">
                  Type Links allow you to connect different material types
                  together. When types are linked, selecting a material that
                  exists in both types will automatically apply it to all linked
                  types. This simplifies configuration and ensures consistency
                  across related parts.
                </p>
              </div>
              <TypeLinksComponent
                customTypes={customTypes}
                initialLinks={formData.typeLinks || []}
                onChange={(updatedLinks) => {
                  // Save the updated type links to form data
                  setFormData((prev) => ({
                    ...prev,
                    typeLinks: updatedLinks,
                  }));
                }}
                setLoading={setLoading}
              />
            </div>
          )}
        </form>

        {/* Type Form Modal */}
        {showTypeForm && (
          <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
            <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-5xl ay-max-h-screen ay-overflow-auto">
              <h3 className="ay-text-lg ay-font-bold ay-mb-4">
                {editingTypeIndex !== null ? "Edit" : "Add"} Material Type
              </h3>

              <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-2 ay-gap-4 ay-mb-4">
                <div>
                  <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                    Technical Name *
                  </label>
                  <input
                    type="text"
                    name="technicalName"
                    value={typeFormData.technicalName}
                    onChange={handleTypeFormChange}
                    className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                    required
                  />
                  <p className="ay-text-xs ay-text-gray-500 ay-mt-1">
                    Must match the technical name used in the 3D model.
                  </p>
                </div>

                <div>
                  <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                    Display Label *
                  </label>
                  <input
                    type="text"
                    name="displayLabel"
                    value={typeFormData.displayLabel}
                    onChange={handleTypeFormChange}
                    className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                    required
                  />
                  <p className="ay-text-xs ay-text-gray-500 ay-mt-1">
                    This label will be displayed in the accordion.
                  </p>
                </div>
              </div>

              {/* Material Selection */}
              <div className="ay-mb-6">
                <div className="ay-flex ay-justify-between ay-items-center ay-mb-2">
                  <h4 className="ay-text-md ay-font-medium">
                    Material Selection
                  </h4>
                  <div className="ay-flex ay-items-center ay-gap-2">
                    <label className="ay-text-sm ay-text-gray-700">
                      Filter by Collection:
                    </label>
                    <select
                      value={selectedCollection}
                      onChange={(e) => setSelectedCollection(e.target.value)}
                      className="ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                    >
                      <option value="all">All Collections</option>
                      {collections.map((collection) => (
                        <option key={collection} value={collection}>
                          {collection}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Bulk Actions for Displayed Materials */}
                <div className="ay-mb-3 ay-bg-gray-50 ay-p-3 ay-rounded-md ay-flex ay-flex-wrap ay-gap-2">
                  <button
                    type="button"
                    onClick={() =>
                      handleBulkAddMaterials(
                        getFilteredMaterials().map((m) => m.name)
                      )
                    }
                    className="ay-px-3 ay-py-1 ay-bg-blue-500 ay-text-white ay-rounded ay-text-sm"
                  >
                    Add All Displayed
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      handleBulkAddMaterials(
                        getFilteredMaterials().map((m) => m.name),
                        true,
                        false
                      )
                    }
                    className="ay-px-3 ay-py-1 ay-bg-green-500 ay-text-white ay-rounded ay-text-sm"
                  >
                    Add All as Standard
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      handleBulkAddMaterials(
                        getFilteredMaterials().map((m) => m.name),
                        false,
                        true
                      )
                    }
                    className="ay-px-3 ay-py-1 ay-bg-yellow-500 ay-text-white ay-rounded ay-text-sm"
                  >
                    Add All as Optional
                  </button>
                </div>

                {/* Material Selection Controls */}
                <div className="ay-flex ay-justify-between ay-mb-3 ay-bg-gray-50 ay-p-2 ay-rounded">
                  <div className="ay-flex ay-items-center ay-gap-2">
                    <button
                      type="button"
                      onClick={() => {
                        // Sort materials alphabetically
                        setAllMaterials(
                          [...allMaterials].sort((a, b) =>
                            a.name.localeCompare(b.name)
                          )
                        );
                      }}
                      className="ay-px-2 ay-py-1 ay-bg-gray-200 ay-text-gray-700 ay-rounded ay-text-xs"
                    >
                      Sort A-Z
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        // Select all filtered materials as optional (default)
                        handleBulkAddMaterials(
                          getFilteredMaterials().map((m) => m.name),
                          false,
                          true
                        );
                      }}
                      className="ay-px-2 ay-py-1 ay-bg-yellow-400 ay-text-white ay-rounded ay-text-xs"
                    >
                      Select All as Optional
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        // Select all filtered materials as standard
                        handleBulkAddMaterials(
                          getFilteredMaterials().map((m) => m.name),
                          true,
                          false
                        );
                      }}
                      className="ay-px-2 ay-py-1 ay-bg-green-500 ay-text-white ay-rounded ay-text-xs"
                    >
                      Select All as Standard
                    </button>
                  </div>
                  <span className="ay-text-xs ay-text-gray-500">
                    Click = Optional | Shift+Click = Standard
                  </span>
                </div>

                {/* Available Materials Grid */}
                <div className="ay-grid ay-grid-cols-2 md:ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-2 ay-mb-6 ay-h-64 ay-overflow-y-auto ay-border ay-border-gray-200 ay-rounded-md ay-p-3">
                  {getFilteredMaterials()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((material) => {
                      const isSelected = typeFormData.materials.includes(
                        material.name
                      );
                      const isStandard =
                        typeFormData.standardMaterials.includes(material.name);
                      const isOptional =
                        typeFormData.optionalMaterials.includes(material.name);

                      let statusClass = "";
                      if (isStandard)
                        statusClass = "ay-border-green-500 ay-bg-green-50";
                      else if (isOptional)
                        statusClass = "ay-border-yellow-500 ay-bg-yellow-50";
                      else if (isSelected)
                        statusClass = "ay-border-blue-500 ay-bg-blue-50";

                      return (
                        <div
                          key={material.id}
                          className={`ay-border ay-rounded-md ay-p-2 ay-cursor-pointer ay-transition-all ${statusClass} ay-hover:ay-shadow`}
                          onClick={(e) => {
                            // Handle click with Shift key for Standard, otherwise Optional
                            if (e.shiftKey) {
                              handleMaterialStatusChange(
                                material.name,
                                isStandard ? "none" : "standard"
                              );
                            } else {
                              handleMaterialStatusChange(
                                material.name,
                                isOptional ? "none" : "optional"
                              );
                            }
                          }}
                        >
                          <div className="ay-flex ay-items-center ay-gap-2">
                            {material.thumbnail ? (
                              material.thumbnail.startsWith("#") ? (
                                <div
                                  className="ay-w-8 ay-h-8 ay-rounded-full ay-border ay-border-gray-300"
                                  style={{
                                    backgroundColor: material.thumbnail,
                                  }}
                                ></div>
                              ) : (
                                <img
                                  src={material.thumbnail}
                                  alt={material.name}
                                  className="ay-w-8 ay-h-8 ay-rounded-full ay-object-cover"
                                />
                              )
                            ) : (
                              <div className="ay-w-8 ay-h-8 ay-bg-gray-200 ay-rounded-full"></div>
                            )}
                            <div className="ay-flex-1 ay-overflow-hidden">
                              <div className="ay-text-sm ay-font-medium ay-truncate">
                                {material.name}
                              </div>
                              <div className="ay-text-xs ay-text-gray-500 ay-truncate">
                                {material.collection}
                              </div>
                            </div>

                            {/* Status indicator */}
                            {isSelected && (
                              <div className="ay-flex ay-flex-col ay-gap-1">
                                {isStandard && (
                                  <span className="ay-px-1 ay-bg-green-500 ay-text-white ay-text-xs ay-rounded">
                                    STD
                                  </span>
                                )}
                                {isOptional && (
                                  <span className="ay-px-1 ay-bg-yellow-400 ay-text-white ay-text-xs ay-rounded">
                                    OPT
                                  </span>
                                )}
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveMaterial(material.name);
                                  }}
                                  className="ay-px-1 ay-text-xs ay-bg-red-500 ay-text-white ay-rounded"
                                >
                                  ✕
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}

                  {getFilteredMaterials().length === 0 && (
                    <div className="ay-col-span-full ay-text-center ay-py-8 ay-text-gray-500">
                      No materials found. Try changing the collection filter.
                    </div>
                  )}
                </div>

                {/* Selected Materials Summary */}
                <div className="ay-border ay-border-gray-200 ay-rounded-md ay-p-3">
                  <h5 className="ay-text-sm ay-font-medium ay-mb-2">
                    Selected Materials Summary
                  </h5>

                  <div className="ay-flex ay-flex-wrap ay-gap-2">
                    {typeFormData.materials.map((materialName) => {
                      let badgeClass = "ay-bg-blue-100 ay-text-blue-800";
                      if (
                        typeFormData.standardMaterials.includes(materialName)
                      ) {
                        badgeClass = "ay-bg-green-100 ay-text-green-800";
                      } else if (
                        typeFormData.optionalMaterials.includes(materialName)
                      ) {
                        badgeClass = "ay-bg-yellow-100 ay-text-yellow-800";
                      }

                      return (
                        <div
                          key={materialName}
                          className={`ay-px-2 ay-py-1 ay-rounded-md ay-text-xs ay-flex ay-items-center ay-gap-1 ${badgeClass}`}
                        >
                          <span>{materialName}</span>
                          <button
                            type="button"
                            onClick={() => handleRemoveMaterial(materialName)}
                            className="ay-text-gray-500 ay-hover:ay-text-gray-700"
                          >
                            ✕
                          </button>
                        </div>
                      );
                    })}

                    {typeFormData.materials.length === 0 && (
                      <p className="ay-text-sm ay-text-gray-500">
                        No materials selected yet.
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="ay-flex ay-justify-end ay-gap-3 ay-mt-6">
                <button
                  type="button"
                  onClick={() => setShowTypeForm(false)}
                  className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSaveType}
                  className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-shadow-sm ay-bg-blue-500 ay-text-white ay-hover:ay-bg-blue-600"
                >
                  {editingTypeIndex !== null ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Import JSON Modal */}
        {showImportForm && (
          <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
            <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-md">
              <h3 className="ay-text-lg ay-font-bold ay-mb-4">
                Import Material Types from JSON
              </h3>

              <div className="ay-mb-4">
                <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                  JSON Format
                </label>
                <textarea
                  value={importJson}
                  onChange={(e) => setImportJson(e.target.value)}
                  className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-h-40 ay-resize-none ay-font-mono ay-text-sm"
                  placeholder='{
  "struttura": ["V1 Pure White", "V38 Signal Black"],
  "ripiani": ["V1 Pure White", "V38 Signal Black", "MWL1 Natural Oak"]
}'
                  required
                ></textarea>
                <p className="ay-text-xs ay-text-gray-500 ay-mt-1">
                  JSON format: {"{"}"technicalName": ["Material 1", "Material
                  2", ...], ...{"}"}
                </p>
              </div>

              <div className="ay-flex ay-justify-end ay-gap-3 ay-mt-6">
                <button
                  type="button"
                  onClick={() => setShowImportForm(false)}
                  className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleImportJson}
                  className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-shadow-sm ay-bg-blue-500 ay-text-white ay-hover:ay-bg-blue-600"
                >
                  Import
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FamilyForm;
