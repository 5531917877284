// FamilySelector.js - Updated to match Accordion styling
import React, { useState, useEffect, useRef } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { fetchProductStructure } from "./api";

const FamilySelector = ({
  products,
  selectedFamily,
  onFamilyChange,
  selectedProduct,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [productStructure, setProductStructure] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);
  const categoryRefs = useRef({});
  const headerClickRef = useRef(false);

  // Load product structure on component mount
  useEffect(() => {
    loadProductStructure();
  }, []);

  // Update content height when isDropdownOpen changes or content changes
  useEffect(() => {
    if (isDropdownOpen && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [isDropdownOpen, productStructure, expandedCategories]);

  // Update selected category when selected family changes
  useEffect(() => {
    if (selectedFamily) {
      // Find which category contains this family
      for (const category in productStructure) {
        if (productStructure[category]?.includes(selectedFamily)) {
          setSelectedCategory(category);

          // Ensure only this category is expanded, close others
          const newExpandedState = {};
          Object.keys(expandedCategories).forEach((cat) => {
            newExpandedState[cat] = cat === category;
          });
          setExpandedCategories(newExpandedState);
          break;
        }
      }
    }
  }, [selectedFamily, productStructure]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !headerClickRef.current &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
      headerClickRef.current = false;
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Load product structure
  const loadProductStructure = async () => {
    setLoading(true);
    try {
      const structure = await fetchProductStructure();
      setProductStructure(structure);

      const categoryNames = Object.keys(structure);
      setCategories(categoryNames);

      // Initialize expanded state for categories
      const initialExpandedCategories = {};
      categoryNames.forEach((category) => {
        initialExpandedCategories[category] = false;
      });

      // If we have a selected family, find its category
      if (selectedFamily) {
        for (const category of categoryNames) {
          if (structure[category]?.includes(selectedFamily)) {
            setSelectedCategory(category);
            // Auto-expand only this category
            categoryNames.forEach((cat) => {
              initialExpandedCategories[cat] = cat === category;
            });
            break;
          }
        }
      }
      // Otherwise, select the first category
      else if (categoryNames.length > 0) {
        setSelectedCategory(categoryNames[0]);
      }

      setExpandedCategories(initialExpandedCategories);
    } catch (error) {
      console.error("Failed to load product structure:", error);
    } finally {
      setLoading(false);
    }
  };

  // Toggle category expansion - ensure only one is open at a time
  const toggleCategory = (e, category) => {
    e.stopPropagation();

    setExpandedCategories((prev) => {
      const newState = {};
      // Close all categories
      Object.keys(prev).forEach((cat) => {
        newState[cat] = false;
      });
      // Toggle the clicked category
      newState[category] = !prev[category];
      return newState;
    });
  };

  // Toggle the dropdown open/close
  const toggleDropdown = (e) => {
    e.stopPropagation();
    headerClickRef.current = true;
    setIsDropdownOpen((prev) => !prev);
  };

  // Handle family selection - IMPORTANT: Only change family, not product
  const handleFamilySelect = (e, category, family) => {
    e.stopPropagation();

    // Only update the family, let FamilyAccordion handle product selection
    setSelectedCategory(category);
    onFamilyChange(family);
    setIsDropdownOpen(false);
  };

  return (
    <div className="ay-w-full ay-py-2 ay-border-b ay-border-black/80">
      <div
        className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-cursor-pointer"
        onClick={toggleDropdown}
      >
        {/* Header section with aligned title and selected family */}
        <div className="ay-flex-grow">
          <div className="ay-flex ay-items-center ay-justify-between">
            <h2 className="ay-text-lg ay-font-serif ay-capitalize">
              Gamma:{" "}
              {selectedFamily ? (
                <span className="ay-text-base ay-font-medium ay-text-black/40 ay-ml-1">
                  {selectedFamily}
                </span>
              ) : (
                <span className="ay-text-base ay-font-medium ay-text-black/40 ay-ml-1">
                  Seleziona la gamma
                </span>
              )}
            </h2>
            <div className="ay-flex ay-items-center ay-justify-center ay-w-6 ay-h-6">
              <span
                className={`ay-transition-transform ay-text-sm ay-duration-300 ay-ease-in-out ${
                  isDropdownOpen ? "ay-rotate-45" : "ay-rotate-0"
                }`}
              >
                <AiOutlinePlus />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Accordion Content with animation */}
      <div
        ref={contentRef}
        className="ay-overflow-hidden ay-transition-all ay-duration-300 ay-ease-in-out"
        style={{
          maxHeight: `${contentHeight}px`,
          opacity: isDropdownOpen ? 1 : 0,
        }}
      >
        <div
          className="ay-mt-4 ay-transform ay-transition-transform ay-duration-300 ay-ease-in-out"
          style={{
            transform: isDropdownOpen ? "translateY(0)" : "translateY(-10px)",
          }}
        >
          {/* Dropdown */}
          <div ref={dropdownRef} className="ay-w-full ay-overflow-visible">
            {loading ? (
              <div className="ay-px-4 ay-py-3 ay-text-gray-500 ay-text-center">
                Loading product structure...
              </div>
            ) : categories.length > 0 ? (
              categories.map((category) => (
                <div
                  key={category}
                  className="ay-border-b ay-border-gray-100 ay-last:ay-border-0"
                  ref={(el) => (categoryRefs.current[category] = el)}
                >
                  {/* Category header */}
                  <div
                    onClick={(e) => toggleCategory(e, category)}
                    className="ay-flex ay-justify-between ay-items-center ay-px-4 ay-py-2 ay-cursor-pointer ay-hover:ay-bg-gray-50"
                  >
                    <span className="ay-font-medium">{category}</span>
                    <div className="ay-flex ay-items-center ay-justify-center ay-w-5 ay-h-5">
                      <span
                        className={`ay-transition-transform ay-text-xs ay-duration-300 ay-ease-in-out ${
                          expandedCategories[category]
                            ? "ay-rotate-45"
                            : "ay-rotate-0"
                        }`}
                      >
                        <AiOutlinePlus />
                      </span>
                    </div>
                  </div>

                  {/* Families in this category - with animation */}
                  <div
                    className="ay-overflow-hidden ay-transition-all ay-duration-300 ay-ease-in-out"
                    style={{
                      maxHeight: expandedCategories[category] ? "500px" : "0px",
                      opacity: expandedCategories[category] ? 1 : 0,
                    }}
                  >
                    <div
                      className="ay-pl-8 ay-pr-4 ay-pb-2 ay-transform ay-transition-transform ay-duration-300"
                      style={{
                        transform: expandedCategories[category]
                          ? "translateY(0)"
                          : "translateY(-10px)",
                      }}
                    >
                      {productStructure[category]?.length > 0 ? (
                        productStructure[category].map((family) => (
                          <div
                            key={family}
                            onClick={(e) =>
                              handleFamilySelect(e, category, family)
                            }
                            className={`ay-px-3 ay-py-2 ay-cursor-pointer ay-rounded-md ay-my-1 ay-transition-colors ay-duration-200 ${
                              selectedFamily === family
                                ? "ay-bg-gray-100 ay-text-black"
                                : "ay-hover:ay-bg-gray-50"
                            }`}
                          >
                            {family}
                          </div>
                        ))
                      ) : (
                        <div className="ay-px-3 ay-py-2 ay-text-gray-500">
                          No families in this category
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="ay-px-4 ay-py-3 ay-text-gray-500 ay-text-center">
                No categories available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilySelector;
