// components/admin/MaterialsTab.js
import React, { useState, useEffect, useCallback } from "react";
import { SketchPicker } from "react-color";
import {
  fetchMaterials,
  fetchTechnicalTypes,
  addMaterial,
  updateMaterial,
  deleteMaterial,
  importMaterials,
} from "../../api";

const MaterialsTab = ({ setLoading }) => {
  // Materials state
  const [materialsData, setMaterialsData] = useState({ materials: [] });
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [technicalTypes, setTechnicalTypes] = useState([]);

  // Filters
  const [activeType, setActiveType] = useState("all");
  const [activeTechnicalType, setActiveTechnicalType] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  // UI states
  const [showMaterialForm, setShowMaterialForm] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);
  const [editingMaterial, setEditingMaterial] = useState(null);
  const [expandedMaterials, setExpandedMaterials] = useState({});

  // Material form data
  const [materialFormData, setMaterialFormData] = useState({
    id: "",
    name: "",
    slug: "",
    thumbnail: "",
    type: "Default",
    collection: "Default",
    technicalTypes: [],
    variations: [],
  });

  // Import form data
  const [importFormData, setImportFormData] = useState("");

  // Material thumbnail states
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [thumbnailUpload, setThumbnailUpload] = useState(null);

  // Load initial data
  useEffect(() => {
    loadMaterials();
    loadTechnicalTypes();
  }, []);

  // Apply filters whenever materials or filter criteria change
  useEffect(() => {
    applyFilters();
  }, [materialsData, activeType, activeTechnicalType, searchTerm]);

  // Load all materials
  const loadMaterials = async () => {
    setLoading(true);
    try {
      const data = await fetchMaterials();
      setMaterialsData(data);
    } catch (error) {
      console.error("Failed to load materials:", error);
    } finally {
      setLoading(false);
    }
  };

  // Load technical types
  const loadTechnicalTypes = async () => {
    try {
      const types = await fetchTechnicalTypes();
      setTechnicalTypes(types);
    } catch (error) {
      console.error("Failed to load technical types:", error);
    }
  };

  // Apply filters to materials
  const applyFilters = useCallback(() => {
    let filtered = [...(materialsData.materials || [])];

    // Filter by type
    if (activeType !== "all") {
      filtered = filtered.filter((material) => material.type === activeType);
    }

    // Filter by technical type
    if (activeTechnicalType !== "all") {
      filtered = filtered.filter(
        (material) =>
          material.technicalTypes &&
          material.technicalTypes.includes(activeTechnicalType)
      );
    }

    // Filter by search term
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (material) =>
          material.name.toLowerCase().includes(lowerSearchTerm) ||
          material.id.toLowerCase().includes(lowerSearchTerm) ||
          (material.collection &&
            material.collection.toLowerCase().includes(lowerSearchTerm))
      );
    }

    setFilteredMaterials(filtered);
  }, [materialsData, activeType, activeTechnicalType, searchTerm]);

  // Get all unique types from materials
  const getUniqueTypes = () => {
    const types = new Set();
    materialsData.materials.forEach((material) => {
      if (material.type) {
        types.add(material.type);
      }
    });
    return Array.from(types);
  };

  // Toggle material expansion
  const toggleMaterialExpansion = (materialId) => {
    setExpandedMaterials((prev) => ({
      ...prev,
      [materialId]: !prev[materialId],
    }));
  };

  // Handle add material
  const handleAddMaterial = () => {
    setEditingMaterial(null);
    setMaterialFormData({
      id: "",
      name: "",
      slug: "",
      thumbnail: "",
      type: "Default",
      collection: "Default",
      technicalTypes: [],
      variations: [],
    });
    setThumbnailPreview("");
    setThumbnailUpload(null);
    setShowMaterialForm(true);
  };

  // Handle edit material
  const handleEditMaterial = (material) => {
    setEditingMaterial(material);
    setMaterialFormData({
      id: material.id,
      name: material.name,
      slug: material.slug,
      thumbnail: material.thumbnail,
      type: material.type || "Default",
      collection: material.collection || "Default",
      technicalTypes: material.technicalTypes || [],
      variations: material.variations || [],
    });
    setThumbnailPreview(material.thumbnail);
    setThumbnailUpload(null);
    setShowMaterialForm(true);
  };

  // Handle delete material
  const handleDeleteMaterial = async (materialId) => {
    if (!window.confirm("Are you sure you want to delete this material?")) {
      return;
    }

    setLoading(true);
    try {
      await deleteMaterial(materialId);
      await loadMaterials();
    } catch (error) {
      console.error("Failed to delete material:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle material form change
  const handleMaterialFormChange = (e) => {
    const { name, value } = e.target;
    setMaterialFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle technical type checkbox change
  const handleTechnicalTypeChange = (technicalType) => {
    setMaterialFormData((prev) => {
      const technicalTypes = [...prev.technicalTypes];
      const index = technicalTypes.indexOf(technicalType);

      if (index === -1) {
        technicalTypes.push(technicalType);
      } else {
        technicalTypes.splice(index, 1);
      }

      return {
        ...prev,
        technicalTypes,
      };
    });
  };

  // Handle color change in color picker
  const handleColorChange = (color) => {
    setMaterialFormData((prev) => ({
      ...prev,
      thumbnail: color.hex,
    }));
    setThumbnailPreview(color.hex);
  };

  // Handle thumbnail file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnailUpload(file);
      const reader = new FileReader();
      reader.onloadend = () => setThumbnailPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  // Handle variation add/edit
  const handleVariationChange = (index, field, value) => {
    setMaterialFormData((prev) => {
      const variations = [...prev.variations];
      variations[index] = {
        ...variations[index],
        [field]: value,
      };
      return {
        ...prev,
        variations,
      };
    });
  };

  // Add a new variation
  const handleAddVariation = () => {
    setMaterialFormData((prev) => ({
      ...prev,
      variations: [...prev.variations, { name: "", slug: "", thumbnail: "" }],
    }));
  };

  // Remove a variation
  const handleRemoveVariation = (index) => {
    setMaterialFormData((prev) => {
      const variations = [...prev.variations];
      variations.splice(index, 1);
      return {
        ...prev,
        variations,
      };
    });
  };

  // Handle material form submit
  const handleMaterialFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      // Prepare form data
      const formData = { ...materialFormData };

      // Handle thumbnail upload if needed
      if (thumbnailUpload) {
        // Create FormData to send the file
        const uploadFormData = new FormData();
        uploadFormData.append("thumbnail", thumbnailUpload);

        // Upload the file to the server
        const uploadResponse = await fetch(`${API_BASE_URL}/upload.php`, {
          method: "POST",
          body: uploadFormData,
        });

        if (!uploadResponse.ok) {
          throw new Error("Failed to upload thumbnail");
        }

        // Get the URL of the uploaded file
        const uploadResult = await uploadResponse.json();
        formData.thumbnail = uploadResult.url;
      }

      // Add or update the material
      if (editingMaterial) {
        await updateMaterial(formData);
      } else {
        await addMaterial(formData);
      }

      // Reload materials
      await loadMaterials();
      setShowMaterialForm(false);
    } catch (error) {
      console.error("Failed to save material:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle import form change
  const handleImportFormChange = (e) => {
    setImportFormData(e.target.value);
  };

  // Handle import form submit
  const handleImportFormSubmit = async (e) => {
    e.preventDefault();

    try {
      // Parse the JSON input
      const importData = JSON.parse(importFormData);

      setLoading(true);
      // Call the import API
      await importMaterials(importData);

      // Reload materials
      await loadMaterials();
      setShowImportForm(false);
      setImportFormData("");
    } catch (error) {
      console.error("Failed to import materials:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Generate unique collections from materials
  const getUniqueCollections = () => {
    const collections = new Set();
    materialsData.materials.forEach((material) => {
      if (material.collection) {
        collections.add(material.collection);
      }
    });
    return Array.from(collections);
  };

  return (
    <div className="ay-relative">
      {/* Material Form Modal */}
      {showMaterialForm && (
        <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
          <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-2xl ay-max-h-screen ay-overflow-auto">
            <h2 className="ay-text-xl ay-font-bold ay-mb-4">
              {editingMaterial ? "Edit Material" : "Add New Material"}
            </h2>

            <form onSubmit={handleMaterialFormSubmit}>
              <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-2 ay-gap-4">
                {/* Material Name */}
                <div>
                  <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                    Material Name *
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={materialFormData.name}
                    onChange={handleMaterialFormChange}
                    className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                    required
                  />
                </div>

                {/* Material ID/Slug */}
                <div>
                  <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                    Material ID *
                  </label>
                  <input
                    type="text"
                    name="id"
                    value={materialFormData.id}
                    onChange={handleMaterialFormChange}
                    className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                    required
                    disabled={editingMaterial}
                  />
                  <p className="ay-text-xs ay-text-gray-500 ay-mt-1">
                    Unique identifier for the material.
                  </p>
                </div>

                {/* Material Slug */}
                <div>
                  <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                    Slug *
                  </label>
                  <input
                    type="text"
                    name="slug"
                    value={materialFormData.slug}
                    onChange={handleMaterialFormChange}
                    className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                    required
                  />
                </div>

                {/* Material Type */}
                <div>
                  <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                    Type
                  </label>
                  <input
                    type="text"
                    name="type"
                    value={materialFormData.type}
                    onChange={handleMaterialFormChange}
                    className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                  />
                </div>

                {/* Material Collection */}
                <div>
                  <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                    Collection
                  </label>
                  <input
                    type="text"
                    name="collection"
                    value={materialFormData.collection}
                    onChange={handleMaterialFormChange}
                    className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                  />
                </div>

                {/* Technical Types */}
                <div className="ay-col-span-full">
                  <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-2">
                    Technical Types
                  </label>
                  <div className="ay-flex ay-flex-wrap ay-gap-2 ay-border ay-border-gray-300 ay-rounded-md ay-p-3">
                    {technicalTypes.map((type) => (
                      <label
                        key={type}
                        className="ay-flex ay-items-center ay-gap-1 ay-cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          checked={materialFormData.technicalTypes.includes(
                            type
                          )}
                          onChange={() => handleTechnicalTypeChange(type)}
                          className="ay-h-4 ay-w-4 ay-text-blue-600 ay-rounded"
                        />
                        <span>{type}</span>
                      </label>
                    ))}
                    {technicalTypes.length === 0 && (
                      <p className="ay-text-sm ay-text-gray-500">
                        No technical types available.
                      </p>
                    )}
                  </div>
                </div>

                {/* Thumbnail/Color */}
                <div className="ay-col-span-full">
                  <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                    Thumbnail
                  </label>

                  <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-2 ay-gap-4">
                    <div>
                      <input
                        type="text"
                        name="thumbnail"
                        value={materialFormData.thumbnail}
                        onChange={handleMaterialFormChange}
                        placeholder="Color code (#RRGGBB) or image URL"
                        className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-mb-2"
                      />

                      <div className="ay-flex ay-items-center ay-gap-2">
                        <button
                          type="button"
                          onClick={() => setShowColorPicker(!showColorPicker)}
                          className="ay-px-3 ay-py-1 ay-bg-gray-200 ay-rounded ay-text-sm"
                        >
                          {showColorPicker ? "Hide" : "Show"} Color Picker
                        </button>

                        <span className="ay-text-sm ay-text-gray-500">or</span>

                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="ay-text-sm"
                        />
                      </div>

                      {showColorPicker && (
                        <div className="ay-mt-2">
                          <SketchPicker
                            color={materialFormData.thumbnail || "#ffffff"}
                            onChange={handleColorChange}
                          />
                        </div>
                      )}
                    </div>

                    <div>
                      <p className="ay-text-sm ay-font-medium ay-mb-2">
                        Preview:
                      </p>
                      {thumbnailPreview ? (
                        thumbnailPreview.startsWith("#") ||
                        thumbnailPreview.startsWith("rgb") ? (
                          <div
                            className="ay-w-16 ay-h-16 ay-rounded ay-border ay-border-gray-300"
                            style={{ backgroundColor: thumbnailPreview }}
                          ></div>
                        ) : (
                          <img
                            src={thumbnailPreview}
                            alt="Thumbnail preview"
                            className="ay-max-w-xs ay-max-h-32 ay-border ay-border-gray-300 ay-rounded"
                          />
                        )
                      ) : (
                        <div className="ay-w-16 ay-h-16 ay-bg-gray-200 ay-rounded ay-flex ay-items-center ay-justify-center">
                          <span className="ay-text-xs ay-text-gray-500">
                            No preview
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Variations */}
                <div className="ay-col-span-full ay-mt-4">
                  <div className="ay-flex ay-justify-between ay-items-center ay-mb-2">
                    <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700">
                      Variations
                    </label>
                    <button
                      type="button"
                      onClick={handleAddVariation}
                      className="ay-px-3 ay-py-1 ay-bg-blue-500 ay-text-white ay-rounded ay-text-sm"
                    >
                      Add Variation
                    </button>
                  </div>

                  {materialFormData.variations.length > 0 ? (
                    <div className="ay-space-y-3">
                      {materialFormData.variations.map((variation, index) => (
                        <div
                          key={index}
                          className="ay-border ay-border-gray-300 ay-rounded-md ay-p-3"
                        >
                          <div className="ay-flex ay-justify-between ay-mb-2">
                            <h4 className="ay-font-medium">
                              Variation {index + 1}
                            </h4>
                            <button
                              type="button"
                              onClick={() => handleRemoveVariation(index)}
                              className="ay-text-red-500 ay-text-sm"
                            >
                              Remove
                            </button>
                          </div>

                          <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-2 ay-gap-3">
                            <div>
                              <label className="ay-block ay-text-xs ay-font-medium ay-text-gray-700 ay-mb-1">
                                Name
                              </label>
                              <input
                                type="text"
                                value={variation.name}
                                onChange={(e) =>
                                  handleVariationChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                                className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                                placeholder="Variation name"
                              />
                            </div>

                            <div>
                              <label className="ay-block ay-text-xs ay-font-medium ay-text-gray-700 ay-mb-1">
                                Slug
                              </label>
                              <input
                                type="text"
                                value={variation.slug}
                                onChange={(e) =>
                                  handleVariationChange(
                                    index,
                                    "slug",
                                    e.target.value
                                  )
                                }
                                className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                                placeholder="variation-slug"
                              />
                            </div>

                            <div className="ay-col-span-full">
                              <label className="ay-block ay-text-xs ay-font-medium ay-text-gray-700 ay-mb-1">
                                Thumbnail
                              </label>
                              <input
                                type="text"
                                value={variation.thumbnail}
                                onChange={(e) =>
                                  handleVariationChange(
                                    index,
                                    "thumbnail",
                                    e.target.value
                                  )
                                }
                                className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                                placeholder="Color code or image URL"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="ay-text-sm ay-text-gray-500 ay-py-2">
                      No variations added.
                    </p>
                  )}
                </div>
              </div>

              <div className="ay-flex ay-justify-end ay-gap-3 ay-mt-6">
                <button
                  type="button"
                  onClick={() => setShowMaterialForm(false)}
                  className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-shadow-sm ay-bg-blue-500 ay-text-white ay-hover:ay-bg-blue-600"
                >
                  {editingMaterial ? "Update" : "Create"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Import Form Modal */}
      {showImportForm && (
        <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
          <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-2xl ay-max-h-screen ay-overflow-auto">
            <h2 className="ay-text-xl ay-font-bold ay-mb-4">
              Import Materials
            </h2>

            <form onSubmit={handleImportFormSubmit}>
              <div className="ay-mb-4">
                <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                  Paste JSON Data
                </label>
                <textarea
                  value={importFormData}
                  onChange={handleImportFormChange}
                  rows={10}
                  className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-font-mono ay-text-sm"
                  placeholder='{"struttura": ["V1 Pure White", ...], "ripiani": [...], ...}'
                  required
                ></textarea>
                <p className="ay-text-xs ay-text-gray-500 ay-mt-1">
                  Format: JSON object where keys are technical names and values
                  are arrays of material names.
                </p>
              </div>

              <div className="ay-flex ay-justify-end ay-gap-3">
                <button
                  type="button"
                  onClick={() => setShowImportForm(false)}
                  className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-shadow-sm ay-bg-blue-500 ay-text-white ay-hover:ay-bg-blue-600"
                >
                  Import
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Materials Management UI */}
      <div>
        {/* Header with actions */}
        <div className="ay-flex ay-justify-between ay-items-center ay-mb-6">
          <h2 className="ay-text-2xl ay-font-bold">Materials Management</h2>
          <div className="ay-flex ay-gap-2">
            <button
              onClick={() => setShowImportForm(true)}
              className="ay-px-4 ay-py-2 ay-bg-purple-500 ay-text-white ay-rounded ay-hover:ay-bg-purple-600"
            >
              Import Materials
            </button>
            <button
              onClick={handleAddMaterial}
              className="ay-px-4 ay-py-2 ay-bg-green-500 ay-text-white ay-rounded ay-hover:ay-bg-green-600"
            >
              Add Material
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="ay-bg-gray-50 ay-p-4 ay-rounded-lg ay-mb-6">
          <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-3 ay-gap-4">
            {/* Search */}
            <div>
              <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                Search
              </label>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                placeholder="Search by name, ID, or collection..."
              />
            </div>

            {/* Type Filter */}
            <div>
              <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                Type
              </label>
              <select
                value={activeType}
                onChange={(e) => setActiveType(e.target.value)}
                className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
              >
                <option value="all">All Types</option>
                {getUniqueTypes().map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {/* Technical Type Filter */}
            <div>
              <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                Technical Type
              </label>
              <select
                value={activeTechnicalType}
                onChange={(e) => setActiveTechnicalType(e.target.value)}
                className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
              >
                <option value="all">All Technical Types</option>
                {technicalTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Materials Table */}
        <div className="ay-overflow-x-auto ay-bg-white ay-rounded-lg ay-shadow">
          <table className="ay-min-w-full ay-divide-y ay-divide-gray-200">
            <thead className="ay-bg-gray-50">
              <tr>
                <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                  Material
                </th>
                <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                  Preview
                </th>
                <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                  Type
                </th>
                <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                  Collection
                </th>
                <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                  Technical Types
                </th>
                <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                  Variations
                </th>
                <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="ay-bg-white ay-divide-y ay-divide-gray-200">
              {filteredMaterials.length > 0 ? (
                filteredMaterials.map((material) => (
                  <React.Fragment key={material.id}>
                    <tr className="ay-hover:ay-bg-gray-50">
                      <td className="ay-px-6 ay-py-4">
                        <div className="ay-text-sm ay-font-medium ay-text-gray-900">
                          {material.name}
                        </div>
                        <div className="ay-text-sm ay-text-gray-500">
                          {material.id}
                        </div>
                      </td>
                      <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                        {material.thumbnail ? (
                          material.thumbnail.startsWith("#") ||
                          material.thumbnail.startsWith("rgb") ? (
                            <div
                              className="ay-w-8 ay-h-8 ay-rounded-full ay-border ay-border-gray-300"
                              style={{ backgroundColor: material.thumbnail }}
                            ></div>
                          ) : (
                            <img
                              src={material.thumbnail}
                              alt={material.name}
                              className="ay-w-8 ay-h-8 ay-rounded-full ay-object-cover"
                            />
                          )
                        ) : (
                          <div className="ay-w-8 ay-h-8 ay-bg-gray-200 ay-rounded-full"></div>
                        )}
                      </td>
                      <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap ay-text-sm ay-text-gray-500">
                        {material.type || "Default"}
                      </td>
                      <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap ay-text-sm ay-text-gray-500">
                        {material.collection || "Default"}
                      </td>
                      <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                        <div className="ay-flex ay-flex-wrap ay-gap-1">
                          {material.technicalTypes?.map((type) => (
                            <span
                              key={type}
                              className="ay-px-2 ay-py-1 ay-text-xs ay-bg-blue-100 ay-text-blue-800 ay-rounded"
                            >
                              {type}
                            </span>
                          )) || "None"}
                        </div>
                      </td>
                      <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                        {(material.variations?.length || 0) > 0 ? (
                          <button
                            onClick={() => toggleMaterialExpansion(material.id)}
                            className="ay-text-blue-500 ay-text-sm ay-underline"
                          >
                            {expandedMaterials[material.id] ? "Hide" : "Show"} (
                            {material.variations.length})
                          </button>
                        ) : (
                          <span className="ay-text-sm ay-text-gray-500">
                            None
                          </span>
                        )}
                      </td>
                      <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap ay-text-sm ay-font-medium">
                        <button
                          onClick={() => handleEditMaterial(material)}
                          className="ay-text-blue-600 ay-hover:ay-text-blue-900 ay-mr-3"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeleteMaterial(material.id)}
                          className="ay-text-red-600 ay-hover:ay-text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                    {/* Variations Expansion Row */}
                    {expandedMaterials[material.id] &&
                      material.variations?.length > 0 && (
                        <tr>
                          <td
                            colSpan="7"
                            className="ay-px-6 ay-py-4 ay-bg-gray-50"
                          >
                            <div className="ay-text-sm ay-font-medium ay-mb-2">
                              Variations:
                            </div>
                            <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-2 lg:ay-grid-cols-3 ay-gap-3">
                              {material.variations.map((variation, index) => (
                                <div
                                  key={index}
                                  className="ay-border ay-border-gray-300 ay-rounded-md ay-p-2 ay-bg-white"
                                >
                                  <div className="ay-flex ay-items-center ay-gap-2">
                                    {variation.thumbnail ? (
                                      variation.thumbnail.startsWith("#") ||
                                      variation.thumbnail.startsWith("rgb") ? (
                                        <div
                                          className="ay-w-6 ay-h-6 ay-rounded-full ay-border ay-border-gray-300"
                                          style={{
                                            backgroundColor:
                                              variation.thumbnail,
                                          }}
                                        ></div>
                                      ) : (
                                        <img
                                          src={variation.thumbnail}
                                          alt={variation.name}
                                          className="ay-w-6 ay-h-6 ay-rounded-full ay-object-cover"
                                        />
                                      )
                                    ) : (
                                      <div className="ay-w-6 ay-h-6 ay-bg-gray-200 ay-rounded-full"></div>
                                    )}
                                    <div>
                                      <div className="ay-font-medium">
                                        {variation.name}
                                      </div>
                                      <div className="ay-text-xs ay-text-gray-500">
                                        {variation.slug}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="7"
                    className="ay-px-6 ay-py-4 ay-text-center ay-text-gray-500"
                  >
                    No materials found.{" "}
                    {searchTerm ||
                    activeType !== "all" ||
                    activeTechnicalType !== "all"
                      ? "Try adjusting your search filters."
                      : "Add your first material."}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Materials Count */}
        <div className="ay-mt-4 ay-text-sm ay-text-gray-500">
          Showing {filteredMaterials.length} of {materialsData.materials.length}{" "}
          materials
        </div>
      </div>
    </div>
  );
};

export default MaterialsTab;
