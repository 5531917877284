// components/admin/ViewsTab.js
import React, { useState, useEffect } from "react";
import {
  fetchProductViews,
  addProductView,
  updateProductView,
  deleteProductView,
} from "../../api";

const ViewsTab = ({ selectedProductCode, selectedProduct, setLoading }) => {
  // State for views
  const [views, setViews] = useState([]);

  // UI state
  const [showViewForm, setShowViewForm] = useState(false);
  const [editingView, setEditingView] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    label: "",
    cameraPositionX: 0,
    cameraPositionY: 0,
    cameraPositionZ: 0,
    cameraTargetX: 0,
    cameraTargetY: 0,
    cameraTargetZ: 0,
    cameraFOV: 45,
    animate: false,
    animationDuration: 1.5,
  });

  // Current view data from the 3D viewer
  const [currentViewData, setCurrentViewData] = useState(null);

  // Load views when product changes
  useEffect(() => {
    if (selectedProductCode) {
      loadProductViews();
    }
  }, [selectedProductCode]);

  // Load product views
  const loadProductViews = async () => {
    setLoading(true);
    try {
      const viewsData = await fetchProductViews(selectedProductCode);
      setViews(viewsData || []);
    } catch (error) {
      console.error("Failed to load product views:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle getting current camera position from the viewer
  const getCurrentCameraPosition = () => {
    if (!window.emViewers || !window.emViewers["emersyaIframe"]) {
      alert("3D viewer not available");
      return;
    }

    const viewer = window.emViewers["emersyaIframe"];

    try {
      // Get current camera data from the viewer
      viewer.getCameraPosition().then((cameraData) => {
        setCurrentViewData(cameraData);

        // Update form with camera data
        setFormData((prev) => ({
          ...prev,
          cameraPositionX: parseFloat(cameraData.position.x.toFixed(3)),
          cameraPositionY: parseFloat(cameraData.position.y.toFixed(3)),
          cameraPositionZ: parseFloat(cameraData.position.z.toFixed(3)),
          cameraTargetX: parseFloat(cameraData.target.x.toFixed(3)),
          cameraTargetY: parseFloat(cameraData.target.y.toFixed(3)),
          cameraTargetZ: parseFloat(cameraData.target.z.toFixed(3)),
          cameraFOV: parseFloat(cameraData.fov.toFixed(1)),
        }));
      });
    } catch (error) {
      console.error("Failed to get camera position:", error);
      alert("Failed to get camera position from viewer");
    }
  };

  // Handle adding a new view
  const handleAddView = () => {
    setEditingView(null);
    setFormData({
      label: "",
      cameraPositionX: 0,
      cameraPositionY: 0,
      cameraPositionZ: 0,
      cameraTargetX: 0,
      cameraTargetY: 0,
      cameraTargetZ: 0,
      cameraFOV: 45,
      animate: false,
      animationDuration: 1.5,
    });
    setCurrentViewData(null);
    setShowViewForm(true);
  };

  // Handle editing a view
  const handleEditView = (view) => {
    setEditingView(view);
    setFormData({
      label: view.label,
      cameraPositionX: parseFloat(view.cameraPosition?.x || 0),
      cameraPositionY: parseFloat(view.cameraPosition?.y || 0),
      cameraPositionZ: parseFloat(view.cameraPosition?.z || 0),
      cameraTargetX: parseFloat(view.cameraTarget?.x || 0),
      cameraTargetY: parseFloat(view.cameraTarget?.y || 0),
      cameraTargetZ: parseFloat(view.cameraTarget?.z || 0),
      cameraFOV: parseFloat(view.cameraFOV || 45),
      animate: Boolean(view.animate),
      animationDuration: parseFloat(view.animationDuration || 1.5),
    });
    setCurrentViewData(null);
    setShowViewForm(true);
  };

  // Handle deleting a view
  const handleDeleteView = async (viewLabel) => {
    if (
      !window.confirm(
        `Are you sure you want to delete the view "${viewLabel}"?`
      )
    ) {
      return;
    }

    setLoading(true);
    try {
      await deleteProductView(selectedProductCode, viewLabel);
      await loadProductViews();
    } catch (error) {
      console.error("Failed to delete view:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle applying a view to the viewer
  const handleApplyView = (view) => {
    if (!window.emViewers || !window.emViewers["emersyaIframe"]) {
      alert("3D viewer not available");
      return;
    }

    const viewer = window.emViewers["emersyaIframe"];

    try {
      viewer.lookAtPosition({
        position: {
          x: view.cameraPosition?.x || 0,
          y: view.cameraPosition?.y || 0,
          z: view.cameraPosition?.z || 0,
        },
        target: {
          x: view.cameraTarget?.x || 0,
          y: view.cameraTarget?.y || 0,
          z: view.cameraTarget?.z || 0,
        },
        fov: view.cameraFOV || 45,
        animate: view.animate || false,
        animationDuration: view.animationDuration || 1.5,
      });
    } catch (error) {
      console.error("Failed to apply view:", error);
      alert("Failed to apply view to viewer");
    }
  };

  // Handle form input changes
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle different input types
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else if (type === "number") {
      setFormData((prev) => ({
        ...prev,
        [name]: value === "" ? 0 : parseFloat(value),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare view data
    const viewData = {
      label: formData.label,
      cameraPosition: {
        x: formData.cameraPositionX,
        y: formData.cameraPositionY,
        z: formData.cameraPositionZ,
      },
      cameraTarget: {
        x: formData.cameraTargetX,
        y: formData.cameraTargetY,
        z: formData.cameraTargetZ,
      },
      cameraFOV: formData.cameraFOV,
      animate: formData.animate,
      animationDuration: formData.animationDuration,
    };

    setLoading(true);
    try {
      if (editingView) {
        await updateProductView(
          selectedProductCode,
          editingView.label,
          viewData
        );
      } else {
        await addProductView(selectedProductCode, viewData);
      }

      await loadProductViews();
      setShowViewForm(false);
    } catch (error) {
      console.error("Failed to save view:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ay-relative">
      {/* View Form Modal */}
      {showViewForm && (
        <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
          <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-2xl ay-max-h-screen ay-overflow-auto">
            <h2 className="ay-text-xl ay-font-bold ay-mb-4">
              {editingView ? "Edit View" : "Add New View"}
            </h2>

            <form onSubmit={handleSubmit}>
              <div className="ay-mb-4">
                <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                  View Label *
                </label>
                <input
                  type="text"
                  name="label"
                  value={formData.label}
                  onChange={handleFormChange}
                  className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                  required
                />
              </div>

              <div className="ay-mb-4 ay-flex ay-justify-end">
                <button
                  type="button"
                  onClick={getCurrentCameraPosition}
                  className="ay-px-4 ay-py-2 ay-bg-blue-500 ay-text-white ay-rounded ay-hover:ay-bg-blue-600"
                >
                  Get Current Camera Position
                </button>
              </div>

              <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-3 ay-gap-4 ay-mb-4">
                <div>
                  <h3 className="ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-2">
                    Camera Position
                  </h3>

                  <div className="ay-space-y-2">
                    <div>
                      <label className="ay-block ay-text-xs ay-text-gray-500">
                        X
                      </label>
                      <input
                        type="number"
                        name="cameraPositionX"
                        value={formData.cameraPositionX}
                        onChange={handleFormChange}
                        step="0.001"
                        className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                      />
                    </div>

                    <div>
                      <label className="ay-block ay-text-xs ay-text-gray-500">
                        Y
                      </label>
                      <input
                        type="number"
                        name="cameraPositionY"
                        value={formData.cameraPositionY}
                        onChange={handleFormChange}
                        step="0.001"
                        className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                      />
                    </div>

                    <div>
                      <label className="ay-block ay-text-xs ay-text-gray-500">
                        Z
                      </label>
                      <input
                        type="number"
                        name="cameraPositionZ"
                        value={formData.cameraPositionZ}
                        onChange={handleFormChange}
                        step="0.001"
                        className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-2">
                    Camera Target
                  </h3>

                  <div className="ay-space-y-2">
                    <div>
                      <label className="ay-block ay-text-xs ay-text-gray-500">
                        X
                      </label>
                      <input
                        type="number"
                        name="cameraTargetX"
                        value={formData.cameraTargetX}
                        onChange={handleFormChange}
                        step="0.001"
                        className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                      />
                    </div>

                    <div>
                      <label className="ay-block ay-text-xs ay-text-gray-500">
                        Y
                      </label>
                      <input
                        type="number"
                        name="cameraTargetY"
                        value={formData.cameraTargetY}
                        onChange={handleFormChange}
                        step="0.001"
                        className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                      />
                    </div>

                    <div>
                      <label className="ay-block ay-text-xs ay-text-gray-500">
                        Z
                      </label>
                      <input
                        type="number"
                        name="cameraTargetZ"
                        value={formData.cameraTargetZ}
                        onChange={handleFormChange}
                        step="0.001"
                        className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-2">
                    Other Settings
                  </h3>

                  <div className="ay-space-y-2">
                    <div>
                      <label className="ay-block ay-text-xs ay-text-gray-500">
                        Field of View (FOV)
                      </label>
                      <input
                        type="number"
                        name="cameraFOV"
                        value={formData.cameraFOV}
                        onChange={handleFormChange}
                        min="10"
                        max="120"
                        step="0.1"
                        className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                      />
                    </div>

                    <div className="ay-mt-4">
                      <label className="ay-flex ay-items-center ay-gap-2 ay-cursor-pointer">
                        <input
                          type="checkbox"
                          name="animate"
                          checked={formData.animate}
                          onChange={handleFormChange}
                          className="ay-h-4 ay-w-4 ay-text-blue-600 ay-rounded"
                        />
                        <span className="ay-text-sm">
                          Animate Camera Transition
                        </span>
                      </label>
                    </div>

                    {formData.animate && (
                      <div>
                        <label className="ay-block ay-text-xs ay-text-gray-500">
                          Animation Duration (seconds)
                        </label>
                        <input
                          type="number"
                          name="animationDuration"
                          value={formData.animationDuration}
                          onChange={handleFormChange}
                          min="0.1"
                          max="10"
                          step="0.1"
                          className="ay-block ay-w-full ay-px-3 ay-py-1 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500 ay-text-sm"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {currentViewData && (
                <div className="ay-bg-blue-50 ay-p-3 ay-rounded-md ay-mb-4">
                  <p className="ay-text-sm ay-text-blue-800">
                    Current camera position has been applied to the form.
                  </p>
                </div>
              )}

              <div className="ay-flex ay-justify-end ay-gap-3 ay-mt-6">
                <button
                  type="button"
                  onClick={() => setShowViewForm(false)}
                  className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-shadow-sm ay-bg-blue-500 ay-text-white ay-hover:ay-bg-blue-600"
                >
                  {editingView ? "Update" : "Create"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div>
        {/* Header with actions */}
        <div className="ay-flex ay-justify-between ay-items-center ay-mb-6">
          <div>
            <h2 className="ay-text-2xl ay-font-bold">
              Custom Views for {selectedProduct?.name}
            </h2>
            <p className="ay-text-gray-600">
              Code: {selectedProduct?.code} | Family: {selectedProduct?.family}
            </p>
          </div>
          <button
            onClick={handleAddView}
            className="ay-px-4 ay-py-2 ay-bg-green-500 ay-text-white ay-rounded ay-hover:ay-bg-green-600"
          >
            Add New View
          </button>
        </div>

        {/* Views List */}
        <div className="ay-bg-white ay-rounded-lg ay-shadow ay-overflow-hidden">
          <div className="ay-p-4 ay-border-b">
            <h3 className="ay-font-medium">Saved Camera Views</h3>
            <p className="ay-text-sm ay-text-gray-500">
              Configure custom camera positions for this product.
            </p>
          </div>

          {views.length > 0 ? (
            <div className="ay-divide-y ay-divide-gray-200">
              {views.map((view) => (
                <div
                  key={view.label}
                  className="ay-p-4 ay-flex ay-justify-between ay-items-center"
                >
                  <div>
                    <h4 className="ay-font-medium">{view.label}</h4>
                    <div className="ay-text-sm ay-text-gray-500 ay-mt-1">
                      Position: [{view.cameraPosition?.x.toFixed(2) || 0},{" "}
                      {view.cameraPosition?.y.toFixed(2) || 0},{" "}
                      {view.cameraPosition?.z.toFixed(2) || 0}]
                      {view.animate &&
                        ` • Animated (${view.animationDuration || 1.5}s)`}
                    </div>
                  </div>

                  <div className="ay-flex ay-gap-2">
                    <button
                      onClick={() => handleApplyView(view)}
                      className="ay-px-3 ay-py-1 ay-bg-gray-200 ay-text-gray-800 ay-rounded ay-text-sm ay-hover:ay-bg-gray-300"
                    >
                      Apply
                    </button>
                    <button
                      onClick={() => handleEditView(view)}
                      className="ay-px-3 ay-py-1 ay-bg-blue-500 ay-text-white ay-rounded ay-text-sm ay-hover:ay-bg-blue-600"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteView(view.label)}
                      className="ay-px-3 ay-py-1 ay-bg-red-500 ay-text-white ay-rounded ay-text-sm ay-hover:ay-bg-red-600"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="ay-p-8 ay-text-center">
              <p className="ay-text-gray-500 ay-mb-4">
                No custom views found for this product.
              </p>
              <button
                onClick={handleAddView}
                className="ay-px-4 ay-py-2 ay-bg-blue-500 ay-text-white ay-rounded ay-hover:ay-bg-blue-600"
              >
                Create Your First Custom View
              </button>
            </div>
          )}
        </div>

        {/* Tips Section */}
        <div className="ay-mt-6 ay-bg-blue-50 ay-p-4 ay-rounded-lg">
          <h3 className="ay-font-medium ay-mb-2">Tips for Creating Views</h3>
          <ul className="ay-list-disc ay-pl-5 ay-space-y-1 ay-text-sm ay-text-blue-800">
            <li>
              Use the 3D viewer to position the camera exactly how you want.
            </li>
            <li>
              Click "Get Current Camera Position" to capture the current view.
            </li>
            <li>
              Give your view a descriptive name like "Front", "Back", or
              "Detail".
            </li>
            <li>Enable animation for smooth transitions between views.</li>
            <li>
              Custom views are useful for showcasing specific product features
              or angles.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ViewsTab;
