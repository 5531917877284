// components/admin/ProductsManagerModal.js
import React, { useState, useEffect } from "react";
import {
  FiX,
  FiEdit,
  FiTrash2,
  FiEye,
  FiDownload,
  FiFilter,
  FiRefreshCw,
  FiSearch,
  FiTag,
  FiPackage,
  FiLayers,
  FiCode,
  FiDollarSign,
} from "react-icons/fi";

const ProductsManagerModal = ({
  products,
  context,
  onClose,
  onProductEdit,
  onProductDelete,
  onProductView,
}) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Initialize filtered products
  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  // Handle search
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (!term.trim()) {
      setFilteredProducts(products);
      return;
    }

    const searchResults = products.filter(
      (product) =>
        product.name.toLowerCase().includes(term.toLowerCase()) ||
        product.code.toLowerCase().includes(term.toLowerCase()) ||
        (product.projectCode &&
          product.projectCode.toLowerCase().includes(term.toLowerCase())) ||
        (product.slug &&
          product.slug.toLowerCase().includes(term.toLowerCase())) ||
        (product.tag && product.tag.toLowerCase().includes(term.toLowerCase()))
    );

    setFilteredProducts(searchResults);
  };

  // Handle sorting
  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedProducts = [...filteredProducts].sort((a, b) => {
      // Handle null values
      if (!a[key] && !b[key]) return 0;
      if (!a[key]) return 1;
      if (!b[key]) return -1;

      // Handle string comparison
      if (typeof a[key] === "string") {
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      }

      // Handle number comparison
      return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
    });

    setFilteredProducts(sortedProducts);
  };

  // Export products data as CSV
  const handleExportCSV = () => {
    // Create header row
    const headers = ["name", "code", "slug", "projectCode", "price", "tag"];
    const headerRow = headers.join(",");

    // Create data rows
    const dataRows = filteredProducts.map((product) => {
      return headers
        .map((header) => {
          // Wrap values in quotes to handle commas in strings
          const value =
            product[header] !== undefined && product[header] !== null
              ? String(product[header]).replace(/"/g, '""') // Escape quotes
              : "";
          return `"${value}"`;
        })
        .join(",");
    });

    // Combine header and data rows
    const csvContent = [headerRow, ...dataRows].join("\n");

    // Create and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `products_${context.category}_${context.family}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Get sort direction indicator
  const getSortDirectionIndicator = (key) => {
    if (sortConfig.key !== key) {
      return null;
    }
    return sortConfig.direction === "asc" ? "↑" : "↓";
  };

  return (
    <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50 ay-overflow-y-auto ay-py-10">
      <div className="ay-bg-white ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-7xl ay-max-h-full ay-flex ay-flex-col ay-mx-4">
        <div className="ay-flex ay-justify-between ay-items-center ay-p-4 ay-border-b ay-border-gray-200">
          <h2 className="ay-text-xl ay-font-bold ay-flex ay-items-center ay-gap-2">
            <FiLayers className="ay-text-blue-500" />
            Products Manager: {context.category} / {context.family}
          </h2>
          <button
            onClick={onClose}
            className="ay-p-2 ay-text-gray-500 ay-hover:ay-text-gray-700 ay-rounded-full ay-hover:ay-bg-gray-100"
          >
            <FiX />
          </button>
        </div>

        {/* Toolbar */}
        <div className="ay-flex ay-items-center ay-justify-between ay-p-4 ay-bg-gray-50">
          <div className="ay-flex ay-items-center ay-gap-2 ay-w-1/2">
            <div className="ay-relative ay-flex-grow">
              <div className="ay-absolute ay-inset-y-0 ay-left-0 ay-pl-3 ay-flex ay-items-center ay-pointer-events-none">
                <FiSearch className="ay-text-gray-400" />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search products..."
                className="ay-block ay-w-full ay-pl-10 ay-pr-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
              />
            </div>
            <button
              onClick={() => setFilteredProducts(products)}
              className="ay-p-2 ay-bg-gray-200 ay-text-gray-700 ay-rounded ay-hover:ay-bg-gray-300 ay-flex ay-items-center ay-gap-1"
              title="Reset filters"
            >
              <FiRefreshCw />
            </button>
          </div>

          <div className="ay-flex ay-items-center ay-gap-2">
            <span className="ay-text-sm ay-text-gray-500">
              {filteredProducts.length} of {products.length} products
            </span>
            <button
              onClick={handleExportCSV}
              className="ay-px-3 ay-py-2 ay-bg-green-500 ay-text-white ay-rounded ay-hover:ay-bg-green-600 ay-flex ay-items-center ay-gap-1"
            >
              <FiDownload /> Export CSV
            </button>
          </div>
        </div>

        {/* Products Table */}
        <div className="ay-overflow-x-auto ay-flex-grow">
          <table className="ay-min-w-full ay-divide-y ay-divide-gray-200">
            <thead className="ay-bg-gray-50 ay-sticky ay-top-0">
              <tr>
                <th
                  className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider ay-cursor-pointer ay-hover:ay-bg-gray-100"
                  onClick={() => requestSort("name")}
                >
                  <div className="ay-flex ay-items-center ay-gap-1">
                    Name {getSortDirectionIndicator("name")}
                  </div>
                </th>
                <th
                  className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider ay-cursor-pointer ay-hover:ay-bg-gray-100"
                  onClick={() => requestSort("code")}
                >
                  <div className="ay-flex ay-items-center ay-gap-1">
                    <FiCode className="ay-text-gray-400" />
                    Code {getSortDirectionIndicator("code")}
                  </div>
                </th>
                <th
                  className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider ay-cursor-pointer ay-hover:ay-bg-gray-100"
                  onClick={() => requestSort("slug")}
                >
                  <div className="ay-flex ay-items-center ay-gap-1">
                    Slug {getSortDirectionIndicator("slug")}
                  </div>
                </th>
                <th
                  className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider ay-cursor-pointer ay-hover:ay-bg-gray-100"
                  onClick={() => requestSort("projectCode")}
                >
                  <div className="ay-flex ay-items-center ay-gap-1">
                    <FiPackage className="ay-text-gray-400" />
                    Project Code {getSortDirectionIndicator("projectCode")}
                  </div>
                </th>
                <th
                  className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider ay-cursor-pointer ay-hover:ay-bg-gray-100"
                  onClick={() => requestSort("price")}
                >
                  <div className="ay-flex ay-items-center ay-gap-1">
                    <FiDollarSign className="ay-text-gray-400" />
                    Price {getSortDirectionIndicator("price")}
                  </div>
                </th>
                <th
                  className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider ay-cursor-pointer ay-hover:ay-bg-gray-100"
                  onClick={() => requestSort("tag")}
                >
                  <div className="ay-flex ay-items-center ay-gap-1">
                    <FiTag className="ay-text-gray-400" />
                    Tag {getSortDirectionIndicator("tag")}
                  </div>
                </th>
                <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="ay-bg-white ay-divide-y ay-divide-gray-200">
              {filteredProducts.length === 0 ? (
                <tr>
                  <td
                    colSpan="7"
                    className="ay-px-6 ay-py-8 ay-text-center ay-text-gray-500"
                  >
                    {searchTerm
                      ? "No products match your search."
                      : "No products found in this family."}
                  </td>
                </tr>
              ) : (
                filteredProducts.map((product) => (
                  <tr
                    key={product.code}
                    className="ay-hover:ay-bg-gray-50 ay-transition-colors"
                  >
                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                      <div className="ay-text-sm ay-font-medium ay-text-gray-900">
                        {product.name}
                      </div>
                    </td>
                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                      <code className="ay-text-sm ay-font-mono ay-bg-gray-100 ay-px-2 ay-py-1 ay-rounded">
                        {product.code}
                      </code>
                    </td>
                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                      <code className="ay-text-xs ay-font-mono ay-bg-gray-100 ay-px-2 ay-py-1 ay-rounded">
                        {product.slug || "-"}
                      </code>
                    </td>
                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                      {product.projectCode || "-"}
                    </td>
                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                      {product.price
                        ? `€${product.price.toLocaleString()}`
                        : "-"}
                    </td>
                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                      {product.tag ? (
                        <span className="ay-px-2 ay-py-1 ay-text-xs ay-bg-blue-100 ay-text-blue-800 ay-rounded">
                          {product.tag}
                        </span>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap ay-text-sm ay-font-medium">
                      <div className="ay-flex ay-gap-2">
                        <button
                          onClick={() => onProductView(product.code)}
                          className="ay-p-1.5 ay-bg-blue-500 ay-text-white ay-rounded ay-hover:ay-bg-blue-600"
                          title="View Product"
                        >
                          <FiEye />
                        </button>
                        <button
                          onClick={() => onProductEdit(product)}
                          className="ay-p-1.5 ay-bg-yellow-500 ay-text-white ay-rounded ay-hover:ay-bg-yellow-600"
                          title="Edit Product"
                        >
                          <FiEdit />
                        </button>
                        <button
                          onClick={() => onProductDelete(product)}
                          className="ay-p-1.5 ay-bg-red-500 ay-text-white ay-rounded ay-hover:ay-bg-red-600"
                          title="Delete Product"
                        >
                          <FiTrash2 />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Footer */}
        <div className="ay-px-4 ay-py-3 ay-bg-gray-50 ay-text-right ay-border-t ay-border-gray-200">
          <button
            onClick={onClose}
            className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductsManagerModal;
