import React, { useState, useEffect, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  fetchProductStructure,
  fetchCategory,
  addCategory,
  updateCategory,
  deleteCategory,
  addFamily,
  updateFamily,
  deleteFamily,
  addProduct,
  updateProduct,
  deleteProduct,
  fetchMaterialLinks,
  reorderCategories,
  reorderFamilies,
  reorderProducts,
} from "../../api";
import CategoryForm from "./CategoryForm";
import FamilyForm from "./FamilyForm";
import ProductForm from "./ProductForm";
import ConfirmationModal from "./ConfirmationModal";
import ProductsManagerModal from "./ProductsManagerModal";
import {
  FiPlus,
  FiEdit,
  FiTrash2,
  FiChevronRight,
  FiChevronDown,
  FiGrid,
  FiEye,
  FiTag,
  FiDollarSign,
  FiCode,
  FiPackage,
  FiMove,
} from "react-icons/fi";

const ProductsTab = ({
  products,
  setProducts,
  categories,
  setCategories,
  onProductChange,
  setLoading,
}) => {
  // State for structure and expansions
  const [productStructure, setProductStructure] = useState({});
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedFamilies, setExpandedFamilies] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // State for forms
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [showFamilyForm, setShowFamilyForm] = useState(false);
  const [showProductForm, setShowProductForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // State for material links (used in forms)
  const [materialLinks, setMaterialLinks] = useState([]);

  // Confirmation modal
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    message: "",
    onConfirm: () => {},
  });

  // Products manager modal
  const [showProductsManager, setShowProductsManager] = useState(false);
  const [productsToManage, setProductsToManage] = useState([]);
  const [manageContext, setManageContext] = useState({
    category: "",
    family: "",
  });

  // Initial load
  useEffect(() => {
    loadProductStructure();
    loadMaterialLinks();
    // eslint-disable-next-line
  }, []);

  // Load product structure
  const loadProductStructure = async () => {
    setLoading(true);
    try {
      const structure = await fetchProductStructure();

      // Update the product structure in state
      setProductStructure(structure);

      // Merge expansions so we don't collapse everything
      setExpandedCategories((prev) => {
        const updated = { ...prev };
        // For each category in the new structure, if we don't have an entry, default to false
        for (const cat of Object.keys(structure)) {
          if (updated[cat] === undefined) {
            updated[cat] = false;
          }
        }
        // Remove expansions for categories that no longer exist
        for (const cat of Object.keys(updated)) {
          if (!structure[cat]) delete updated[cat];
        }
        return updated;
      });

      setExpandedFamilies((prev) => {
        const updated = { ...prev };
        // Same logic for families
        for (const cat of Object.keys(structure)) {
          for (const fam of structure[cat]) {
            const key = `${cat}-${fam}`;
            if (updated[key] === undefined) {
              updated[key] = false;
            }
          }
        }
        // Remove families not in the structure anymore
        for (const key of Object.keys(updated)) {
          const [cat, fam] = key.split("-");
          if (!structure[cat] || !structure[cat].includes(fam)) {
            delete updated[key];
          }
        }
        return updated;
      });

      // Update categories
      setCategories(Object.keys(structure));
    } catch (error) {
      console.error("Failed to load product structure:", error);
    } finally {
      setLoading(false);
    }
  };

  // Load material links
  const loadMaterialLinks = async () => {
    try {
      const links = await fetchMaterialLinks();
      setMaterialLinks(links);
    } catch (error) {
      console.error("Failed to load material links:", error);
    }
  };

  // Toggle category
  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Toggle family
  const toggleFamily = (category, family) => {
    const key = `${category}-${family}`;
    setExpandedFamilies((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Add category
  const handleAddCategory = () => {
    setIsEditing(false);
    setSelectedCategory(null);
    setShowCategoryForm(true);
  };

  // Edit category
  const handleEditCategory = (category) => {
    setIsEditing(true);
    setSelectedCategory(category);
    setShowCategoryForm(true);
  };

  // Save category
  const handleSaveCategory = async (categoryData) => {
    setLoading(true);
    try {
      if (isEditing) {
        await updateCategory(selectedCategory, categoryData.name);
      } else {
        await addCategory(categoryData.name);
      }
      // Refresh structure
      await loadProductStructure();
      setShowCategoryForm(false);
    } catch (error) {
      console.error("Failed to save category:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Confirm delete category
  const handleConfirmDeleteCategory = (category) => {
    setConfirmModal({
      isOpen: true,
      title: "Delete Category",
      message: `Are you sure you want to delete the category "${category}" and all its families/products?`,
      onConfirm: () => handleDeleteCategory(category),
    });
  };

  // Delete category
  const handleDeleteCategory = async (category) => {
    setLoading(true);
    try {
      await deleteCategory(category);
      await loadProductStructure();
      setConfirmModal({ isOpen: false });
    } catch (error) {
      console.error("Failed to delete category:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Add family
  const handleAddFamily = (category) => {
    setIsEditing(false);
    setSelectedCategory(category);
    setSelectedFamily(null);
    setShowFamilyForm(true);
  };

  // Edit family
  const handleEditFamily = (category, family) => {
    setIsEditing(true);
    setSelectedCategory(category);
    setSelectedFamily(family);
    setShowFamilyForm(true);
  };

  // Save family
  const handleSaveFamily = async (familyData) => {
    setLoading(true);
    try {
      if (isEditing) {
        await updateFamily(selectedCategory, selectedFamily, familyData.name, {
          defaultMaterialLinks: familyData.defaultMaterialLinks,
          defaultMaterials: familyData.defaultMaterials,
          typeLinks: familyData.typeLinks,
        });
      } else {
        await addFamily(selectedCategory, familyData.name, {
          defaultMaterialLinks: familyData.defaultMaterialLinks,
          defaultMaterials: familyData.defaultMaterials,
          typeLinks: familyData.typeLinks,
        });
      }
      // Refresh structure
      await loadProductStructure();
      setShowFamilyForm(false);
    } catch (error) {
      console.error("Failed to save family:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Confirm delete family
  const handleConfirmDeleteFamily = (category, family) => {
    setConfirmModal({
      isOpen: true,
      title: "Delete Family",
      message: `Are you sure you want to delete the family "${family}" from "${category}"?`,
      onConfirm: () => handleDeleteFamily(category, family),
    });
  };

  // Delete family
  const handleDeleteFamily = async (category, family) => {
    setLoading(true);
    try {
      await deleteFamily(category, family);
      await loadProductStructure();
      setConfirmModal({ isOpen: false });
    } catch (error) {
      console.error("Failed to delete family:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Add product
  const handleAddProduct = (category, family) => {
    setIsEditing(false);
    setSelectedCategory(category);
    setSelectedFamily(family);
    setSelectedProduct(null);
    setShowProductForm(true);
  };

  // Edit product
  const handleEditProduct = (product) => {
    setIsEditing(true);
    setSelectedCategory(product.category);
    setSelectedFamily(product.family);
    setSelectedProduct(product);
    setShowProductForm(true);
  };

  // Save product
  const handleSaveProduct = async (productData) => {
    setLoading(true);
    try {
      if (isEditing) {
        await updateProduct({
          ...productData,
          code: selectedProduct.code,
          category: selectedCategory,
          family: selectedFamily,
        });
      } else {
        await addProduct({
          ...productData,
          category: selectedCategory,
          family: selectedFamily,
        });
      }
      // Refresh the products array in local state
      const updatedCategory = await fetchCategory(selectedCategory);
      setProducts(updatedCategory.products || []);

      // Reload structure so the new product is visible in UI
      await loadProductStructure();
      setShowProductForm(false);
    } catch (error) {
      console.error("Failed to save product:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Confirm delete product
  const handleConfirmDeleteProduct = (product) => {
    setConfirmModal({
      isOpen: true,
      title: "Delete Product",
      message: `Are you sure you want to delete "${product.name}" (${product.code})?`,
      onConfirm: () => handleDeleteProduct(product),
    });
  };

  // Delete product
  const handleDeleteProduct = async (product) => {
    setLoading(true);
    try {
      await deleteProduct(product.code);
      const updatedCategory = await fetchCategory(product.category);
      setProducts(updatedCategory.products || []);
      await loadProductStructure();
      setConfirmModal({ isOpen: false });
    } catch (error) {
      console.error("Failed to delete product:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Products Manager
  const handleOpenProductsManager = (category, family) => {
    const familyProducts = getProductsForFamily(category, family);
    setProductsToManage(familyProducts);
    setManageContext({ category, family });
    setShowProductsManager(true);
  };

  // Best-match category
  const findBestMatchCategory = (categoryName) => {
    if (!categoryName) return null;
    if (productStructure[categoryName]) return categoryName;

    const lower = categoryName.toLowerCase();
    const match = Object.keys(productStructure).find(
      (c) => c.toLowerCase() === lower
    );
    if (match) return match;

    const parts = categoryName.split("-");
    if (parts.length > 1) {
      if (productStructure[parts[0]]) return parts[0];
      const firstPartMatch = Object.keys(productStructure).find(
        (c) => c.toLowerCase() === parts[0].toLowerCase()
      );
      if (firstPartMatch) return firstPartMatch;
    }
    return null;
  };

  // Best-match family
  const findBestMatchFamily = (categoryName, familyName) => {
    if (!categoryName || !familyName) return null;
    const matchedCategory = findBestMatchCategory(categoryName);
    if (!matchedCategory) return null;

    const families = productStructure[matchedCategory] || [];
    if (families.includes(familyName)) return familyName;

    const lower = familyName.toLowerCase();
    const direct = families.find((f) => f.toLowerCase() === lower);
    if (direct) return direct;

    // Fuzzy
    for (const fam of families) {
      if (
        fam.toLowerCase().includes(lower) ||
        lower.includes(fam.toLowerCase())
      ) {
        return fam;
      }
    }
    return null;
  };

  // Get products for a family
  const getProductsForFamily = (categoryName, familyName) => {
    // Among all products in state, find matches
    const matchedCat = findBestMatchCategory(categoryName);
    const matchedFam = findBestMatchFamily(categoryName, familyName);

    if (matchedCat && matchedFam) {
      return products.filter(
        (p) => p.category === matchedCat && p.family === matchedFam
      );
    }
    return [];
  };

  // *** KEY CHANGE: Remove the final "await loadProductStructure()" call here ***
  const handleDragEnd = useCallback(
    async (result) => {
      const { destination, source, type } = result;
      if (
        !destination ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return;
      }

      setLoading(true);
      try {
        // Local copy
        let newStructure = { ...productStructure };

        if (type === "CATEGORY") {
          // Reorder categories locally
          const catOrder = Object.keys(productStructure);
          const [removed] = catOrder.splice(source.index, 1);
          catOrder.splice(destination.index, 0, removed);

          const updatedStructure = {};
          catOrder.forEach((cat) => {
            updatedStructure[cat] = productStructure[cat];
          });
          setProductStructure(updatedStructure);

          // Server reorder
          await reorderCategories(catOrder);
        } else if (type === "FAMILY") {
          const categoryId = source.droppableId.replace("category-", "");
          if (destination.droppableId === source.droppableId) {
            // Reorder within same category
            const famOrder = [...productStructure[categoryId]];
            const [removed] = famOrder.splice(source.index, 1);
            famOrder.splice(destination.index, 0, removed);

            newStructure[categoryId] = famOrder;
            setProductStructure(newStructure);

            await reorderFamilies(categoryId, famOrder);
          } else {
            // Move family to another category? (Optional if not supported by your backend)
            const targetCat = destination.droppableId.replace("category-", "");
            const famToMove = productStructure[categoryId][source.index];

            const sourceFamilies = [...productStructure[categoryId]];
            sourceFamilies.splice(source.index, 1);
            newStructure[categoryId] = sourceFamilies;

            const targetFamilies = [...productStructure[targetCat]];
            targetFamilies.splice(destination.index, 0, famToMove);
            newStructure[targetCat] = targetFamilies;

            setProductStructure(newStructure);
            // You'd need a custom API call to truly move families across categories
          }
        } else if (type === "PRODUCT") {
          // Reorder products within a single family
          const droppableIdParts = source.droppableId.split("-");
          if (droppableIdParts[0] !== "family" || droppableIdParts.length < 3) {
            return;
          }
          const encodedFamilyId = droppableIdParts.pop();
          const encodedCategoryId = droppableIdParts.slice(1).join("-");
          let catId = decodeURIComponent(encodedCategoryId);
          let famId = decodeURIComponent(encodedFamilyId);

          const matchedCat = findBestMatchCategory(catId);
          const matchedFam = findBestMatchFamily(catId, famId);
          if (matchedCat && matchedFam) {
            catId = matchedCat;
            famId = matchedFam;
          }

          if (destination.droppableId === source.droppableId) {
            // Reorder in same family
            const famProducts = getProductsForFamily(catId, famId);
            const productOrder = famProducts.map((p) => p.code);
            const [removed] = productOrder.splice(source.index, 1);
            productOrder.splice(destination.index, 0, removed);

            // Reorder on server
            await reorderProducts(catId, famId, productOrder);

            // Optionally, reload or partially update local products
            // so it matches new order. We'll re-fetch that single category:
            const updated = await fetchCategory(catId);
            setProducts(updated.products || []);
          } else {
            // Move product to a different family (if your backend supports it)
            alert("Moving products between families not yet supported");
          }
        }

        // *** We do NOT call loadProductStructure() again ***
        // This prevents the big "jump" and keeps accordions open
      } catch (error) {
        console.error("Reorder error:", error);
        alert(error.message);
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      productStructure,
      setProductStructure,
      reorderCategories,
      reorderFamilies,
      reorderProducts,
      fetchCategory,
      setProducts,
    ]
  );

  return (
    <div className="ay-relative">
      {/* Confirmation Modal */}
      {confirmModal.isOpen && (
        <ConfirmationModal
          title={confirmModal.title}
          message={confirmModal.message}
          onConfirm={confirmModal.onConfirm}
          onCancel={() => setConfirmModal({ isOpen: false })}
        />
      )}

      {/* Category Form Modal */}
      {showCategoryForm && (
        <CategoryForm
          category={selectedCategory}
          isEditing={isEditing}
          onSave={handleSaveCategory}
          onCancel={() => setShowCategoryForm(false)}
        />
      )}

      {/* Family Form Modal */}
      {showFamilyForm && (
        <FamilyForm
          category={selectedCategory}
          family={selectedFamily}
          isEditing={isEditing}
          materialLinks={materialLinks}
          onSave={handleSaveFamily}
          onCancel={() => setShowFamilyForm(false)}
        />
      )}

      {/* Product Form Modal */}
      {showProductForm && (
        <ProductForm
          product={selectedProduct}
          category={selectedCategory}
          family={selectedFamily}
          isEditing={isEditing}
          materialLinks={materialLinks}
          onSave={handleSaveProduct}
          onCancel={() => setShowProductForm(false)}
        />
      )}

      {/* Products Manager Modal */}
      {showProductsManager && (
        <ProductsManagerModal
          products={productsToManage}
          context={manageContext}
          onClose={() => setShowProductsManager(false)}
          onProductEdit={handleEditProduct}
          onProductDelete={handleConfirmDeleteProduct}
          onProductView={onProductChange}
        />
      )}

      {/* Header */}
      <div className="ay-flex ay-justify-between ay-items-center ay-mb-6">
        <h2 className="ay-text-2xl ay-font-bold">Product Management</h2>
        <button
          onClick={handleAddCategory}
          className="ay-px-4 ay-py-2 ay-bg-green-500 ay-text-white ay-rounded ay-hover:ay-bg-green-600 ay-flex ay-items-center ay-gap-2"
        >
          <FiPlus /> Add Category
        </button>
      </div>

      {/* Tree with DragDrop */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="categories" type="CATEGORY">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="ay-space-y-4"
            >
              {Object.keys(productStructure).length === 0 ? (
                <div className="ay-text-center ay-py-8 ay-text-gray-500">
                  <p>No categories found.</p>
                  <button
                    onClick={handleAddCategory}
                    className="ay-mt-2 ay-text-blue-500 ay-underline ay-flex ay-items-center ay-gap-1 ay-justify-center"
                  >
                    <FiPlus /> Add your first category
                  </button>
                </div>
              ) : (
                Object.keys(productStructure).map((cat, catIndex) => (
                  <Draggable
                    key={cat}
                    draggableId={`category-${cat}`}
                    index={catIndex}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`ay-border ay-rounded-lg ay-overflow-hidden ${
                          snapshot.isDragging
                            ? "ay-opacity-70 ay-shadow-lg"
                            : ""
                        }`}
                      >
                        {/* Category header */}
                        <div
                          className="ay-bg-gray-100 ay-p-4 ay-flex ay-justify-between ay-items-center ay-cursor-pointer"
                          onClick={() => toggleCategory(cat)}
                        >
                          <div className="ay-flex ay-items-center ay-gap-2 ay-font-medium ay-text-lg">
                            <span
                              className={`ay-transition-transform ay-duration-200 ${
                                expandedCategories[cat] ? "ay-rotate-90" : ""
                              }`}
                            >
                              {expandedCategories[cat] ? (
                                <FiChevronDown />
                              ) : (
                                <FiChevronRight />
                              )}
                            </span>
                            <span>{cat}</span>
                          </div>

                          <div className="ay-flex ay-gap-2 ay-items-center">
                            <div
                              {...provided.dragHandleProps}
                              className="ay-cursor-move ay-mr-2"
                            >
                              <FiMove className="ay-text-gray-500 ay-hover:ay-text-gray-700" />
                            </div>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddFamily(cat);
                              }}
                              className="ay-p-2 ay-bg-blue-500 ay-text-white ay-rounded ay-text-sm ay-hover:ay-bg-blue-600 ay-flex ay-items-center ay-gap-1"
                              title="Add Family"
                            >
                              <FiPlus />
                              <span className="ay-hidden md:ay-inline">
                                Add Family
                              </span>
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditCategory(cat);
                              }}
                              className="ay-p-2 ay-bg-yellow-500 ay-text-white ay-rounded ay-text-sm ay-hover:ay-bg-yellow-600"
                              title="Edit Category"
                            >
                              <FiEdit />
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleConfirmDeleteCategory(cat);
                              }}
                              className="ay-p-2 ay-bg-red-500 ay-text-white ay-rounded ay-text-sm ay-hover:ay-bg-red-600"
                              title="Delete Category"
                            >
                              <FiTrash2 />
                            </button>
                          </div>
                        </div>

                        {/* Families */}
                        {expandedCategories[cat] && (
                          <Droppable
                            droppableId={`category-${cat}`}
                            type="FAMILY"
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="ay-pl-8 ay-py-2"
                              >
                                {productStructure[cat].length === 0 ? (
                                  <div className="ay-text-center ay-py-4 ay-text-gray-500">
                                    <p>No families in this category.</p>
                                    <button
                                      onClick={() => handleAddFamily(cat)}
                                      className="ay-mt-2 ay-text-blue-500 ay-underline ay-flex ay-items-center ay-gap-1 ay-justify-center"
                                    >
                                      <FiPlus /> Add your first family
                                    </button>
                                  </div>
                                ) : (
                                  productStructure[cat].map((fam, famIndex) => (
                                    <Draggable
                                      key={`${cat}-${fam}`}
                                      draggableId={`family-${cat}-${fam}`}
                                      index={famIndex}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className={`ay-border ay-rounded-lg ay-overflow-hidden ay-mb-3 ${
                                            snapshot.isDragging
                                              ? "ay-opacity-70 ay-shadow-lg"
                                              : ""
                                          }`}
                                        >
                                          {/* Family header */}
                                          <div
                                            className="ay-bg-gray-50 ay-p-3 ay-flex ay-justify-between ay-items-center ay-cursor-pointer"
                                            onClick={() =>
                                              toggleFamily(cat, fam)
                                            }
                                          >
                                            <div className="ay-flex ay-items-center ay-gap-2 ay-font-medium">
                                              <span
                                                className={`ay-transition-transform ay-duration-200 ${
                                                  expandedFamilies[
                                                    `${cat}-${fam}`
                                                  ]
                                                    ? "ay-rotate-90"
                                                    : ""
                                                }`}
                                              >
                                                {expandedFamilies[
                                                  `${cat}-${fam}`
                                                ] ? (
                                                  <FiChevronDown />
                                                ) : (
                                                  <FiChevronRight />
                                                )}
                                              </span>
                                              <span>{fam}</span>
                                              <span className="ay-ml-2 ay-text-xs ay-bg-blue-100 ay-text-blue-800 ay-rounded-full ay-px-2 ay-py-0.5">
                                                {
                                                  getProductsForFamily(cat, fam)
                                                    .length
                                                }{" "}
                                                products
                                              </span>
                                            </div>

                                            <div className="ay-flex ay-gap-2 ay-items-center">
                                              <div
                                                {...provided.dragHandleProps}
                                                className="ay-cursor-move ay-mr-2"
                                              >
                                                <FiMove className="ay-text-gray-500 ay-hover:ay-text-gray-700" />
                                              </div>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleOpenProductsManager(
                                                    cat,
                                                    fam
                                                  );
                                                }}
                                                className="ay-p-2 ay-bg-indigo-500 ay-text-white ay-rounded ay-text-sm ay-hover:ay-bg-indigo-600"
                                                title="Manage Products"
                                              >
                                                <FiGrid />
                                              </button>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleAddProduct(cat, fam);
                                                }}
                                                className="ay-p-2 ay-bg-green-500 ay-text-white ay-rounded ay-text-sm ay-hover:ay-bg-green-600"
                                                title="Add Product"
                                              >
                                                <FiPlus />
                                              </button>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleEditFamily(cat, fam);
                                                }}
                                                className="ay-p-2 ay-bg-yellow-500 ay-text-white ay-rounded ay-text-sm ay-hover:ay-bg-yellow-600"
                                                title="Edit Family"
                                              >
                                                <FiEdit />
                                              </button>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleConfirmDeleteFamily(
                                                    cat,
                                                    fam
                                                  );
                                                }}
                                                className="ay-p-2 ay-bg-red-500 ay-text-white ay-rounded ay-text-sm ay-hover:ay-bg-red-600"
                                                title="Delete Family"
                                              >
                                                <FiTrash2 />
                                              </button>
                                            </div>
                                          </div>

                                          {/* Products */}
                                          {expandedFamilies[
                                            `${cat}-${fam}`
                                          ] && (
                                            <Droppable
                                              droppableId={`family-${encodeURIComponent(
                                                cat
                                              )}-${encodeURIComponent(fam)}`}
                                              type="PRODUCT"
                                            >
                                              {(provided) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                  className="ay-p-3"
                                                >
                                                  <div className="ay-overflow-x-auto">
                                                    <table className="ay-min-w-full ay-divide-y ay-divide-gray-200">
                                                      <thead className="ay-bg-gray-50">
                                                        <tr>
                                                          <th className="ay-w-10 ay-px-2"></th>
                                                          <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                                                            Name
                                                          </th>
                                                          <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                                                            Code
                                                          </th>
                                                          <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                                                            Slug
                                                          </th>
                                                          <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                                                            Project Code
                                                          </th>
                                                          <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                                                            Price
                                                          </th>
                                                          <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                                                            Tag
                                                          </th>
                                                          <th className="ay-px-6 ay-py-3 ay-text-left ay-text-xs ay-font-medium ay-text-gray-500 ay-uppercase ay-tracking-wider">
                                                            Actions
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody className="ay-bg-white ay-divide-y ay-divide-gray-200">
                                                        {getProductsForFamily(
                                                          cat,
                                                          fam
                                                        ).length === 0 ? (
                                                          <tr>
                                                            <td
                                                              colSpan="8"
                                                              className="ay-px-6 ay-py-4 ay-text-center ay-text-gray-500"
                                                            >
                                                              No products in
                                                              this family.
                                                            </td>
                                                          </tr>
                                                        ) : (
                                                          getProductsForFamily(
                                                            cat,
                                                            fam
                                                          ).map(
                                                            (
                                                              product,
                                                              prodIndex
                                                            ) => (
                                                              <Draggable
                                                                key={
                                                                  product.code
                                                                }
                                                                draggableId={`product-${product.code}`}
                                                                index={
                                                                  prodIndex
                                                                }
                                                              >
                                                                {(
                                                                  provided,
                                                                  snapshot
                                                                ) => (
                                                                  <tr
                                                                    ref={
                                                                      provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    className={
                                                                      snapshot.isDragging
                                                                        ? "ay-bg-gray-50"
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <td className="ay-w-10 ay-px-2 ay-py-4 ay-whitespace-nowrap">
                                                                      <div
                                                                        {...provided.dragHandleProps}
                                                                        className="ay-cursor-move"
                                                                      >
                                                                        <FiMove className="ay-text-gray-400 ay-hover:ay-text-gray-600" />
                                                                      </div>
                                                                    </td>
                                                                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                                                      <div className="ay-text-sm ay-font-medium ay-text-gray-900">
                                                                        {
                                                                          product.name
                                                                        }
                                                                      </div>
                                                                    </td>
                                                                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                                                      <div className="ay-flex ay-items-center">
                                                                        <FiCode className="ay-mr-1 ay-text-gray-500" />
                                                                        <span>
                                                                          {
                                                                            product.code
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                                                      <code className="ay-text-xs ay-bg-gray-100 ay-px-2 ay-py-1 ay-rounded">
                                                                        {product.slug ||
                                                                          "-"}
                                                                      </code>
                                                                    </td>
                                                                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                                                      <div className="ay-flex ay-items-center">
                                                                        <FiPackage className="ay-mr-1 ay-text-gray-500" />
                                                                        <span>
                                                                          {product.projectCode ||
                                                                            "-"}
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                                                      <div className="ay-flex ay-items-center">
                                                                        <FiDollarSign className="ay-mr-1 ay-text-gray-500" />
                                                                        <span>
                                                                          {product.price
                                                                            ? `€${product.price.toLocaleString()}`
                                                                            : "-"}
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap">
                                                                      {product.tag ? (
                                                                        <div className="ay-flex ay-items-center">
                                                                          <FiTag className="ay-mr-1 ay-text-blue-500" />
                                                                          <span className="ay-px-2 ay-py-1 ay-text-xs ay-bg-blue-100 ay-text-blue-800 ay-rounded">
                                                                            {
                                                                              product.tag
                                                                            }
                                                                          </span>
                                                                        </div>
                                                                      ) : (
                                                                        "-"
                                                                      )}
                                                                    </td>
                                                                    <td className="ay-px-6 ay-py-4 ay-whitespace-nowrap ay-space-x-1">
                                                                      <button
                                                                        onClick={() =>
                                                                          onProductChange(
                                                                            product.code
                                                                          )
                                                                        }
                                                                        className="ay-p-1.5 ay-bg-blue-500 ay-text-white ay-rounded ay-text-xs ay-hover:ay-bg-blue-600"
                                                                        title="View Product"
                                                                      >
                                                                        <FiEye />
                                                                      </button>
                                                                      <button
                                                                        onClick={() =>
                                                                          handleEditProduct(
                                                                            product
                                                                          )
                                                                        }
                                                                        className="ay-p-1.5 ay-bg-yellow-500 ay-text-white ay-rounded ay-text-xs ay-hover:ay-bg-yellow-600"
                                                                        title="Edit Product"
                                                                      >
                                                                        <FiEdit />
                                                                      </button>
                                                                      <button
                                                                        onClick={() =>
                                                                          handleConfirmDeleteProduct(
                                                                            product
                                                                          )
                                                                        }
                                                                        className="ay-p-1.5 ay-bg-red-500 ay-text-white ay-rounded ay-text-xs ay-hover:ay-bg-red-600"
                                                                        title="Delete Product"
                                                                      >
                                                                        <FiTrash2 />
                                                                      </button>
                                                                    </td>
                                                                  </tr>
                                                                )}
                                                              </Draggable>
                                                            )
                                                          )
                                                        )}
                                                        {provided.placeholder}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  {getProductsForFamily(
                                                    cat,
                                                    fam
                                                  ).length === 0 && (
                                                    <div className="ay-text-center ay-mt-2">
                                                      <button
                                                        onClick={() =>
                                                          handleAddProduct(
                                                            cat,
                                                            fam
                                                          )
                                                        }
                                                        className="ay-text-blue-500 ay-underline ay-flex ay-items-center ay-gap-1 ay-justify-center"
                                                      >
                                                        <FiPlus /> Add your
                                                        first product
                                                      </button>
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </Droppable>
                                          )}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ProductsTab;
