// ContactModal.js
import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";

const ContactModal = ({
  isOpen,
  onClose,
  selectedProduct,
  selectedOptionalMaterials,
  selectedMaterials = {},
  viewerMaterials = [],
}) => {
  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    email: "",
    telefono: "",
    azienda: "",
    messaggio: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  // Chiudi il modal se non ci sono materiali opzionali selezionati
  useEffect(() => {
    if (
      isOpen &&
      (!selectedOptionalMaterials || selectedOptionalMaterials.length === 0)
    ) {
      onClose();
    }
  }, [isOpen, selectedOptionalMaterials, onClose]);

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError("");

    try {
      // In un'implementazione reale, invieresti i dati del form al server
      // Per ora, simuliamo un invio riuscito dopo un breve ritardo
      await new Promise((resolve) => setTimeout(resolve, 1500));

      setSubmitSuccess(true);

      // Resetta il form dopo il successo
      setTimeout(() => {
        setSubmitSuccess(false);
        onClose();
        setFormData({
          nome: "",
          cognome: "",
          email: "",
          telefono: "",
          azienda: "",
          messaggio: "",
        });
      }, 2000);
    } catch (error) {
      setSubmitError("Impossibile inviare il modulo. Si prega di riprovare.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Calcola il sovrapprezzo
  const calculateSurcharge = () => {
    if (!selectedProduct || !selectedProduct.price) return 0;
    return Math.round(selectedProduct.price * 0.15);
  };

  // Calcola il prezzo totale
  const calculateTotalPrice = () => {
    if (!selectedProduct || !selectedProduct.price) return 0;
    const basePrice = selectedProduct.price;
    return basePrice + calculateSurcharge();
  };

  // Raggruppa i materiali per tipo di accordione, inclusi quelli di default
  const getMaterialsByAccordion = () => {
    // Raccogliamo tutti i materiali dagli accordion, non solo quelli selezionati manualmente
    // Questo ci permette di mostrare anche i materiali opzionali di default
    if (!viewerMaterials && !selectedMaterials) return {};

    const groups = {};

    // Prima aggiungiamo i materiali opzionali selezionati
    if (selectedOptionalMaterials) {
      selectedOptionalMaterials.forEach((item) => {
        const typeLabel = getTypeLabel(item.type);

        if (!groups[typeLabel]) {
          groups[typeLabel] = [];
        }

        groups[typeLabel].push(item);
      });
    }

    // Poi aggiungiamo i materiali selezionati che sono opzionali ma non nella lista selectedOptionalMaterials
    // Questi sono i materiali opzionali di default che non sono stati esplicitamente cambiati dall'utente
    if (selectedMaterials && viewerMaterials) {
      Object.entries(selectedMaterials).forEach(([type, material]) => {
        // Se il materiale è opzionale ma non è già nella lista
        if (
          material.optional &&
          !selectedOptionalMaterials.some((m) => m.type === type)
        ) {
          const typeLabel = getTypeLabel(type);

          if (!groups[typeLabel]) {
            groups[typeLabel] = [];
          }

          // Aggiungiamo il materiale se non è già presente nel gruppo
          if (
            !groups[typeLabel].some(
              (item) =>
                item.type === type && item.material.name === material.name
            )
          ) {
            groups[typeLabel].push({
              type: type,
              material: material,
            });
          }
        }
      });
    }

    return groups;
  };

  // Ottieni l'etichetta per il tipo di materiale
  const getTypeLabel = (type) => {
    if (!type) return "Altro";

    // Cerca l'etichetta nei prodotti
    if (selectedProduct?.materials?.[type]?.label) {
      return selectedProduct.materials[type].label;
    }

    // Cerca nelle impostazioni predefinite della famiglia
    if (selectedProduct?._familyDefaults?.defaultMaterials?.[type]?.label) {
      return selectedProduct._familyDefaults.defaultMaterials[type].label;
    }

    // Formatta il nome tecnico in modo più leggibile
    return (
      type.charAt(0).toUpperCase() + type.slice(1).replace(/([A-Z])/g, " $1")
    );
  };

  const materialsByAccordion = getMaterialsByAccordion();

  return (
    <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
      <div className="ay-bg-white ay-rounded-lg ay-shadow-xl ay-w-full ay-max-w-2xl ay-max-h-[90vh] ay-overflow-auto">
        <div className="ay-flex ay-justify-between ay-items-center ay-px-6 ay-py-4 ay-border-b ay-border-gray-200">
          <h2 className="ay-text-xl ay-font-bold ay-text-gray-800">
            Richiesta Preventivo Materiali Opzionali
          </h2>
          <button
            onClick={onClose}
            className="ay-text-gray-500 ay-hover:ay-text-gray-700 ay-transition-colors ay-focus:ay-outline-none"
          >
            <FaTimes />
          </button>
        </div>

        {submitSuccess ? (
          <div className="ay-text-center ay-py-10 ay-px-6">
            <div className="ay-bg-green-100 ay-text-green-700 ay-rounded-full ay-w-16 ay-h-16 ay-flex ay-items-center ay-justify-center ay-mx-auto ay-mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ay-h-8 ay-w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h3 className="ay-text-xl ay-font-medium ay-mb-2">
              Richiesta Inviata!
            </h3>
            <p className="ay-text-gray-600">
              Grazie per la tua richiesta. Ti contatteremo al più presto per un
              preventivo personalizzato.
            </p>
          </div>
        ) : (
          <>
            <div className="ay-p-6">
              {/* Sezione prezzo */}
              <div className="ay-mb-6 ay-bg-gray-50 ay-rounded-lg ay-border ay-border-gray-200 ay-overflow-hidden">
                <div className="ay-p-4 ay-bg-blue-50 ay-border-b ay-border-blue-100">
                  <div className="ay-flex ay-justify-between ay-items-center">
                    <h3 className="ay-font-bold ay-text-gray-800">
                      Preventivo Stimato
                    </h3>
                    <div className="ay-text-right">
                      <p className="ay-text-xl ay-font-bold ay-text-blue-600">
                        €{calculateTotalPrice().toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="ay-p-4">
                  <div className="ay-flex ay-justify-between ay-items-center ay-text-sm ay-mb-2">
                    <span className="ay-text-gray-600">Prezzo Base:</span>
                    <span className="ay-font-medium">
                      €{selectedProduct?.price.toLocaleString()}
                    </span>
                  </div>
                  <div className="ay-flex ay-justify-between ay-items-center ay-text-sm ay-mb-3">
                    <span className="ay-text-gray-600">
                      Sovrapprezzo Materiali Opzionali (15%):
                    </span>
                    <span className="ay-font-medium ay-text-red-600">
                      €{calculateSurcharge().toLocaleString()}
                    </span>
                  </div>

                  <div className="ay-mt-3 ay-p-3 ay-bg-yellow-50 ay-rounded ay-text-sm ay-border ay-border-yellow-200">
                    <p className="ay-text-center ay-font-medium ay-text-yellow-800">
                      Il sovrapprezzo per Colori RAL OPZIONALI è fisso al 15%
                      del prezzo base, indipendentemente dalla quantità di
                      materiali opzionali selezionati.
                    </p>
                  </div>
                </div>
              </div>

              {/* Informazioni prodotto */}
              <div className="ay-mb-6">
                <h3 className="ay-font-bold ay-text-gray-800 ay-mb-3">
                  Dettagli Prodotto
                </h3>
                <div className="ay-p-4 ay-bg-white ay-rounded-lg ay-border ay-border-gray-200">
                  <div className="ay-grid ay-grid-cols-2 ay-gap-4">
                    <div>
                      <p className="ay-text-sm">
                        <span className="ay-text-gray-600 ay-font-medium">
                          Prodotto:
                        </span>{" "}
                        {selectedProduct?.name}
                      </p>
                    </div>
                    <div>
                      <p className="ay-text-sm">
                        <span className="ay-text-gray-600 ay-font-medium">
                          Codice:
                        </span>{" "}
                        {selectedProduct?.code}
                      </p>
                    </div>
                    {selectedProduct?.family && (
                      <div>
                        <p className="ay-text-sm">
                          <span className="ay-text-gray-600 ay-font-medium">
                            Famiglia:
                          </span>{" "}
                          {selectedProduct?.family}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Configurazione selezionata - Raggruppata per accordion */}
              <div className="ay-mb-6">
                <h3 className="ay-font-bold ay-text-gray-800 ay-mb-3">
                  Materiali Selezionati
                </h3>

                <div className="ay-bg-white ay-rounded-lg ay-border ay-border-gray-200 ay-overflow-hidden">
                  {Object.entries(materialsByAccordion).map(
                    ([accordionName, materials], accordionIndex) => (
                      <div
                        key={accordionName}
                        className="ay-border-b ay-border-gray-200 ay-last:ay-border-b-0"
                      >
                        <div className="ay-bg-gray-50 ay-p-3 ay-border-b ay-border-gray-200">
                          <h4 className="ay-font-medium ay-text-gray-800">
                            {accordionName}
                          </h4>
                        </div>
                        <div className="ay-divide-y ay-divide-gray-100">
                          {materials.map((item, index) => (
                            <div
                              key={`${item.type}-${index}`}
                              className="ay-p-3 ay-flex ay-items-center ay-gap-3"
                            >
                              {item.material.thumbnail &&
                              item.material.thumbnail.startsWith("#") ? (
                                <div
                                  className="ay-w-8 ay-h-8 ay-rounded-full ay-border ay-border-gray-300 ay-flex-shrink-0"
                                  style={{
                                    backgroundColor: item.material.thumbnail,
                                  }}
                                ></div>
                              ) : (
                                <div className="ay-w-8 ay-h-8 ay-rounded-full ay-bg-gray-200 ay-flex-shrink-0"></div>
                              )}
                              <div className="ay-flex-1">
                                <p className="ay-font-medium">
                                  {item.material.name}
                                </p>
                                <p className="ay-text-xs ay-text-gray-500">
                                  {item.material.collection || ""}
                                </p>
                              </div>
                              <span className="ay-text-xs ay-bg-yellow-100 ay-text-yellow-800 ay-px-2 ay-py-1 ay-rounded-full">
                                Opzionale
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>

              {/* Form di contatto */}
              <form onSubmit={handleSubmit}>
                <h3 className="ay-font-bold ay-text-gray-800 ay-mb-3">
                  I Tuoi Dati
                </h3>

                <div className="ay-grid ay-grid-cols-1 ay-gap-4 md:ay-grid-cols-2">
                  <div>
                    <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                      Nome *
                    </label>
                    <input
                      type="text"
                      name="nome"
                      value={formData.nome}
                      onChange={handleChange}
                      className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                      Cognome *
                    </label>
                    <input
                      type="text"
                      name="cognome"
                      value={formData.cognome}
                      onChange={handleChange}
                      className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                      Telefono
                    </label>
                    <input
                      type="tel"
                      name="telefono"
                      value={formData.telefono}
                      onChange={handleChange}
                      className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                    />
                  </div>

                  <div>
                    <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                      Azienda
                    </label>
                    <input
                      type="text"
                      name="azienda"
                      value={formData.azienda}
                      onChange={handleChange}
                      className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                    />
                  </div>

                  <div className="md:ay-col-span-2">
                    <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                      Note Aggiuntive
                    </label>
                    <textarea
                      name="messaggio"
                      value={formData.messaggio}
                      onChange={handleChange}
                      rows="3"
                      className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
                      placeholder="Specifiche aggiuntive, quantità, tempistiche di consegna..."
                    ></textarea>
                  </div>
                </div>

                {submitError && (
                  <div className="ay-mt-4 ay-p-3 ay-bg-red-50 ay-text-red-700 ay-rounded-md ay-text-sm">
                    {submitError}
                  </div>
                )}
              </form>
            </div>

            {/* Footer con pulsanti di azione */}
            <div className="ay-px-6 ay-py-4 ay-bg-gray-50 ay-border-t ay-border-gray-200 ay-flex ay-justify-end ay-gap-3">
              <button
                type="button"
                onClick={onClose}
                className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50 ay-disabled:ay-opacity-50 ay-transition-colors ay-focus:ay-outline-none"
                disabled={isSubmitting}
              >
                Annulla
              </button>
              <button
                onClick={handleSubmit}
                className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-bg-blue-600 ay-text-white ay-hover:ay-bg-blue-700 ay-disabled:ay-opacity-50 ay-flex ay-items-center ay-gap-2 ay-transition-colors ay-focus:ay-outline-none"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <svg
                      className="ay-animate-spin ay-h-4 ay-w-4 ay-text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="ay-opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="ay-opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Invio in corso...
                  </>
                ) : (
                  "Richiedi Preventivo"
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactModal;
