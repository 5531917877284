// config.js
// Fix the import - change materialMapping to materialManager
import { materialManager } from "./materialManager";

// You can remove this import if you're not using local materials anymore
// import materiali from "./materials";

// You can remove this line if you're not using local materials
// export const material = materiali;

export const defaultRivestimento = {
  slug: "",
  name: "",
  thumbnail: "",
};

export const defaultStruttura = {
  slug: "",
  name: "",
  thumbnail: "",
};

export const defaultFinitura = {
  slug: "",
  name: "",
  thumbnail: "",
};

// Configurazione globale (se ti serve)
export let configuration = {
  rivestimento: defaultRivestimento.slug,
};

// switchConfiguration => applica una configurazione
// "configurationName" = oggetto con {slug, name, thumbnail}
// "type" = "struttura", "imbottito", ecc.
export function switchConfiguration(configurationName, type) {
  console.log("=== SWITCH CONFIGURATION ===");
  console.log("Material obj:", {
    collection: configurationName.collection || "",
    name: configurationName.name,
    optional: configurationName.optional || false,
    slug: configurationName.slug,
    standard: configurationName.standard || false,
    thumbnail: configurationName.thumbnail,
    viewerName: configurationName.viewerName,
  });
  console.log("Type:", type);

  // For safety, use the getTechnicalName if you want
  const technicalName = type;

  // Check if the emersyaIframe exists before trying to access it
  if (window.emViewers && window.emViewers["emersyaIframe"]) {
    // Make a specific call for this type and material
    window.emViewers["emersyaIframe"].setMaterials({
      materials: [
        {
          materialVariation:
            configurationName.viewerName || configurationName.name, // Use viewerName if available, name as backup
          configurableMaterial: technicalName,
        },
      ],
    });
    console.log("Configuration Applied");
  } else {
    console.error("emersyaIframe not found");
  }

  console.log("=== END SWITCH CONFIGURATION ===");
}
