// components/admin/DashboardTab.js
import React, { useState, useEffect } from "react";
import {
  fetchProductStructure,
  fetchMaterials,
  fetchMaterialLinks,
  fetchTechnicalTypes,
} from "../../api";

const DashboardTab = ({ products, categories, materialLinks, setLoading }) => {
  const [productStructure, setProductStructure] = useState({});
  const [materialsData, setMaterialsData] = useState({ materials: [] });
  const [technicalTypes, setTechnicalTypes] = useState([]);
  const [stats, setStats] = useState({
    products: 0,
    categories: 0,
    families: 0,
    materials: 0,
    technicalTypes: 0,
    materialLinks: 0,
  });

  // Load initial data
  useEffect(() => {
    loadDashboardData();
  }, []);

  // Calculate stats whenever data changes
  useEffect(() => {
    calculateStats();
  }, [
    products,
    categories,
    materialLinks,
    productStructure,
    materialsData,
    technicalTypes,
  ]);

  // Load all dashboard data
  const loadDashboardData = async () => {
    setLoading(true);
    try {
      // Load product structure
      const structure = await fetchProductStructure();
      setProductStructure(structure);

      // Load materials
      const materials = await fetchMaterials();
      setMaterialsData(materials);

      // Load technical types
      const types = await fetchTechnicalTypes();
      setTechnicalTypes(types);
    } catch (error) {
      console.error("Failed to load dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Calculate system statistics
  const calculateStats = () => {
    // Count families across all categories
    let familyCount = 0;
    Object.keys(productStructure).forEach((category) => {
      familyCount += productStructure[category]?.length || 0;
    });

    setStats({
      products: products.length,
      categories: Object.keys(productStructure).length,
      families: familyCount,
      materials: materialsData.materials?.length || 0,
      technicalTypes: technicalTypes.length,
      materialLinks: materialLinks.length,
    });
  };

  // Get unique material types from materials
  const getMaterialTypes = () => {
    const types = new Set();
    materialsData.materials?.forEach((material) => {
      if (material.type) {
        types.add(material.type);
      }
    });
    return Array.from(types);
  };

  // Get material count by technical type
  const getMaterialCountByTechnicalType = () => {
    const counts = {};
    technicalTypes.forEach((type) => {
      counts[type] = 0;
    });

    materialsData.materials?.forEach((material) => {
      material.technicalTypes?.forEach((type) => {
        if (counts[type] !== undefined) {
          counts[type]++;
        }
      });
    });

    return counts;
  };

  // Get material count by type
  const getMaterialCountByType = () => {
    const counts = {};
    getMaterialTypes().forEach((type) => {
      counts[type] = 0;
    });

    materialsData.materials?.forEach((material) => {
      if (material.type && counts[material.type] !== undefined) {
        counts[material.type]++;
      }
    });

    return counts;
  };

  // Get product count by family
  const getProductCountByFamily = () => {
    const counts = {};

    products.forEach((product) => {
      if (!counts[product.family]) {
        counts[product.family] = 0;
      }
      counts[product.family]++;
    });

    return counts;
  };

  return (
    <div>
      <h2 className="ay-text-2xl ay-font-bold ay-mb-6">Dashboard</h2>

      {/* Overview Cards */}
      <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-3 ay-gap-4 ay-mb-8">
        <div className="ay-bg-blue-50 ay-rounded-lg ay-shadow ay-p-6">
          <h3 className="ay-text-lg ay-font-medium ay-text-blue-800 ay-mb-2">
            Products
          </h3>
          <div className="ay-flex ay-justify-between ay-items-end">
            <div>
              <p className="ay-text-4xl ay-font-bold ay-text-blue-900">
                {stats.products}
              </p>
              <p className="ay-text-sm ay-text-blue-600">Total Products</p>
            </div>
            <div className="ay-text-right">
              <p className="ay-text-lg ay-font-semibold ay-text-blue-800">
                {stats.categories}
              </p>
              <p className="ay-text-sm ay-text-blue-600">Categories</p>
              <p className="ay-text-lg ay-font-semibold ay-text-blue-800">
                {stats.families}
              </p>
              <p className="ay-text-sm ay-text-blue-600">Families</p>
            </div>
          </div>
        </div>

        <div className="ay-bg-green-50 ay-rounded-lg ay-shadow ay-p-6">
          <h3 className="ay-text-lg ay-font-medium ay-text-green-800 ay-mb-2">
            Materials
          </h3>
          <div className="ay-flex ay-justify-between ay-items-end">
            <div>
              <p className="ay-text-4xl ay-font-bold ay-text-green-900">
                {stats.materials}
              </p>
              <p className="ay-text-sm ay-text-green-600">Total Materials</p>
            </div>
            <div className="ay-text-right">
              <p className="ay-text-lg ay-font-semibold ay-text-green-800">
                {getMaterialTypes().length}
              </p>
              <p className="ay-text-sm ay-text-green-600">Material Types</p>
              <p className="ay-text-lg ay-font-semibold ay-text-green-800">
                {stats.technicalTypes}
              </p>
              <p className="ay-text-sm ay-text-green-600">Technical Types</p>
            </div>
          </div>
        </div>

        <div className="ay-bg-purple-50 ay-rounded-lg ay-shadow ay-p-6">
          <h3 className="ay-text-lg ay-font-medium ay-text-purple-800 ay-mb-2">
            Material Links
          </h3>
          <div className="ay-flex ay-justify-between ay-items-end">
            <div>
              <p className="ay-text-4xl ay-font-bold ay-text-purple-900">
                {stats.materialLinks}
              </p>
              <p className="ay-text-sm ay-text-purple-600">
                Total Material Links
              </p>
            </div>
            <div className="ay-text-right">
              <p className="ay-text-lg ay-font-semibold ay-text-purple-800">
                {materialLinks.reduce(
                  (total, link) =>
                    total + Object.keys(link.materialPairs || {}).length,
                  0
                )}
              </p>
              <p className="ay-text-sm ay-text-purple-600">Material Pairs</p>
            </div>
          </div>
        </div>
      </div>

      {/* Recent Items and Analytics */}
      <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-2 ay-gap-6">
        {/* Product Categories */}
        <div className="ay-bg-white ay-rounded-lg ay-shadow ay-p-4">
          <h3 className="ay-text-lg ay-font-medium ay-mb-3">
            Product Categories
          </h3>

          {Object.keys(productStructure).length > 0 ? (
            <div className="ay-space-y-3">
              {Object.keys(productStructure).map((category) => (
                <div
                  key={category}
                  className="ay-border ay-border-gray-200 ay-rounded ay-p-3"
                >
                  <div className="ay-flex ay-justify-between ay-items-center">
                    <h4 className="ay-font-medium">{category}</h4>
                    <span className="ay-bg-blue-100 ay-text-blue-800 ay-text-xs ay-rounded ay-px-2 ay-py-1">
                      {productStructure[category]?.length || 0} Families
                    </span>
                  </div>

                  {productStructure[category]?.length > 0 && (
                    <div className="ay-mt-2 ay-pl-4 ay-border-l ay-border-gray-200">
                      <ul className="ay-space-y-1">
                        {productStructure[category]
                          .slice(0, 3)
                          .map((family) => (
                            <li
                              key={family}
                              className="ay-text-sm ay-flex ay-justify-between"
                            >
                              <span>{family}</span>
                              <span className="ay-text-gray-500">
                                {
                                  products.filter((p) => p.family === family)
                                    .length
                                }{" "}
                                Products
                              </span>
                            </li>
                          ))}
                        {productStructure[category].length > 3 && (
                          <li className="ay-text-sm ay-text-gray-500">
                            +{productStructure[category].length - 3} more
                            families
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className="ay-text-center ay-py-4 ay-text-gray-500">
              No categories found.
            </p>
          )}
        </div>

        {/* Technical Types and Materials */}
        <div className="ay-bg-white ay-rounded-lg ay-shadow ay-p-4">
          <h3 className="ay-text-lg ay-font-medium ay-mb-3">Technical Types</h3>

          {technicalTypes.length > 0 ? (
            <div className="ay-space-y-3">
              {technicalTypes.map((type) => {
                const materialCount =
                  getMaterialCountByTechnicalType()[type] || 0;
                const percentage =
                  stats.materials > 0
                    ? Math.round((materialCount / stats.materials) * 100)
                    : 0;

                return (
                  <div
                    key={type}
                    className="ay-border ay-border-gray-200 ay-rounded ay-p-3"
                  >
                    <div className="ay-flex ay-justify-between ay-items-center ay-mb-1">
                      <h4 className="ay-font-medium">{type}</h4>
                      <span className="ay-bg-green-100 ay-text-green-800 ay-text-xs ay-rounded ay-px-2 ay-py-1">
                        {materialCount} Materials
                      </span>
                    </div>

                    <div className="ay-w-full ay-bg-gray-200 ay-rounded-full ay-h-2.5">
                      <div
                        className="ay-bg-green-600 ay-h-2.5 ay-rounded-full"
                        style={{ width: `${percentage}%` }}
                      ></div>
                    </div>
                    <div className="ay-text-xs ay-text-right ay-mt-1 ay-text-gray-500">
                      {percentage}% of all materials
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="ay-text-center ay-py-4 ay-text-gray-500">
              No technical types found.
            </p>
          )}
        </div>
      </div>

      {/* System Status */}
      <div className="ay-mt-6 ay-bg-white ay-rounded-lg ay-shadow ay-p-4">
        <h3 className="ay-text-lg ay-font-medium ay-mb-3">System Status</h3>

        <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-2 ay-gap-4">
          <div>
            <h4 className="ay-font-medium ay-text-sm ay-mb-2">
              Data Structure
            </h4>
            <ul className="ay-space-y-1 ay-text-sm">
              <li className="ay-flex ay-justify-between ay-border-b ay-pb-1">
                <span>Categories:</span>
                <span className="ay-font-medium">{stats.categories}</span>
              </li>
              <li className="ay-flex ay-justify-between ay-border-b ay-pb-1">
                <span>Families:</span>
                <span className="ay-font-medium">{stats.families}</span>
              </li>
              <li className="ay-flex ay-justify-between ay-border-b ay-pb-1">
                <span>Products:</span>
                <span className="ay-font-medium">{stats.products}</span>
              </li>
              <li className="ay-flex ay-justify-between ay-border-b ay-pb-1">
                <span>Material Types:</span>
                <span className="ay-font-medium">
                  {getMaterialTypes().length}
                </span>
              </li>
              <li className="ay-flex ay-justify-between ay-border-b ay-pb-1">
                <span>Technical Types:</span>
                <span className="ay-font-medium">{stats.technicalTypes}</span>
              </li>
              <li className="ay-flex ay-justify-between ay-border-b ay-pb-1">
                <span>Materials:</span>
                <span className="ay-font-medium">{stats.materials}</span>
              </li>
              <li className="ay-flex ay-justify-between">
                <span>Material Links:</span>
                <span className="ay-font-medium">{stats.materialLinks}</span>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="ay-font-medium ay-text-sm ay-mb-2">
              Database Status
            </h4>
            <div className="ay-flex ay-items-center ay-gap-2 ay-mb-3">
              <div className="ay-w-3 ay-h-3 ay-rounded-full ay-bg-green-500"></div>
              <span className="ay-text-sm">
                Database is online and functioning normally
              </span>
            </div>

            <h4 className="ay-font-medium ay-text-sm ay-mb-2">Last Updates</h4>
            <ul className="ay-space-y-1 ay-text-sm">
              <li className="ay-flex ay-justify-between ay-border-b ay-pb-1">
                <span>Products:</span>
                <span className="ay-font-medium">Just now</span>
              </li>
              <li className="ay-flex ay-justify-between ay-border-b ay-pb-1">
                <span>Materials:</span>
                <span className="ay-font-medium">Just now</span>
              </li>
              <li className="ay-flex ay-justify-between">
                <span>Material Links:</span>
                <span className="ay-font-medium">Just now</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTab;
