// components/admin/ConfirmationModal.js
import React from "react";
import { FiAlertTriangle, FiX, FiCheck } from "react-icons/fi";

const ConfirmationModal = ({ title, message, onConfirm, onCancel }) => {
  return (
    <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
      <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-md ay-mx-4">
        <div className="ay-flex ay-items-center ay-gap-3 ay-mb-4">
          <div className="ay-flex-shrink-0 ay-bg-red-100 ay-rounded-full ay-p-2">
            <FiAlertTriangle className="ay-text-red-600 ay-text-xl" />
          </div>
          <h2 className="ay-text-xl ay-font-bold ay-text-gray-800">{title}</h2>
        </div>

        <div className="ay-text-gray-600 ay-mb-6 ay-border-l-4 ay-border-red-300 ay-pl-4 ay-py-2">
          {message}
        </div>

        <div className="ay-flex ay-justify-end ay-gap-3 ay-mt-6">
          <button
            onClick={onCancel}
            className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50 ay-flex ay-items-center ay-gap-2"
          >
            <FiX /> Cancel
          </button>
          <button
            onClick={onConfirm}
            className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-shadow-sm ay-bg-red-500 ay-text-white ay-hover:ay-bg-red-600 ay-flex ay-items-center ay-gap-2"
          >
            <FiCheck /> Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
