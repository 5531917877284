// MaterialLinksIntegration.js - Component to integrate material links into family/product forms
import React, { useState, useEffect } from "react";
import { fetchMaterialLinks } from "../../api";

export const MaterialLinksSelector = ({
  selectedLinks = [],
  onSelectionChange,
  title = "Material Links",
}) => {
  const [materialLinks, setMaterialLinks] = useState([]);
  const [loading, setLoading] = useState(false);

  // Load material links on component mount
  useEffect(() => {
    loadMaterialLinks();
  }, []);

  // Load all material links
  const loadMaterialLinks = async () => {
    setLoading(true);
    try {
      const links = await fetchMaterialLinks();
      setMaterialLinks(links);
    } catch (error) {
      console.error("Failed to load material links:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle link selection
  const handleLinkToggle = (linkId) => {
    if (onSelectionChange) {
      if (selectedLinks.includes(linkId)) {
        onSelectionChange(selectedLinks.filter((id) => id !== linkId));
      } else {
        onSelectionChange([...selectedLinks, linkId]);
      }
    }
  };

  return (
    <div className="ay-bg-gray-50 ay-p-4 ay-rounded-lg">
      <h3 className="ay-text-lg ay-font-medium ay-mb-2">{title}</h3>
      <p className="ay-text-sm ay-text-gray-500 ay-mb-4">
        Material links define relationships between different technical types.
        When a material is selected for one type, linked materials will be
        automatically applied to other types.
      </p>

      {loading ? (
        <div className="ay-flex ay-justify-center ay-py-4">
          <div className="ay-animate-spin ay-w-8 ay-h-8 ay-border-4 ay-border-blue-500 ay-border-t-transparent ay-rounded-full"></div>
        </div>
      ) : (
        <div className="ay-grid ay-grid-cols-1 md:ay-grid-cols-2 lg:ay-grid-cols-3 ay-gap-4">
          {materialLinks.map((link) => (
            <div
              key={link.id}
              className={`ay-border ay-rounded-lg ay-p-4 ay-cursor-pointer ay-transition-colors ${
                selectedLinks.includes(link.id)
                  ? "ay-border-blue-500 ay-bg-blue-50"
                  : "ay-border-gray-300 ay-hover:ay-bg-gray-50"
              }`}
              onClick={() => handleLinkToggle(link.id)}
            >
              <div className="ay-flex ay-items-center ay-mb-2">
                <input
                  type="checkbox"
                  checked={selectedLinks.includes(link.id)}
                  onChange={() => {}}
                  className="ay-h-4 ay-w-4 ay-text-blue-600 ay-rounded"
                />
                <span className="ay-ml-2 ay-font-medium">{link.name}</span>
              </div>

              <div className="ay-flex ay-flex-wrap ay-gap-1 ay-mb-2">
                {link.linkedTechnicalNames?.map((type) => (
                  <span
                    key={type}
                    className="ay-px-2 ay-py-1 ay-text-xs ay-bg-blue-100 ay-text-blue-800 ay-rounded"
                  >
                    {type}
                  </span>
                ))}
              </div>

              <div className="ay-text-xs ay-text-gray-500">
                {Object.keys(link.materialPairs || {}).length} material pairs
                defined
              </div>
            </div>
          ))}

          {materialLinks.length === 0 && (
            <div className="ay-col-span-full ay-text-center ay-py-4 ay-text-gray-500">
              No material links available.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Context-specific mini link creator component
export const QuickMaterialLinkCreator = ({
  onLinkCreated,
  availableTechnicalTypes = [],
}) => {
  const [formData, setFormData] = useState({
    name: "",
    linkedTechnicalNames: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTechnicalTypeToggle = (type) => {
    setFormData((prev) => {
      const current = [...prev.linkedTechnicalNames];
      const index = current.indexOf(type);

      if (index === -1) {
        current.push(type);
      } else {
        current.splice(index, 1);
      }

      return {
        ...prev,
        linkedTechnicalNames: current,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || formData.linkedTechnicalNames.length < 2) {
      alert("Please provide a name and select at least two technical types");
      return;
    }

    // Create a basic link with empty material pairs
    const newLink = {
      ...formData,
      materialPairs: {},
    };

    onLinkCreated(newLink);

    // Reset form
    setFormData({
      name: "",
      linkedTechnicalNames: [],
    });
  };

  return (
    <div className="ay-border ay-border-gray-300 ay-rounded-lg ay-p-4 ay-bg-white">
      <h4 className="ay-text-md ay-font-medium ay-mb-3">Quick Create Link</h4>

      <form onSubmit={handleSubmit}>
        <div className="ay-mb-4">
          <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
            Link Name *
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
            placeholder="E.g., Structure-Shelves Link"
            required
          />
        </div>

        <div className="ay-mb-4">
          <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
            Technical Types to Link *
          </label>
          <div className="ay-flex ay-flex-wrap ay-gap-2 ay-border ay-border-gray-300 ay-rounded-md ay-p-3">
            {availableTechnicalTypes.map((type) => (
              <label
                key={type}
                className={`ay-flex ay-items-center ay-gap-1 ay-px-2 ay-py-1 ay-rounded ay-cursor-pointer ay-text-sm ${
                  formData.linkedTechnicalNames.includes(type)
                    ? "ay-bg-blue-100 ay-text-blue-800"
                    : "ay-bg-gray-100 ay-text-gray-700"
                }`}
              >
                <input
                  type="checkbox"
                  checked={formData.linkedTechnicalNames.includes(type)}
                  onChange={() => handleTechnicalTypeToggle(type)}
                  className="ay-h-3 ay-w-3 ay-text-blue-600 ay-border-gray-300 ay-rounded"
                />
                {type}
              </label>
            ))}
          </div>
          {formData.linkedTechnicalNames.length < 2 && (
            <p className="ay-text-xs ay-text-red-500 ay-mt-1">
              Please select at least two technical types.
            </p>
          )}
        </div>

        <button
          type="submit"
          className="ay-w-full ay-px-4 ay-py-2 ay-bg-blue-500 ay-text-white ay-rounded ay-hover:ay-bg-blue-600"
          disabled={!formData.name || formData.linkedTechnicalNames.length < 2}
        >
          Create Link
        </button>
      </form>
    </div>
  );
};

export default MaterialLinksSelector;
