// Accordion.js
import React, { useState, useEffect, useMemo, useRef } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { materialManager } from "./materialManager";

export const Accordion = ({
  title,
  variations,
  selected,
  onChange,
  standardMaterials = [],
  optionalMaterials = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  // Debug log - only on initial render, not on every update
  useEffect(() => {
    console.log(
      `Accordion initialized: "${title}" with ${variations.length} materials`
    );
  }, [title, variations.length]);

  // Update content height when isOpen changes or content changes
  useEffect(() => {
    if (isOpen && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [isOpen, variations, selected]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (material) => {
    onChange(material);
  };

  // Helper function for normalization - define outside of useMemo
  const normalize = (str) =>
    String(str || "")
      .toLowerCase()
      .replace(/[\s-]+/g, "");

  // Define isStandard and isOptional functions at component level
  const isStandard = (material) => {
    // First check if the material itself is marked as standard
    if (material?.standard === true) {
      return true;
    }

    const normalizedName = normalize(material?.name || "");
    const normalizedSlug = normalize(material?.slug || "");

    return standardMaterials.some((stdMat) => {
      const normalizedStdMat = normalize(stdMat);
      return (
        normalizedName === normalizedStdMat ||
        normalizedSlug === normalizedStdMat ||
        material?.name === stdMat ||
        material?.slug === stdMat
      );
    });
  };

  const isOptional = (material) => {
    // First check if the material itself is marked as optional
    if (material?.optional === true) {
      return true;
    }

    const normalizedName = normalize(material?.name || "");
    const normalizedSlug = normalize(material?.slug || "");

    return optionalMaterials.some((optMat) => {
      const normalizedOptMat = normalize(optMat);
      return (
        normalizedName === normalizedOptMat ||
        normalizedSlug === normalizedOptMat ||
        material?.name === optMat ||
        material?.slug === optMat
      );
    });
  };

  // Material classification logic memoized to run only when dependencies change
  const materialData = useMemo(() => {
    // Group materials - using the isStandard function defined above
    const standardVariations = variations.filter((m) => isStandard(m));
    const optionalVariations = variations.filter((m) => !isStandard(m));

    // Get collections
    const getUniqueCollections = (materials) => {
      const collections = new Set();
      materials.forEach((material) => {
        if (material.collection) {
          collections.add(material.collection);
        }
      });
      return Array.from(collections).sort();
    };

    // Group by collection
    const groupByCollection = (materials) => {
      const groups = {};
      materials.forEach((material) => {
        const collection = material.collection || "Default";
        if (!groups[collection]) {
          groups[collection] = [];
        }
        groups[collection].push(material);
      });
      return groups;
    };

    const standardCollections = getUniqueCollections(standardVariations);
    const optionalCollections = getUniqueCollections(optionalVariations);
    const standardByCollection = groupByCollection(standardVariations);
    const optionalByCollection = groupByCollection(optionalVariations);

    return {
      standardVariations,
      optionalVariations,
      standardCollections,
      optionalCollections,
      standardByCollection,
      optionalByCollection,
    };
  }, [variations, standardMaterials, optionalMaterials, isStandard]);

  // Extract all the values from our memoized object
  const {
    standardVariations,
    optionalVariations,
    standardCollections,
    optionalCollections,
    standardByCollection,
    optionalByCollection,
  } = materialData;

  // Log material variations counts after we have access to them
  useEffect(() => {
    console.log(
      `Accordion ${title}: Standard variations:`,
      standardVariations.length
    );
    console.log(
      `Accordion ${title}: Optional variations:`,
      optionalVariations.length
    );
  }, [title, standardVariations.length, optionalVariations.length]);

  // Determine if selected material is optional
  const isSelectedOptional = selected ? !isStandard(selected) : false;

  return (
    <div className="ay-w-full ay-py-2 ay-border-b ay-border-black/80">
      <div
        className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-cursor-pointer"
        onClick={handleToggle}
      >
        {/* Header section with aligned title and icon */}
        <div className="ay-flex-grow">
          <div className="ay-flex ay-items-center ay-justify-between">
            <h2 className="ay-text-lg ay-font-serif ay-capitalize">{title}</h2>
            <div className="ay-flex ay-items-center ay-justify-center ay-w-6 ay-h-6">
              <span
                className={`ay-transition-transform ay-text-sm ay-duration-300 ay-ease-in-out ${
                  isOpen ? "ay-rotate-45" : "ay-rotate-0"
                }`}
              >
                <AiOutlinePlus />
              </span>
            </div>
          </div>

          {selected && (
            <div className="ay-flex ay-items-center ay-gap-2 ay-mt-1">
              <div className="ay-w-8 ay-h-8 ay-overflow-hidden ay-border ay-border-black/80">
                {selected.thumbnail && selected.thumbnail.startsWith("#") ? (
                  <div
                    className="ay-w-full ay-h-full"
                    style={{ backgroundColor: selected.thumbnail }}
                  />
                ) : (
                  <img
                    src={selected.thumbnail || "#"}
                    alt={selected.name}
                    className="ay-w-full ay-h-full ay-object-cover"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100" height="100" fill="%23cccccc"/><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="14" fill="%23ffffff">No Image</text></svg>';
                    }}
                  />
                )}
              </div>
              <h5 className="ay-font-medium ay-text-base ay-text-black/40 ay-truncate">
                {selected.name}
                {isStandard(selected)
                  ? " (standard)"
                  : isOptional(selected)
                  ? " (optional)"
                  : ""}
              </h5>
            </div>
          )}
          {!selected && (
            <h5 className="ay-font-medium ay-text-base ay-text-black/40 ay-mt-1 ay-truncate">
              {`Seleziona ${title.toLowerCase()}`}
            </h5>
          )}
        </div>
      </div>

      {/* Accordion Content with animation */}
      <div
        ref={contentRef}
        className="ay-overflow-hidden ay-transition-all ay-duration-300 ay-ease-in-out"
        style={{ maxHeight: `${contentHeight}px`, opacity: isOpen ? 1 : 0 }}
      >
        <div
          className="ay-mt-4 ay-transform ay-transition-transform ay-duration-300 ay-ease-in-out"
          style={{ transform: isOpen ? "translateY(0)" : "translateY(-10px)" }}
        >
          {/* Standard Materials Section with subtle distinction */}
          {standardVariations.length > 0 && (
            <div className="ay-mb-6 ay-ml-1 ay-border-l ay-border-black/50 ay-px-2">
              <h3 className="ay-text-sm ay-font-medium ay-text-black/30">
                Materiali standart
              </h3>

              {/* Group by Collection */}
              {standardCollections.map((collection) => (
                <div key={collection} className="ay-mb-4">
                  <h4 className="ay-text-lg ay-mt-1 ay-font-serif ay-capitalize ay-font-bold ay-mb-2">
                    {collection}
                  </h4>
                  <div className="ay-grid ay-grid-cols-4 ay-gap-1">
                    {standardByCollection[collection].map((material) => (
                      <div
                        key={material.slug || material.name}
                        className="ay-cursor-pointer ay-text-center ay-group hover:ay-scale-105 ay-transition-transform"
                        onClick={() => handleSelect(material)}
                        tabIndex="0"
                      >
                        <div
                          className={`ay-w-full ay-aspect-square ay-overflow-hidden ay-border focus-visible:ay-ring-2 focus-visible:ay-ring-black ${
                            selected?.slug === material.slug ||
                            selected?.name === material.name
                              ? "ay-border-2 ay-border-black"
                              : "ay-border ay-border-black/60"
                          }`}
                          tabIndex="-1"
                        >
                          {material.thumbnail &&
                          material.thumbnail.startsWith("#") ? (
                            <div
                              className="ay-w-full ay-h-full"
                              style={{ backgroundColor: material.thumbnail }}
                            />
                          ) : (
                            <img
                              src={material.thumbnail || "#"}
                              alt={material.name}
                              className="ay-w-full ay-h-full ay-object-cover ay-scale-125 group-hover:ay-scale-250 ay-transition-transform ay-duration-500 ay-ease-in-out"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100" height="100" fill="%23cccccc"/><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="14" fill="%23ffffff">No Image</text></svg>';
                              }}
                            />
                          )}
                        </div>
                        <span
                          className={`ay-text-xs ay-mt-1 ${
                            selected?.slug === material.slug ||
                            selected?.name === material.name
                              ? "ay-font-bold"
                              : ""
                          }`}
                        >
                          {material.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Optional Materials Section with subtle distinction */}
          {optionalVariations.length > 0 && (
            <div className="ay-mb-6 ay-border-l ay-ml-1 ay-border-black/50 ay-px-2">
              <h3 className="ay-text-sm ay-font-medium ay-text-black/30">
                Materiali extra*
              </h3>

              {/* Group by Collection */}
              {optionalCollections.map((collection) => (
                <div key={collection} className="ay-mb-4">
                  <h4 className="ay-text-lg ay-mt-1 ay-font-serif ay-capitalize ay-font-bold ay-mb-2">
                    {collection}
                  </h4>
                  <div className="ay-grid ay-grid-cols-4 ay-gap-1">
                    {optionalByCollection[collection].map((material) => (
                      <div
                        key={material.slug || material.name}
                        className="ay-cursor-pointer ay-text-center ay-group hover:ay-scale-105 ay-transition-transform"
                        onClick={() => handleSelect(material)}
                        tabIndex="0"
                      >
                        <div
                          className={`ay-w-full ay-aspect-square ay-overflow-hidden ay-border focus-visible:ay-ring-2 focus-visible:ay-ring-black ${
                            selected?.slug === material.slug ||
                            selected?.name === material.name
                              ? "ay-border-2 ay-border-black"
                              : "ay-border ay-border-black/60"
                          }`}
                          tabIndex="-1"
                        >
                          {material.thumbnail &&
                          material.thumbnail.startsWith("#") ? (
                            <div
                              className="ay-w-full ay-h-full"
                              style={{ backgroundColor: material.thumbnail }}
                            />
                          ) : (
                            <img
                              src={material.thumbnail || "#"}
                              alt={material.name}
                              className="ay-w-full ay-h-full ay-object-cover ay-scale-125 group-hover:ay-scale-250 ay-transition-transform ay-duration-500 ay-ease-in-out"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100" height="100" fill="%23cccccc"/><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="14" fill="%23ffffff">No Image</text></svg>';
                              }}
                            />
                          )}
                        </div>
                        <span
                          className={`ay-text-xs ay-mt-1 ${
                            selected?.slug === material.slug ||
                            selected?.name === material.name
                              ? "ay-font-bold"
                              : ""
                          }`}
                        >
                          {material.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
