// materialManager.js
import { fetchMaterials } from "./api";

class MaterialManager {
  constructor() {
    this.configurableMaterials = []; // Materials from the 3D viewer
    this.allMaterials = []; // All materials from our database (global list)
    this.materialsByName = {}; // Materials indexed by name for quick lookup
    this.materialsBySlug = {}; // Materials indexed by slug for quick lookup
    this.familySettings = {}; // Family-specific material settings
    this.productSettings = {}; // Product-specific material settings (overrides family)
    this.initialized = false;
    this.currentFamily = null; // Added to store the current family
    this.currentProduct = null; // Added to store the current product
  }

  // Initialize all materials - fetch from the server
  async initializeAllMaterials() {
    this.allMaterials = [];
    this.materialsByName = {};
    this.materialsBySlug = {};

    try {
      // Fetch materials from the server
      const materialsData = await fetchMaterials();
      const serverMaterials = materialsData.materials || [];

      // Process all materials from the server
      serverMaterials.forEach((material) => {
        // Add the material to our collections
        this.allMaterials.push(material);
        this.materialsByName[material.name] = material;
        this.materialsBySlug[material.slug] = material;

        // Process variations if they exist
        if (material.variations && Array.isArray(material.variations)) {
          material.variations.forEach((variation) => {
            const varMaterial = {
              name: variation.name,
              slug: variation.slug || this.generateSlug(variation.name),
              thumbnail: variation.thumbnail || "",
              collection: material.collection || "Default",
              type: material.type || "Default",
            };

            this.allMaterials.push(varMaterial);
            this.materialsByName[variation.name] = varMaterial;
            this.materialsBySlug[varMaterial.slug] = varMaterial;
          });
        }
      });

      console.log(
        `Initialized ${this.allMaterials.length} materials from the server`
      );
    } catch (error) {
      console.error("Failed to fetch materials from server:", error);
      console.log("Using empty materials array as fallback");
    }

    return this.allMaterials;
  }
  // Initialize with viewer materials and family/product settings
  initialize(viewerMaterials = [], familyName = null, productCode = null) {
    console.log("Initializing material manager with:", {
      viewerMaterialsCount: viewerMaterials.length,
      familyName,
      productCode,
    });

    // Reset configurable materials
    this.configurableMaterials = [];

    // First, make sure we have all materials initialized
    if (this.allMaterials.length === 0) {
      this.initializeAllMaterials();
    }

    // Store current family and product for use in getAvailableMaterials
    this.currentFamily = familyName;
    this.currentProduct = productCode;

    // Process configurable materials from the viewer
    viewerMaterials.forEach((mat) => {
      // Process material variations
      const enrichedVariations = mat.materialVariations.map((variation) => {
        // Try to find this material in our global list
        const matchingMaterial = this.materialsByName[variation.name] || {
          name: variation.name,
          slug: this.generateSlug(variation.name),
          thumbnail: variation.color || "#808080",
        };

        return {
          name: variation.name,
          slug: matchingMaterial.slug || this.generateSlug(variation.name),
          thumbnail: matchingMaterial.thumbnail || variation.color || "#808080",
          viewerName: variation.name, // Keep original name for viewer reference
          standard: false, // Will be set by getAvailableMaterials based on family settings
          optional: false, // Will be set by getAvailableMaterials based on family settings
          collection: matchingMaterial.collection || "Default",
        };
      });

      // Add to configurable materials
      this.configurableMaterials.push({
        technicalName: mat.name,
        variations: enrichedVariations,
      });
    });

    this.initialized = true;
    console.log(
      "Material manager initialized with",
      this.configurableMaterials.length,
      "configurable materials for family:",
      familyName
    );
    return this.configurableMaterials;
  }
  // Get available materials for configuration with display labels and standard/optional flags
  getAvailableMaterials(familyName = null, productCode = null) {
    if (!this.initialized) {
      console.warn(
        "MaterialManager not initialized before getAvailableMaterials call"
      );
      return [];
    }

    // Use passed parameters or fall back to stored values from initialize
    const actualFamily = familyName || this.currentFamily;
    const actualProduct = productCode || this.currentProduct;

    console.log(
      "Getting available materials for family:",
      actualFamily,
      "product:",
      actualProduct
    );
    console.log("Family settings stored:", this.familySettings);

    // Get the correct settings from family or product
    let settings = null;

    // First try product settings (they override family settings)
    if (actualProduct && this.productSettings[actualProduct]) {
      settings = this.productSettings[actualProduct];
      console.log(`Using product-specific settings for ${actualProduct}`);
    }
    // Then try exact match for family settings
    else if (actualFamily && this.familySettings[actualFamily]) {
      settings = this.familySettings[actualFamily];
      console.log(`Using family settings for ${actualFamily}`);
    }
    // Try case-insensitive match for family name
    else if (actualFamily) {
      const familyLowerCase = actualFamily.toLowerCase();
      for (const storedFamily in this.familySettings) {
        if (storedFamily.toLowerCase() === familyLowerCase) {
          settings = this.familySettings[storedFamily];
          console.log(
            `Using family settings for ${storedFamily} (case-insensitive match for ${actualFamily})`
          );

          // Important: Update the reference to use the correct casing
          this.familySettings[actualFamily] = settings;

          // Only delete the old reference if it's different from the new one
          if (storedFamily !== actualFamily) {
            delete this.familySettings[storedFamily];
            console.log(
              `Removed duplicate family settings for ${storedFamily}`
            );
          }

          break;
        }
      }
    }

    // If still no settings found, use a default empty structure
    if (!settings) {
      console.warn(
        `No settings found for family: ${actualFamily}, product: ${actualProduct}`
      );
      settings = { types: {}, typeLinks: [] };
    }

    console.log("Using material settings:", settings.types);

    // Process each configurable material
    const result = this.configurableMaterials.map((material) => {
      const technicalName = material.technicalName;

      // IMPORTANT: Find type settings in a case-insensitive way
      let typeSettings = null;
      for (const typeKey in settings.types) {
        if (typeKey.toLowerCase() === technicalName.toLowerCase()) {
          typeSettings = settings.types[typeKey];
          console.log(
            `Found type settings for ${technicalName} under key ${typeKey}`
          );
          break;
        }
      }

      // If no settings for this type, just add display label
      if (!typeSettings) {
        console.log(`No type settings found for ${technicalName}`);
        return {
          ...material,
          displayLabel: this.capitalizeFirstLetter(technicalName),
        };
      }

      // Set display label from settings or default
      const displayLabel =
        typeSettings.label || this.capitalizeFirstLetter(technicalName);

      // Deep debug logging
      console.log(
        `Processing ${technicalName} with ${material.variations.length} variations`
      );
      console.log(`Standard materials:`, typeSettings.standard || []);
      console.log(`Optional materials:`, typeSettings.optional || []);

      // Process each variation to determine if it's standard or optional
      const variations = material.variations.map((variation) => {
        const name = variation.name || "";
        const slug = variation.slug || this.generateSlug(name);

        // Helper function to normalize text for comparison
        const normalize = (text) =>
          String(text || "")
            .toLowerCase()
            .replace(/[\s-]+/g, "");

        // Normalize the variation name and slug
        const normalizedName = normalize(name);
        const normalizedSlug = normalize(slug);

        // Check if this is a standard material
        let isStandard = false;
        if (Array.isArray(typeSettings.standard)) {
          // Try to match against each standard material
          for (const stdMat of typeSettings.standard) {
            const normalizedStdMat = normalize(stdMat);
            if (
              normalizedName === normalizedStdMat ||
              normalizedSlug === normalizedStdMat ||
              name === stdMat ||
              slug === stdMat
            ) {
              console.log(`Found standard match for ${name} (${stdMat})`);
              isStandard = true;
              break;
            }
          }
        }

        // Check if this is an optional material
        let isOptional = false;
        if (Array.isArray(typeSettings.optional)) {
          // Try to match against each optional material
          for (const optMat of typeSettings.optional) {
            const normalizedOptMat = normalize(optMat);
            if (
              normalizedName === normalizedOptMat ||
              normalizedSlug === normalizedOptMat ||
              name === optMat ||
              slug === optMat
            ) {
              console.log(`Found optional match for ${name} (${optMat})`);
              isOptional = true;
              break;
            }
          }
        }

        // If it's not standard, mark it as optional
        if (!isStandard) {
          isOptional = true;
        }

        // Return the variation with standard/optional flags
        return {
          ...variation,
          standard: isStandard,
          optional: isOptional,
        };
      });

      // Log results
      const standardCount = variations.filter((v) => v.standard).length;
      const optionalCount = variations.filter((v) => v.optional).length;
      console.log(
        `${technicalName} classifications: ${standardCount} standard, ${optionalCount} optional`
      );

      // Return the updated material with variations
      return {
        ...material,
        displayLabel,
        variations,
      };
    });

    return result;
  }

  // Helper method to capitalize first letter
  capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // Set family-specific material settings
  setFamilySettings(familyName, settings) {
    if (!familyName) {
      console.error(
        "Cannot set family settings with null or empty family name"
      );
      return;
    }

    console.log(`Setting family settings for ${familyName}:`, settings);

    // IMPORTANT: Check if we're overwriting with empty/incomplete settings
    const existingSettings = this.familySettings[familyName];
    if (existingSettings) {
      // If we already have settings for this family and the new settings
      // have empty typeLinks or types, use the existing ones
      const newSettings = {
        types: settings.types || {},
        typeLinks: settings.typeLinks || [],
      };

      // Don't override with empty typeLinks
      if (
        newSettings.typeLinks.length === 0 &&
        existingSettings.typeLinks &&
        existingSettings.typeLinks.length > 0
      ) {
        console.log(`Preserving existing typeLinks for ${familyName}`);
        newSettings.typeLinks = existingSettings.typeLinks;
      }

      // Don't override with empty types
      if (
        Object.keys(newSettings.types).length === 0 &&
        existingSettings.types &&
        Object.keys(existingSettings.types).length > 0
      ) {
        console.log(`Preserving existing types for ${familyName}`);
        newSettings.types = existingSettings.types;
      }

      // Always store with the exact case provided
      this.familySettings[familyName] = newSettings;
    } else {
      // No existing settings, just store the new ones
      this.familySettings[familyName] = {
        types: settings.types || {},
        typeLinks: settings.typeLinks || [],
      };
    }

    console.log(
      `Family settings stored for ${familyName}:`,
      this.familySettings[familyName]
    );

    // Clean up any old versions of this family that might exist with different casing
    const familyLowerCase = familyName.toLowerCase();
    for (const storedFamily in this.familySettings) {
      if (
        storedFamily !== familyName &&
        storedFamily.toLowerCase() === familyLowerCase
      ) {
        console.log(
          `Removing duplicate family settings with different case: "${storedFamily}"`
        );
        delete this.familySettings[storedFamily];
      }
    }
  }

  // Set product-specific material settings
  setProductSettings(productCode, settings) {
    console.log(`Setting product settings for ${productCode}:`, settings);

    // Create a structured settings object
    this.productSettings[productCode] = {
      types: settings.types || {},
      typeLinks: settings.typeLinks || [],
    };
  }

  // Set product-specific material settings
  setProductSettings(productCode, settings) {
    console.log(`Setting product settings for ${productCode}:`, {
      types: Object.keys(settings.types || {}),
    });
    this.productSettings[productCode] = settings;
  }

  // Find material by name
  findMaterialByName(name) {
    if (!name) return null;

    name = String(name).trim();
    const normalizedName = name
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/-/g, "");

    // First try exact match
    if (this.materialsByName[name]) {
      return this.materialsByName[name];
    }

    // Then try normalized match
    for (const key in this.materialsByName) {
      const normalizedKey = key
        .toLowerCase()
        .replace(/\s+/g, "")
        .replace(/-/g, "");
      if (normalizedKey === normalizedName) {
        return this.materialsByName[key];
      }
    }

    return null;
  }

  // Find material by slug
  findMaterialBySlug(slug) {
    if (!slug) return null;

    slug = String(slug).trim();
    const normalizedSlug = slug
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/-/g, "");

    // First try exact match
    if (this.materialsBySlug[slug]) {
      return this.materialsBySlug[slug];
    }

    // Then try normalized match
    for (const key in this.materialsBySlug) {
      const normalizedKey = key
        .toLowerCase()
        .replace(/\s+/g, "")
        .replace(/-/g, "");
      if (normalizedKey === normalizedSlug) {
        return this.materialsBySlug[key];
      }
    }

    return null;
  }

  // Find materials by technical name (for viewer)
  findMaterialsByTechnicalName(technicalName) {
    const materials = this.configurableMaterials.find(
      (m) => m.technicalName === technicalName
    );
    return materials ? materials.variations : [];
  }

  // Get all technical types
  getTechnicalTypes() {
    return this.configurableMaterials.map((m) => m.technicalName);
  }

  // Get materials for a technical type
  getMaterialsForTechnicalType(technicalType) {
    const materials = this.configurableMaterials.find(
      (m) => m.technicalName === technicalType
    );
    return materials ? materials.variations : [];
  }

  // Generate a slug from a name
  generateSlug(name) {
    if (!name) return "";
    return name
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .trim();
  }

  // Convert material name to slug using the database
  nameToSlug(name) {
    const material = this.findMaterialByName(name);
    return material ? material.slug : this.generateSlug(name);
  }

  // Process material type links to apply connected materials
  processMaterialTypeLinks(sourceType, sourceMaterial, selectedMaterials) {
    const updatedMaterials = { ...selectedMaterials };

    // First check product-specific type links
    let productLinks = [];
    const productCode = Object.keys(this.productSettings)[0]; // Example, better to pass this directly

    if (productCode && this.productSettings[productCode]?.typeLinks) {
      productLinks = this.productSettings[productCode].typeLinks;
    }

    // Then check family-specific type links
    let familyLinks = [];
    const familyName = Object.keys(this.familySettings)[0]; // Example, better to pass this directly

    if (familyName && this.familySettings[familyName]?.typeLinks) {
      familyLinks = this.familySettings[familyName].typeLinks;
    }

    // Combine links with product links taking precedence
    const allLinks = [...familyLinks, ...productLinks];

    // Process each link
    allLinks.forEach((link) => {
      // If this link includes our source type
      if (link.linkedTechnicalNames.includes(sourceType)) {
        // For each linked technical type
        link.linkedTechnicalNames.forEach((targetType) => {
          // Skip if it's the source type itself
          if (targetType === sourceType) return;

          // Find materials for this target type
          const targetMaterials = this.getMaterialsForTechnicalType(targetType);

          // If we have the same material name in both types, apply it
          const matchingMaterial = targetMaterials.find(
            (m) =>
              m.name === sourceMaterial.name || m.slug === sourceMaterial.slug
          );

          if (matchingMaterial) {
            // IMPORTANT: Preserve the standard/optional flags from the source material
            updatedMaterials[targetType] = {
              ...matchingMaterial,
              standard: sourceMaterial.standard,
              optional: sourceMaterial.optional,
            };
          }
        });
      }
    });

    return updatedMaterials;
  }
}

// Export singleton
export const materialManager = new MaterialManager();
