// App.js
import React, { useEffect, useState, useRef, useCallback } from "react";
import Player from "./Player";
import { Accordion } from "./Accordion";
import FamilySelector from "./FamilySelector";
import FamilyAccordion from "./FamilyAccordion";
import { FaChevronRight } from "react-icons/fa";
import logo from "./assets/logo-dieffebi.svg";

// Import material manager
import { materialManager } from "./materialManager";

// Import configuration functions
import { switchConfiguration } from "./config";

// Import API functions
import { fetchProductByCode, fetchProducts, fetchMaterialLinks } from "./api";

// Import Admin Panel
import AdminPanel from "./AdminPanel";
import ContactModal from "./ContactModal";

export default function App({ productId, isAdmin = false }) {
  // Loading state
  const [loading, setLoading] = useState(true);

  // Product state
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedProductCode, setSelectedProductCode] = useState(productId);

  // Material state
  const [selectedMaterials, setSelectedMaterials] = useState({});
  const [viewerMaterials, setViewerMaterials] = useState([]);
  const [materialLinks, setMaterialLinks] = useState([]);

  // UI state
  const [isAccordionHidden, setIsAccordionHidden] = useState(false);
  const [priceModification, setPriceModification] = useState(0);
  const [showOptionalMaterialNotice, setShowOptionalMaterialNotice] =
    useState(false);
  const [configurableMaterialOnClick, setConfigurableMaterialOnClick] =
    useState(null);
  const [selectedOptionalMaterials, setSelectedOptionalMaterials] = useState(
    []
  );
  const [showContactModal, setShowContactModal] = useState(false);

  // Refs to keep track of selections
  const lastSelectedFamilyRef = useRef(null);
  const currentProductCodeRef = useRef(productId);
  const viewerLoadingRef = useRef(false);

  // Fetch initial data on mount
  useEffect(() => {
    const initializeApp = async () => {
      setLoading(true);
      try {
        await loadProducts();
        await loadMaterialLinks();
        materialManager.initializeAllMaterials();
      } catch (error) {
        console.error("Failed to initialize app:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeApp();

    // Add event listener for contact modal
    window.addEventListener("openContactModal", () =>
      setShowContactModal(true)
    );

    return () => {
      window.removeEventListener("openContactModal", () =>
        setShowContactModal(true)
      );
    };
  }, []);

  // Keep lastSelectedFamilyRef updated with selectedFamily
  useEffect(() => {
    if (selectedFamily) {
      lastSelectedFamilyRef.current = selectedFamily;
    }
  }, [selectedFamily]);

  // Initialize Emersya and load product data
  useEffect(() => {
    // Set up the initial family value in the ref
    if (selectedFamily) {
      lastSelectedFamilyRef.current = selectedFamily;
    }

    // Add Emersya initialization event listener
    document.addEventListener(
      "emersyaViewerInitialized",
      initializeEmersyaAPI,
      false
    );

    // Load product data if we have a product ID
    if (selectedProductCode) {
      loadProductData();
    }

    // Cleanup
    return () => {
      document.removeEventListener(
        "emersyaViewerInitialized",
        initializeEmersyaAPI
      );
    };
  }, []);

  // Reload product data when selected product changes
  useEffect(() => {
    if (selectedProductCode) {
      // Update our ref to track the current product code
      currentProductCodeRef.current = selectedProductCode;

      // Clear selected materials and optional materials when product changes
      setSelectedMaterials({});
      setSelectedOptionalMaterials([]);
      setPriceModification(0);
      setShowOptionalMaterialNotice(false);

      // Reset viewer loading flag when changing products
      viewerLoadingRef.current = false;

      loadProductData();
    }
  }, [selectedProductCode]);

  // Load all products
  const loadProducts = async () => {
    try {
      const productsData = await fetchProducts();
      setProducts(productsData);
      return productsData;
    } catch (error) {
      console.error("Failed to fetch products:", error);
      return [];
    }
  };

  // Load all material links
  const loadMaterialLinks = async () => {
    try {
      const links = await fetchMaterialLinks();
      setMaterialLinks(links);
      return links;
    } catch (error) {
      console.error("Failed to load material links:", error);
      return [];
    }
  };

  // Toggle accordion visibility
  const toggleAccordionVisibility = () => {
    setIsAccordionHidden((prevState) => !prevState);
    setTimeout(() => {
      if (window.emViewers && window.emViewers["emersyaIframe"]) {
        window.emViewers["emersyaIframe"].resize();
      }
    }, 150);
  };

  // Load product data
  const loadProductData = async () => {
    setLoading(true);
    try {
      const productData = await fetchProductByCode(selectedProductCode);

      // Set the product data
      setSelectedProduct(productData);

      const familyName = productData._family;

      // Update family if needed
      if (familyName) {
        setSelectedFamily(familyName);
        // Update the ref as well
        lastSelectedFamilyRef.current = familyName;
      }

      console.log("Loaded product data:", productData);

      // Extract family defaults
      let familyDefaults = productData._familyDefaults?.defaultMaterials || {};
      let familyTypeLinks = productData._familyDefaults?.typeLinks || [];

      // Ensure each material type has both standard and optional arrays for compatibility
      Object.keys(familyDefaults).forEach((type) => {
        if (!familyDefaults[type].standard) familyDefaults[type].standard = [];
        if (!familyDefaults[type].optional) familyDefaults[type].optional = [];
      });

      // Store family material settings in material manager
      if (familyName) {
        // Pass both types and typeLinks
        materialManager.setFamilySettings(familyName, {
          types: familyDefaults,
          typeLinks: familyTypeLinks,
        });
      }

      // If product has custom material settings, ensure they have standard and optional arrays
      if (
        productData.materials &&
        Object.keys(productData.materials).length > 0
      ) {
        const productMaterials = { ...productData.materials };

        Object.keys(productMaterials).forEach((type) => {
          if (!productMaterials[type].standard)
            productMaterials[type].standard = [];
          if (!productMaterials[type].optional)
            productMaterials[type].optional = [];
        });

        materialManager.setProductSettings(productData.code, {
          types: productMaterials,
          typeLinks: productData.typeLinks || [],
        });
      }
    } catch (error) {
      console.error("Failed to load product:", error);
    } finally {
      setLoading(false);
    }
  };

  // Initialize Emersya API
  const initializeEmersyaAPI = () => {
    const emersyaViewer = window.emViewers["emersyaIframe"];

    // Add configurableMaterial highlight listener
    emersyaViewer.addEventListener(
      "onConfigurableMaterialHighlight",
      function (event) {
        if (event[0]) {
          setConfigurableMaterialOnClick(event[0]);
        } else {
          setConfigurableMaterialOnClick(null);
        }
      }
    );

    // Add stateChange listener to initialize materials when viewer is ready
    emersyaViewer.addEventListener("onStateChange", async (data) => {
      if (data.viewerState === "loaded") {
        // Prevent duplicate executions for the same product
        if (viewerLoadingRef.current) {
          return;
        }

        // Set loading flag
        viewerLoadingRef.current = true;

        try {
          // IMPORTANT: Use the current product code from the ref, not the state
          // This ensures we're always using the most recently selected product
          const currentCode = currentProductCodeRef.current;

          // Load fresh product data directly using the current code
          // This avoids any stale references in the state
          const productData = await fetchProductByCode(currentCode);

          // Update the selectedProduct state with this fresh data
          setSelectedProduct(productData);

          // Also ensure the selectedProductCode state is synchronized
          if (currentCode !== selectedProductCode) {
            setSelectedProductCode(currentCode);
          }

          // Get the family from the product
          const productFamily = productData._family;

          // CRITICAL: First, make sure we have the correct family settings
          // Step 1: Always store the product's native family settings under its own name
          const productFamilyDefaults =
            productData._familyDefaults?.defaultMaterials || {};
          const productFamilyTypeLinks =
            productData._familyDefaults?.typeLinks || [];

          // Always save product's own family settings
          if (productFamily) {
            materialManager.setFamilySettings(productFamily, {
              types: productFamilyDefaults,
              typeLinks: productFamilyTypeLinks,
            });
          }

          // Step 2: Determine which family we should actually use (UI-selected or product's family)
          const actualFamily = lastSelectedFamilyRef.current || productFamily;

          if (
            lastSelectedFamilyRef.current &&
            lastSelectedFamilyRef.current !== productFamily
          ) {
            console.log(
              `Using UI-selected family: ${lastSelectedFamilyRef.current} instead of product family: ${productFamily}`
            );
          } else {
            // Update UI state only if no explicit selection or it matches product family
            if (actualFamily !== selectedFamily) {
              console.log(`Updating family to match product: ${productFamily}`);
              setSelectedFamily(productFamily);
              lastSelectedFamilyRef.current = productFamily;
            }
          }

          console.log(
            "Emersya viewer loaded, using family:",
            actualFamily,
            "with product:",
            currentCode
          );

          // Step 3: Find the correct family settings for the selected family (if different from product family)
          let actualFamilyDefaults = {};
          let actualFamilyTypeLinks = [];

          if (actualFamily !== productFamily) {
            // We need to find settings for the selected family from other products
            try {
              // Try to get the settings by searching through all other products
              const familyProducts = products.filter(
                (p) =>
                  (p._family === actualFamily || p.family === actualFamily) &&
                  p._familyDefaults &&
                  p._familyDefaults.defaultMaterials
              );

              if (familyProducts.length > 0) {
                console.log(
                  `Found ${familyProducts.length} products with settings for family ${actualFamily}`
                );
                const familyProduct = familyProducts[0];
                actualFamilyDefaults =
                  familyProduct._familyDefaults.defaultMaterials || {};
                actualFamilyTypeLinks =
                  familyProduct._familyDefaults.typeLinks || [];
              } else {
                console.warn(
                  `No products found with settings for family ${actualFamily}`
                );
              }
            } catch (err) {
              console.error(
                `Failed to load settings for family ${actualFamily}:`,
                err
              );
            }
          } else {
            // If we're using the product's own family, use its settings
            actualFamilyDefaults = productFamilyDefaults;
            actualFamilyTypeLinks = productFamilyTypeLinks;
          }

          // CRITICAL: Now that we have the correct settings, update the material manager
          if (actualFamily) {
            materialManager.setFamilySettings(actualFamily, {
              types: actualFamilyDefaults,
              typeLinks: actualFamilyTypeLinks,
            });
          }

          // Get the current global configuration
          const globalConfig =
            await emersyaViewer.getCurrentGlobalConfiguration();

          // Get the current project data
          const currentProject = Object.keys(globalConfig.projectsData)[0];
          const projectData = globalConfig.projectsData[currentProject];

          // Get configurable materials from the viewer
          const configurableMaterials =
            await emersyaViewer.getProductNodeInstanceConfigurableMaterials({
              localId: globalConfig.sceneGraph.localId,
            });

          console.log(
            "Fetched configurableMaterials for product:",
            productData.name,
            "code:",
            productData.code,
            configurableMaterials
          );

          // Initialize material manager with viewer materials - using correct family and product code
          // CRITICAL: Always use the code from the product data we just loaded
          console.log(
            `Initializing material manager with correct family: ${actualFamily}, product: ${productData.code}`
          );

          materialManager.initialize(
            configurableMaterials,
            actualFamily,
            productData.code
          );

          // Get available materials with family/product settings applied
          // CRITICAL: Always use the code from the product data we just loaded
          const availableMaterials = materialManager.getAvailableMaterials(
            actualFamily,
            productData.code
          );

          console.log(
            "Enriched and overridden materials for product:",
            productData.code,
            availableMaterials
          );

          // Clear any previous material selections to avoid conflicts
          setSelectedMaterials({});
          setSelectedOptionalMaterials([]);
          setPriceModification(0);
          setShowOptionalMaterialNotice(false);

          // Update viewer materials with the fresh data
          setViewerMaterials(availableMaterials);

          // Set default materials if available in the project data
          if (projectData?.materialTrees) {
            setDefaultMaterials(projectData);
          }

          // Set camera to front view and start rotation
          emersyaViewer.setViewpoint({
            name: "dfbfront",
            transitionTime: 500,
            reinitializeZoomLimits: true,
          });

          setTimeout(() => {
            emersyaViewer.play();
          }, 1500);

          // Mark as done loading
          setLoading(false);

          // Reset loading flag to allow future initializations
          viewerLoadingRef.current = false;
        } catch (error) {
          console.error("Error initializing materials:", error);
          setLoading(false);
          viewerLoadingRef.current = false;
        }
      }
    });
  };

  // Set default materials from project data
  const setDefaultMaterials = (projectData) => {
    if (!projectData?.materialTrees) return;

    // Only set default materials if we don't already have selections
    // This prevents overriding user selections
    if (Object.keys(selectedMaterials).length > 0) {
      console.log(
        "Keeping existing material selections, not applying defaults"
      );
      return;
    }

    console.log(
      "Setting default materials from project data for product:",
      currentProductCodeRef.current
    );

    const newSelected = {};

    Object.entries(projectData.materialTrees).forEach(([key, value]) => {
      if (value) {
        // Find matching material in our database
        const material = materialManager.findMaterialByName(value);

        if (material) {
          newSelected[key] = {
            slug: value, // Use exact name as slug for matching with viewer
            name: value, // Keep the exact name
            thumbnail: material.thumbnail || "",
            viewerName: value, // Important: Keep the exact name for the viewer
          };
        } else {
          // If not found, use as is
          newSelected[key] = {
            slug: value,
            name: value,
            thumbnail: "",
            viewerName: value,
          };
        }
      }
    });

    setSelectedMaterials(newSelected);

    // Position camera and start rotation
    const emersyaViewer = window.emViewers["emersyaIframe"];
    emersyaViewer.setViewpoint({
      name: "dfbfront",
      transitionTime: 500,
      reinitializeZoomLimits: true,
    });

    setTimeout(() => {
      emersyaViewer.play();
    }, 1500);
  };

  // Handle family change
  const handleFamilyChange = useCallback((newFamily) => {
    console.log(`Changing family to: ${newFamily}`);

    // Important: Update the family state and ref BEFORE finding a product
    setSelectedFamily(newFamily);
    // Update the ref
    lastSelectedFamilyRef.current = newFamily;

    // DO NOT AUTO-SELECT PRODUCT (removed that functionality)
    // This allows the user to choose which product they want from the family

    // Clear material selections when family changes
    setSelectedMaterials({});
    setSelectedOptionalMaterials([]);
    setPriceModification(0);
    setShowOptionalMaterialNotice(false);
  }, []);

  // Handle product change
  const handleProductChange = useCallback(
    (newProductCode) => {
      if (!newProductCode) return;
      console.log(`Changing product to: ${newProductCode}`);

      // Prevent unnecessary re-renders if product is already selected
      if (newProductCode === selectedProductCode) {
        console.log("Product already selected, skipping change");
        return;
      }

      // Update our ref to track the current product code
      currentProductCodeRef.current = newProductCode;
      console.log(`Updated currentProductCodeRef to: ${newProductCode}`);

      // Always reset material selections when product changes
      setSelectedMaterials({});
      setSelectedOptionalMaterials([]);
      setPriceModification(0);
      setShowOptionalMaterialNotice(false);
      setConfigurableMaterialOnClick(null);

      // Reset viewer loading flag
      viewerLoadingRef.current = false;

      // Set the new product code first - this will trigger the loadProductData effect
      setSelectedProductCode(newProductCode);
    },
    [selectedProductCode]
  );

  // Handle material change
  const handleMaterialChange = useCallback(
    async (technicalName, newMaterial) => {
      console.log(`Changing material for ${technicalName}:`, newMaterial.name);
      console.log(
        `Current family: ${selectedFamily}, Current product: ${selectedProductCode}`
      );

      // IMPORTANT: Force a small delay before processing to ensure all state is available
      // This helps with the first-click issue
      await new Promise((resolve) => setTimeout(resolve, 10));

      // Create a copy of the current materials state to work with
      const updatedMaterials = { ...selectedMaterials };

      // Add the initially selected material
      updatedMaterials[technicalName] = newMaterial;

      // Apply material change to the 3D model using switchConfiguration
      switchConfiguration(newMaterial, technicalName);

      // Remove any highlights
      if (window.emViewers && window.emViewers["emersyaIframe"]) {
        window.emViewers["emersyaIframe"].setHighlight({
          configurableMaterials: [""],
        });
      }

      // Check if we have valid family settings for the current family
      const currentFamilySettings =
        materialManager.familySettings[selectedFamily];

      if (!currentFamilySettings) {
        console.warn(`No family settings found for ${selectedFamily}`);
        // Try to find settings with case-insensitive match
        const lowerFamily = selectedFamily.toLowerCase();
        let foundMatch = false;

        for (const family in materialManager.familySettings) {
          if (family.toLowerCase() === lowerFamily) {
            console.log(`Found family settings with different case: ${family}`);

            // Use these settings instead
            useTypeLinksFromFamily(
              family,
              technicalName,
              newMaterial,
              updatedMaterials
            );
            foundMatch = true;
            break;
          }
        }

        if (!foundMatch) {
          console.warn(
            `No family settings found for ${selectedFamily}, even with case-insensitive match`
          );
        }
      } else {
        // Use the correctly found family settings
        useTypeLinksFromFamily(
          selectedFamily,
          technicalName,
          newMaterial,
          updatedMaterials
        );
      }

      // Update the state with all changes at once
      setSelectedMaterials(updatedMaterials);

      // Handle optional materials tracking for price modifications
      updateOptionalMaterialsTracking(
        technicalName,
        newMaterial,
        updatedMaterials
      );
    },
    [selectedFamily, selectedProductCode, selectedMaterials]
  );

  // Helper function to process type links for a family
  const useTypeLinksFromFamily = (
    family,
    technicalName,
    newMaterial,
    updatedMaterials
  ) => {
    // Get type links from family settings
    const typeLinks = materialManager.familySettings[family]?.typeLinks || [];

    // Process all type links that include the changed type
    for (const link of typeLinks) {
      console.log(
        `Checking link ${link.name || "unnamed"} with types:`,
        link.linkedTechnicalNames
      );

      // Skip if this link doesn't include our type
      if (
        !link.linkedTechnicalNames ||
        !link.linkedTechnicalNames.includes(technicalName)
      ) {
        continue;
      }

      // Helper function to normalize strings for comparison
      const normalize = (str) =>
        String(str || "")
          .toLowerCase()
          .replace(/\s+/g, "")
          .replace(/-/g, "");

      // For each other type in this link
      for (const linkedType of link.linkedTechnicalNames) {
        // Skip the current type - we only want to update other types
        if (linkedType === technicalName) {
          continue;
        }

        // Find the material type in viewer materials
        const linkedTypeData = viewerMaterials.find(
          (m) => m.technicalName === linkedType
        );
        if (!linkedTypeData) {
          console.log(
            `Linked type ${linkedType} not found in viewer materials`
          );
          continue;
        }

        // Try to find the same material by name in the linked type
        let matchingMaterial = null;

        // First try exact name match
        matchingMaterial = linkedTypeData.variations.find(
          (m) => m.name === newMaterial.name
        );

        // If no match, try normalized name match
        if (!matchingMaterial) {
          const normalizedNewMaterialName = normalize(newMaterial.name);
          matchingMaterial = linkedTypeData.variations.find(
            (m) => normalize(m.name) === normalizedNewMaterialName
          );
        }

        if (matchingMaterial) {
          console.log(
            `Found matching material ${matchingMaterial.name} in type ${linkedType}`
          );

          // Copy the original material to avoid reference issues
          const linkedMaterial = { ...matchingMaterial };

          // IMPORTANT: Preserve the standard/optional status from the source material
          linkedMaterial.standard = newMaterial.standard;
          linkedMaterial.optional = newMaterial.optional;

          // Add to our updated materials batch
          updatedMaterials[linkedType] = linkedMaterial;

          // Apply to 3D model immediately
          switchConfiguration(linkedMaterial, linkedType);
        } else {
          console.log(`No matching material found in type ${linkedType}`);
        }
      }
    }
  };

  // Helper to update optional materials tracking
  const updateOptionalMaterialsTracking = (
    technicalName,
    newMaterial,
    updatedMaterials
  ) => {
    const currentMaterial = selectedMaterials[technicalName];

    const isOptional = newMaterial.optional || false;
    const wasOptional = currentMaterial?.optional || false;

    // Create a new batch of optional materials
    let newOptionalMaterials = [...selectedOptionalMaterials];
    let optionalMaterialsChanged = false;

    // Handle the selected material
    if (isOptional && (!currentMaterial || !wasOptional)) {
      console.log(`Adding ${newMaterial.name} to optional materials list`);
      // Add to optional materials list if not already present
      if (
        !newOptionalMaterials.some(
          (item) =>
            item.type === technicalName &&
            item.material.name === newMaterial.name
        )
      ) {
        newOptionalMaterials.push({
          type: technicalName,
          material: newMaterial,
        });
        optionalMaterialsChanged = true;
      }
    } else if (!isOptional && currentMaterial && wasOptional) {
      console.log(
        `Removing ${currentMaterial.name} from optional materials list`
      );
      // Remove from optional materials list
      newOptionalMaterials = newOptionalMaterials.filter(
        (item) =>
          !(
            item.type === technicalName &&
            item.material.name === currentMaterial.name
          )
      );
      optionalMaterialsChanged = true;
    }

    // Process linked materials for optional status
    for (const [type, material] of Object.entries(updatedMaterials)) {
      if (type !== technicalName) {
        // Skip the original material we just handled
        if (material.optional) {
          // Add if not already in the list
          if (
            !newOptionalMaterials.some(
              (item) =>
                item.type === type && item.material.name === material.name
            )
          ) {
            newOptionalMaterials.push({ type, material });
            optionalMaterialsChanged = true;
          }
        } else {
          // Remove if in the list
          const removeIndex = newOptionalMaterials.findIndex(
            (item) => item.type === type && item.material.name === material.name
          );
          if (removeIndex >= 0) {
            newOptionalMaterials.splice(removeIndex, 1);
            optionalMaterialsChanged = true;
          }
        }
      }
    }

    // Only update state if necessary to avoid re-renders
    if (optionalMaterialsChanged) {
      console.log("Updated optional materials:", newOptionalMaterials);
      setSelectedOptionalMaterials(newOptionalMaterials);
    }
  };

  // Calculate price with optional materials
  const calculateTotalPrice = useCallback(() => {
    if (!selectedProduct || !selectedProduct.price) return 0;

    // If we have optional materials, add 15% to the price
    if (selectedOptionalMaterials.length > 0) {
      const basePrice = selectedProduct.price;
      const surcharge = basePrice * 0.15;
      return basePrice + surcharge;
    }

    return selectedProduct.price;
  }, [selectedProduct, selectedOptionalMaterials]);

  // Update price modification and notice when optional materials or product changes
  useEffect(() => {
    // Ensure we have a valid product and price
    if (!selectedProduct || typeof selectedProduct.price !== "number") {
      setPriceModification(0);
      setShowOptionalMaterialNotice(false);
      return;
    }

    // Calculate surcharge if we have optional materials
    if (selectedOptionalMaterials.length > 0) {
      const surcharge = selectedProduct.price * 0.15;
      setPriceModification(surcharge);
      setShowOptionalMaterialNotice(true);
    } else {
      setPriceModification(0);
      setShowOptionalMaterialNotice(false);
    }
  }, [selectedOptionalMaterials, selectedProduct]);

  // Close contact modal if no optional materials are selected
  useEffect(() => {
    if (showContactModal && selectedOptionalMaterials.length === 0) {
      setShowContactModal(false);
    }
  }, [selectedOptionalMaterials, showContactModal]);

  return (
    <div className="ay-text-base ay-leading-[1]">
      {loading && (
        <div
          className="ay-w-full ay-h-screen ay-bg-white ay-opacity-95 ay-fixed ay-top-0 ay-z-50 ay-flex ay-justify-center ay-items-center ay-text-3xl"
          id="loader"
        >
          <img
            src={logo}
            alt="Logo dieffebi"
            className="ay-animate-scale-pulse"
          />
        </div>
      )}

      {isAdmin ? (
        <AdminPanel
          products={products}
          setProducts={setProducts}
          selectedProductCode={selectedProductCode}
          onProductChange={handleProductChange}
        />
      ) : (
        <div className="ay-flex ay-flex-col md:ay-flex-row ay-h-screen ay-overflow-hidden">
          <div
            className={`ay-relative ${
              isAccordionHidden ? "ay-w-full" : "ay-w-full md:ay-w-4/5"
            } ay-transition-all ay-duration-500 ay-ease-in-out`}
          >
            {/* 3D Viewer Component */}
            <Player
              selectedProductCode={selectedProductCode}
              selectedProduct={selectedProduct}
            />

            {/* Toggle button for accordion */}
            <button
              onClick={toggleAccordionVisibility}
              className={`ay-absolute ay-transition-transform ay-duration-300 ${
                isAccordionHidden ? "ay-rotate-180" : ""
              } ay-right-4 ay-opacity-20 ay-top-1/2 ay-transform ay--translate-y-1/2 ay-text-3xl ay-font-bold ay-focus:ay-outline-none`}
            >
              <FaChevronRight />
            </button>
          </div>

          {/* Configurator Panel */}
          <div
            className={`ay-p-4 ay-bg-white ay-overflow-auto ${
              isAccordionHidden ? "ay-w-0" : "ay-w-full md:ay-w-3/5 lg:ay-w-2/6"
            } ay-transition-all ay-duration-500 ay-ease-in-out`}
            style={{ display: isAccordionHidden ? "none" : "block" }}
          >
            {/* Product Family Selector */}
            <FamilySelector
              products={products}
              selectedFamily={selectedFamily}
              onFamilyChange={handleFamilyChange}
              onProductChange={handleProductChange}
              selectedProduct={selectedProduct}
            />

            {/* Product Selector (Family Accordion) */}
            <FamilyAccordion
              products={products}
              selectedProduct={selectedProduct}
              onProductChange={handleProductChange}
              selectedFamily={selectedFamily}
            />

            {/* Material Configuration Accordions */}
            <div>
              {/* Material Accordions */}
              {viewerMaterials
                .filter((mat) => mat.variations.length >= 2)
                .map((mat) => {
                  // Get standard and optional materials for this type
                  const standardMaterials = mat.variations
                    .filter((v) => v.standard)
                    .map((v) => v.slug);

                  const optionalMaterials = mat.variations
                    .filter((v) => v.optional)
                    .map((v) => v.slug);

                  return (
                    <Accordion
                      key={mat.technicalName}
                      title={mat.displayLabel || mat.technicalName}
                      variations={mat.variations}
                      selected={selectedMaterials[mat.technicalName]}
                      onChange={(newMat) =>
                        handleMaterialChange(mat.technicalName, newMat)
                      }
                      standardMaterials={standardMaterials}
                      optionalMaterials={optionalMaterials}
                    />
                  );
                })}

              {/* Price information */}
              {selectedProduct &&
                typeof selectedProduct.price === "number" &&
                selectedProduct.price > 0 && (
                  <PriceInfo
                    key={`price-${selectedProduct.code}`}
                    selectedProduct={selectedProduct}
                    priceModification={priceModification}
                    showOptionalMaterialNotice={showOptionalMaterialNotice}
                    setShowContactModal={setShowContactModal}
                    totalPrice={calculateTotalPrice()}
                  />
                )}
            </div>
          </div>
        </div>
      )}

      {/* Contact Modal for Optional Materials */}
      <ContactModal
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
        selectedProduct={selectedProduct}
        selectedOptionalMaterials={selectedOptionalMaterials}
      />
    </div>
  );
}

const PriceInfo = ({
  selectedProduct,
  priceModification,
  showOptionalMaterialNotice,
  setShowContactModal,
  totalPrice,
}) => {
  if (
    !selectedProduct ||
    typeof selectedProduct.price !== "number" ||
    selectedProduct.price <= 0
  )
    return null;

  const basePrice = selectedProduct.price;

  return (
    <div className="ay-mb-4 ay-mt-10 ay-bg-white ay-rounded-md ay-shadow-sm ay-overflow-hidden">
      {/* Intestazione del prezzo */}
      <div className="ay-py-3 ay-border-b ay-border-black/80 ay-bg-white">
        <div className="ay-flex ay-justify-between ay-items-center">
          <h3 className="ay-font-medium ay-text-gray-700">Preventivo</h3>
          <div className="ay-text-right">
            <p className="ay-text-xl ay-font-bold ay-text-gray-800">
              €{totalPrice.toLocaleString()}
            </p>
          </div>
        </div>
      </div>

      {/* Dettagli del prezzo */}
      {showOptionalMaterialNotice && (
        <div className="ay-p-3 ay-bg-gray-50">
          <div className="ay-flex ay-justify-between ay-items-center ay-text-sm ay-mb-1">
            <span className="ay-text-gray-600">Prezzo Base:</span>
            <span className="ay-font-medium">
              €{basePrice.toLocaleString()}
            </span>
          </div>
          <div className="ay-flex ay-justify-between ay-items-center ay-text-sm">
            <span className="ay-text-gray-600">Sovrapprezzo (15%):</span>
            <span className="ay-font-medium">
              €{priceModification.toLocaleString()}
            </span>
          </div>
        </div>
      )}

      {/* Avviso materiali opzionali */}
      {showOptionalMaterialNotice && (
        <div className="ay-p-3 ay-border-t ay-border-black/80">
          <div className="ay-flex ay-justify-between ay-items-center">
            <p className="ay-text-xs ay-text-gray-500">
              Il sovrapprezzo per materiali opzionali è fisso al 15% del prezzo
              base.
            </p>
            <button
              onClick={() => setShowContactModal(true)}
              className="ay-px-3 ay-py-1 ay-bg-black ay-text-white ay-text-xs ay-hover:ay-bg-blue-700 ay-transition-colors"
            >
              Richiedi Preventivo
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
