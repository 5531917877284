import React, { useState, useRef, useEffect, useCallback } from "react";
import placeholder from "./assets/placeholder.jpg";
import { AiOutlinePlus } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";

const ITEMS_PER_BATCH = 15;
const FETCH_DEBOUNCE_TIME = 500; // ms

function FamilyAccordion({
  products,
  selectedProduct,
  onProductChange,
  selectedFamily,
}) {
  // State initialization
  const [isOpen, setIsOpen] = useState(false);
  const [previewImages, setPreviewImages] = useState({});
  const [loadingStates, setLoadingStates] = useState({});
  const [failedImages, setFailedImages] = useState({});
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [displayProductName, setDisplayProductName] = useState(
    selectedProduct?.name || "Seleziona modello"
  );
  const [contentHeight, setContentHeight] = useState(0);

  // Refs
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);
  const lastClickTimeRef = useRef(0);
  const fetchQueueRef = useRef(new Set());
  const fetchTimeoutRef = useRef(null);
  const prevFamilyRef = useRef(selectedFamily);
  const toggleClickRef = useRef(false);

  // Compute current family products
  const currentFamilyProducts = React.useMemo(() => {
    // Check for both family and _family properties
    const family =
      selectedFamily ||
      selectedProduct?._family ||
      selectedProduct?.family ||
      "";
    console.log(
      "Filtering products for family:",
      family,
      "Selected product:",
      selectedProduct?.name
    );

    if (family === "") {
      return products;
    }

    // Filter considering both family and _family properties
    return products.filter(
      (product) => product._family === family || product.family === family
    );
  }, [products, selectedFamily, selectedProduct]);

  // Add a useEffect to update display name when family changes
  useEffect(() => {
    if (selectedFamily) {
      // Reset visible products when family changes
      setVisibleProducts([]);
      // Clear fetch queue
      fetchQueueRef.current.clear();
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    }
  }, [selectedFamily]);

  // Update content height when isOpen changes or content changes
  useEffect(() => {
    if (isOpen && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [isOpen, visibleProducts]);

  // Memoized fetch function with error handling
  const fetchProductImage = useCallback(async (product) => {
    if (
      !product?.code ||
      loadingStates[product.code] ||
      previewImages[product.code] ||
      failedImages[product.code]
    ) {
      return;
    }

    setLoadingStates((prev) => ({ ...prev, [product.code]: true }));

    try {
      const productUrl = `https://emersya.com/jsShowcase/${product.code}?container=emersyaIframe`;
      const response = await fetch(productUrl);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.text();
      const viewerPreviewImageMatch = data.match(
        /src="(https:\/\/cdn\.emersya\.com\/cl\/[^"]+\/preview\.png\?date=[^"]+)"/
      );

      if (viewerPreviewImageMatch) {
        setPreviewImages((prev) => ({
          ...prev,
          [product.code]: viewerPreviewImageMatch[1],
        }));
      } else {
        setFailedImages((prev) => ({ ...prev, [product.code]: true }));
      }
    } catch (error) {
      console.warn(
        `Failed to fetch preview for ${product.code}:`,
        error.message
      );
      setFailedImages((prev) => ({ ...prev, [product.code]: true }));
    } finally {
      setLoadingStates((prev) => {
        const newState = { ...prev };
        delete newState[product.code];
        return newState;
      });
    }
  }, []);

  // Debounced batch fetching
  const processFetchQueue = useCallback(() => {
    const queue = Array.from(fetchQueueRef.current);
    fetchQueueRef.current.clear();

    queue.forEach((product) => {
      fetchProductImage(product);
    });
  }, [fetchProductImage]);

  const queueProductFetch = useCallback(
    (product) => {
      if (!product?.code) return;

      fetchQueueRef.current.add(product);

      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }

      fetchTimeoutRef.current = setTimeout(() => {
        processFetchQueue();
      }, FETCH_DEBOUNCE_TIME);
    },
    [processFetchQueue]
  );

  // Handle family change
  useEffect(() => {
    if (selectedFamily !== prevFamilyRef.current) {
      setVisibleProducts([]);
      fetchQueueRef.current.clear();
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
      prevFamilyRef.current = selectedFamily;
    }
  }, [selectedFamily]);

  // Load initial products when dropdown opens
  useEffect(() => {
    if (
      isOpen &&
      currentFamilyProducts.length > 0 &&
      visibleProducts.length === 0
    ) {
      const initialBatch = currentFamilyProducts.slice(0, ITEMS_PER_BATCH);
      setVisibleProducts(initialBatch);

      initialBatch.forEach((product) => {
        if (!previewImages[product.code] && !failedImages[product.code]) {
          queueProductFetch(product);
        }
      });
    }
  }, [
    isOpen,
    currentFamilyProducts,
    queueProductFetch,
    previewImages,
    failedImages,
  ]);

  // Handle infinite scroll
  useEffect(() => {
    if (!isOpen || !dropdownRef.current) return;

    const handleScroll = () => {
      const dropdown = dropdownRef.current;
      if (!dropdown) return;

      const scrollTrigger = dropdown.querySelector(".scroll-trigger");
      if (!scrollTrigger) return;

      const rect = scrollTrigger.getBoundingClientRect();
      const dropdownRect = dropdown.getBoundingClientRect();

      if (rect.top <= dropdownRect.bottom + 100) {
        const currentLength = visibleProducts.length;
        if (currentLength < currentFamilyProducts.length) {
          const nextBatch = currentFamilyProducts.slice(
            currentLength,
            currentLength + ITEMS_PER_BATCH
          );
          setVisibleProducts((prev) => [...prev, ...nextBatch]);

          nextBatch.forEach((product) => {
            if (!previewImages[product.code] && !failedImages[product.code]) {
              queueProductFetch(product);
            }
          });
        }
      }
    };

    const dropdown = dropdownRef.current;
    dropdown.addEventListener("scroll", handleScroll);

    return () => {
      if (dropdown) {
        dropdown.removeEventListener("scroll", handleScroll);
      }
    };
  }, [
    isOpen,
    visibleProducts,
    currentFamilyProducts,
    queueProductFetch,
    previewImages,
    failedImages,
  ]);

  // Handle click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (!toggleClickRef.current) {
          setIsOpen(false);
        }
        toggleClickRef.current = false;
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleClickRef.current = true;
    setIsOpen((prev) => !prev);
  }, []);

  // Update display name
  useEffect(() => {
    if (currentFamilyProducts.length > 0) {
      const firstProduct = currentFamilyProducts[0];
      setDisplayProductName(
        firstProduct.name +
          (firstProduct.id ? ` - cod. ${firstProduct.id}` : "")
      );
    } else {
      setDisplayProductName("Seleziona modello");
    }
  }, [currentFamilyProducts]);

  const handleProductChange = useCallback(
    (e, product) => {
      e.preventDefault();
      e.stopPropagation();

      const now = Date.now();
      if (now - lastClickTimeRef.current < 1000) {
        return;
      }
      lastClickTimeRef.current = now;

      if (product.code !== selectedProduct?.code) {
        onProductChange(product.code);
        setTimeout(() => {
          setIsOpen(false);
        }, 50);
      }
    },
    [selectedProduct?.code, onProductChange]
  );

  // Generate full product display text for tooltip
  const getProductFullText = useCallback((product) => {
    return `${product.name}${product.id ? ` - cod. ${product.id}` : ""}`;
  }, []);

  return (
    <div className="ay-w-full ay-py-2 ay-border-b ay-border-black/80">
      <div
        className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-cursor-pointer"
        onClick={handleToggleClick}
      >
        {/* Header section with aligned title and selected product */}
        <div className="ay-flex-grow">
          <div className="ay-flex ay-items-center ay-justify-between">
            <h2 className="ay-text-lg ay-font-serif ay-capitalize">
              Modello:{" "}
              {selectedProduct?.name ? (
                <span className="ay-text-base ay-font-medium ay-text-black/40 ay-ml-1">
                  {selectedProduct.name}
                  {selectedProduct.id ? ` - cod. ${selectedProduct.id}` : ""}
                </span>
              ) : (
                <span className="ay-text-base ay-font-medium ay-text-black/40 ay-ml-1">
                  Seleziona modello
                </span>
              )}
            </h2>
            <div className="ay-flex ay-items-center ay-justify-center ay-w-6 ay-h-6">
              <span
                className={`ay-transition-transform ay-text-sm ay-duration-300 ay-ease-in-out ${
                  isOpen ? "ay-rotate-45" : "ay-rotate-0"
                }`}
              >
                <AiOutlinePlus />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Accordion Content with animation */}
      <div
        ref={contentRef}
        className="ay-overflow-hidden ay-transition-all ay-duration-300 ay-ease-in-out"
        style={{ maxHeight: `${contentHeight}px`, opacity: isOpen ? 1 : 0 }}
      >
        <div
          className="ay-mt-4 ay-transform ay-transition-transform ay-duration-300 ay-ease-in-out"
          style={{ transform: isOpen ? "translateY(0)" : "translateY(-10px)" }}
        >
          <div
            ref={dropdownRef}
            className="ay-w-full ay-mt-1 ay-divide-y-2 ay-bg-white ay-shadow-lg ay-max-h-[70vh] ay-overflow-auto"
          >
            {visibleProducts.map((product, index) => {
              const isSelected = product.code === selectedProduct?.code;
              const isLoading = loadingStates[product.code];
              const hasFailed = failedImages[product.code];
              const fullText = getProductFullText(product);

              return (
                <div
                  key={`${product.code}-${index}`}
                  className={`ay-py-2 ay-flex ay-items-center ${
                    isSelected
                      ? "ay-bg-gray-100 ay-cursor-not-allowed"
                      : "ay-cursor-pointer hover:ay-bg-gray-50"
                  }`}
                  onClick={(e) => handleProductChange(e, product)}
                  title={fullText}
                >
                  <div className="ay-relative ay-min-w-[48px] ay-w-12 ay-h-12 ay-mr-4 ay-bg-gray-50 ay-flex-shrink-0 ay-flex ay-items-center ay-justify-center  ay-overflow-hidden">
                    {isLoading ? (
                      <div className="ay-absolute ay-inset-0 ay-flex ay-items-center ay-justify-center">
                        <FaSpinner className="ay-animate-spin ay-text-gray-500 ay-w-6 ay-h-6" />
                      </div>
                    ) : previewImages[product.code] ? (
                      <img
                        src={previewImages[product.code]}
                        alt={product.name}
                        className="ay-w-full ay-h-full ay-object-contain"
                      />
                    ) : (
                      <img
                        src={placeholder}
                        alt={product.name}
                        className="ay-w-full ay-h-full ay-object-contain"
                      />
                    )}
                  </div>
                  <div className="ay-flex ay-flex-col ay-text-sm ay-min-w-0 ay-flex-1">
                    <span className="ay-truncate  ay-font-medium">
                      {product.name}
                      {product.id ? ` - cod. ${product.id}` : ""}
                    </span>
                    {isSelected && (
                      <span className="ay-text-xs ay-text-gray-500">
                        Selezionato ora
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
            {visibleProducts.length < currentFamilyProducts.length && (
              <div className="scroll-trigger ay-h-4" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FamilyAccordion;
