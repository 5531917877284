// AdminPanel.js
import React, { useState, useEffect } from "react";
import { fetchProducts, fetchMaterialLinks, fetchCategories } from "./api";
import ProductsTab from "./components/admin/ProductsTab";
import MaterialsTab from "./components/admin/MaterialsTab";
import MaterialLinksSelector from "./components/admin/MaterialLinksIntegration";
import ViewsTab from "./components/admin/ViewsTab";
import DashboardTab from "./components/admin/DashboardTab";
import logo from "./assets/logo-dieffebi.svg";

const AdminPanel = ({ selectedProductCode, onProductChange }) => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [materialLinks, setMaterialLinks] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Load initial data
  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      try {
        const [productsData, categoriesData, linksData] = await Promise.all([
          fetchProducts(),
          fetchCategories(),
          fetchMaterialLinks(),
        ]);

        setProducts(productsData);
        setCategories(categoriesData);
        setMaterialLinks(linksData);
      } catch (error) {
        console.error("Failed to load initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, []);

  // Find the selected product when product code changes
  useEffect(() => {
    if (selectedProductCode && products.length > 0) {
      const product = products.find((p) => p.code === selectedProductCode);
      setSelectedProduct(product || null);
    } else {
      setSelectedProduct(null);
    }
  }, [selectedProductCode, products]);

  // Handle tab switching
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  // Loading overlay component
  const LoadingOverlay = () => (
    <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
      <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-64">
        <div className="ay-animate-spin ay-w-12 ay-h-12 ay-border-4 ay-border-blue-500 ay-border-t-transparent ay-rounded-full ay-mx-auto"></div>
        <p className="ay-mt-4 ay-text-center ay-text-lg">Loading...</p>
      </div>
    </div>
  );

  return (
    <div className="ay-min-h-screen ay-bg-gray-50">
      {/* Loading Overlay */}
      {loading && <LoadingOverlay />}

      <div className="ay-container ay-mx-auto ay-p-4 ay-max-w-full">
        {/* Header with logo and title */}
        <div className="ay-flex ay-justify-between ay-items-center ay-mb-6 ay-bg-white ay-p-4 ay-rounded-lg ay-shadow">
          <div className="ay-flex ay-items-center ay-gap-4">
            <img src={logo} alt="Logo" className="ay-h-12" />
            <h1 className="ay-text-2xl ay-font-bold">Admin Panel</h1>
          </div>

          {/* Exit button to return to configurator */}
          <button
            onClick={() => (window.location.href = "/")}
            className="ay-px-4 ay-py-2 ay-bg-red-500 ay-text-white ay-rounded ay-hover:ay-bg-red-600 ay-transition"
          >
            Exit Admin Mode
          </button>
        </div>

        {/* Tabs */}
        <div className="ay-flex ay-mb-6 ay-bg-white ay-rounded-lg ay-shadow ay-overflow-x-auto">
          <button
            className={`ay-px-6 ay-py-4 ay-font-medium ay-whitespace-nowrap ${
              activeTab === "dashboard"
                ? "ay-border-b-2 ay-border-blue-500 ay-text-blue-600"
                : "ay-text-gray-500 hover:ay-text-gray-700"
            }`}
            onClick={() => handleTabChange("dashboard")}
          >
            Dashboard
          </button>
          <button
            className={`ay-px-6 ay-py-4 ay-font-medium ay-whitespace-nowrap ${
              activeTab === "products"
                ? "ay-border-b-2 ay-border-blue-500 ay-text-blue-600"
                : "ay-text-gray-500 hover:ay-text-gray-700"
            }`}
            onClick={() => handleTabChange("products")}
          >
            Products
          </button>
          <button
            className={`ay-px-6 ay-py-4 ay-font-medium ay-whitespace-nowrap ${
              activeTab === "materials"
                ? "ay-border-b-2 ay-border-blue-500 ay-text-blue-600"
                : "ay-text-gray-500 hover:ay-text-gray-700"
            }`}
            onClick={() => handleTabChange("materials")}
          >
            Materials
          </button>
          <button
            className={`ay-px-6 ay-py-4 ay-font-medium ay-whitespace-nowrap ${
              activeTab === "materialLinks"
                ? "ay-border-b-2 ay-border-blue-500 ay-text-blue-600"
                : "ay-text-gray-500 hover:ay-text-gray-700"
            }`}
            onClick={() => handleTabChange("materialLinks")}
          >
            Material Links
          </button>
          <button
            className={`ay-px-6 ay-py-4 ay-font-medium ay-whitespace-nowrap ${
              activeTab === "views"
                ? "ay-border-b-2 ay-border-blue-500 ay-text-blue-600"
                : "ay-text-gray-500 hover:ay-text-gray-700"
            }`}
            onClick={() => handleTabChange("views")}
            disabled={!selectedProductCode}
          >
            Custom Views
          </button>
        </div>

        {/* Tab Content */}
        <div className="ay-bg-white ay-rounded-lg ay-shadow ay-p-6">
          {activeTab === "dashboard" && (
            <DashboardTab
              products={products}
              categories={categories}
              materialLinks={materialLinks}
              setLoading={setLoading}
            />
          )}

          {activeTab === "products" && (
            <ProductsTab
              products={products}
              setProducts={setProducts}
              categories={categories}
              setCategories={setCategories}
              onProductChange={onProductChange}
              setLoading={setLoading}
            />
          )}

          {activeTab === "materials" && (
            <MaterialsTab
              products={products}
              setProducts={setProducts}
              setLoading={setLoading}
            />
          )}

          {activeTab === "materialLinks" && (
            <MaterialLinksSelector
              materialLinks={materialLinks}
              setMaterialLinks={setMaterialLinks}
              setLoading={setLoading}
            />
          )}

          {activeTab === "views" && selectedProductCode && (
            <ViewsTab
              selectedProductCode={selectedProductCode}
              selectedProduct={selectedProduct}
              setLoading={setLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
