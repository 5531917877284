// index.jsx
import "./index.css";
import App from "./App.js";
import { createRoot } from "react-dom/client";

const root = createRoot(document.querySelector("#ayea-player"));

// Get product ID from the hidden input
const productIdElement = document.getElementById("ayea-product-id");
const productId = productIdElement ? productIdElement.value : null;

// Check if we're in admin mode (via URL parameter or document URL)
const urlParams = new URLSearchParams(window.location.search);
const isAdminMode =
  urlParams.get("admin") === "true" ||
  window.location.href.includes("/admin.html");

root.render(<App productId={productId} isAdmin={isAdminMode} />);

/**
 * Metodo supplementare per casistiche in cui l'embed non viene effettuato tramite semplice tag
 */
window.mountAyeaPlayer = function mountAyeaPlayer() {
    // Check if the container exists
    const mountNode = document.getElementById("ayea-player");
    if (!mountNode) return;

    // We could read the product ID if needed
    const productId = document.getElementById("ayea-product-id")?.value;

    // Mount
    const modalPlayer = createRoot(mountNode);
    modalPlayer.render(<App productId={productId} isAdmin={false}/>);
};