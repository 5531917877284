// components/admin/CategoryForm.js
import React, { useState, useEffect } from "react";

const CategoryForm = ({ category, isEditing, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: "",
  });

  // Initialize form data if editing
  useEffect(() => {
    if (isEditing && category) {
      setFormData({
        name: category,
      });
    }
  }, [isEditing, category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
      <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-md">
        <h2 className="ay-text-xl ay-font-bold ay-mb-4">
          {isEditing ? "Edit Category" : "Add Category"}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="ay-mb-4">
            <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
              Category Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-focus:ay-outline-none ay-focus:ay-ring-blue-500 ay-focus:ay-border-blue-500"
              required
            />
          </div>

          <div className="ay-flex ay-justify-end ay-gap-3 ay-mt-6">
            <button
              type="button"
              onClick={onCancel}
              className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700 ay-hover:ay-bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-shadow-sm ay-bg-blue-500 ay-text-white ay-hover:ay-bg-blue-600"
            >
              {isEditing ? "Update" : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
