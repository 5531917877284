// api.js
// Dynamically determine the API base URL
const isProduction = true;

// Use absolute URL for production, relative path for development
const API_BASE_URL = isProduction
  ? "https://dieffebi-demo.ensoul.works/api"
  : "/api-proxy";

// Upload API
export const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("thumbnail", file);

    const response = await fetch(`${API_BASE_URL}/upload.php`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Upload error response:", errorText);
      throw new Error("Failed to upload file");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

// Products API
export const fetchProducts = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php?flat=true`);
    if (!response.ok) {
      throw new Error("Failed to fetch products");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchProductStructure = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php?structure=true`);
    if (!response.ok) {
      throw new Error("Failed to fetch product structure");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchCategories = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`);
    if (!response.ok) {
      throw new Error("Failed to fetch categories");
    }
    const data = await response.json();
    return Object.keys(data.categories || {});
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchCategory = async (categoryName) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/products.php?category=${categoryName}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch category");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchFamily = async (familyName) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/products.php?family=${familyName}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch family");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchProductByCode = async (code) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php?code=${code}`);
    if (!response.ok) {
      throw new Error("Failed to fetch product");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const addCategory = async (name) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "addCategory",
        name: name,
      }),
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to add category");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updateCategory = async (originalName, newName) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "updateCategory",
        originalName: originalName,
        name: newName,
      }),
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to update category");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const deleteCategory = async (name) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "deleteCategory",
        name: name,
      }),
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to delete category");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const addFamily = async (categoryName, familyName, defaultData = {}) => {
  try {
    const payload = {
      action: "addFamily",
      category: categoryName,
      name: familyName,
    };

    if (defaultData.defaultMaterialLinks) {
      payload.defaultMaterialLinks = defaultData.defaultMaterialLinks;
    }

    if (defaultData.defaultMaterials) {
      payload.defaultMaterials = defaultData.defaultMaterials;
    }

    // Add typeLinks to payload
    if (defaultData.typeLinks) {
      payload.typeLinks = defaultData.typeLinks;
    }

    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to add family");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updateFamily = async (
  categoryName,
  originalName,
  newName,
  defaultData = {}
) => {
  try {
    const payload = {
      action: "updateFamily",
      category: categoryName,
      originalName: originalName,
      name: newName,
    };

    if (defaultData.defaultMaterialLinks) {
      payload.defaultMaterialLinks = defaultData.defaultMaterialLinks;
    }

    if (defaultData.defaultMaterials) {
      payload.defaultMaterials = defaultData.defaultMaterials;
    }

    // Add typeLinks to payload
    if (defaultData.typeLinks) {
      payload.typeLinks = defaultData.typeLinks;
    }

    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to update family");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const deleteFamily = async (categoryName, familyName) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "deleteFamily",
        category: categoryName,
        name: familyName,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to delete family");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const addProduct = async (productData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productData),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to add product");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updateProduct = async (productData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productData),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to update product");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const deleteProduct = async (code) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to delete product");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

// Materials API
export const fetchMaterials = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/materials.php`);
    if (!response.ok) {
      throw new Error("Failed to fetch materials");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchMaterialsByTechnicalType = async (technicalType) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/materials.php?technicalType=${technicalType}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch materials");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchMaterialById = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/materials.php?id=${id}`);
    if (!response.ok) {
      throw new Error("Failed to fetch material");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchTechnicalTypes = async () => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/materials.php?technicalTypes=true`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch technical types");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const addMaterial = async (materialData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/materials.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(materialData),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to add material");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updateMaterial = async (materialData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/materials.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(materialData),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to update material");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const deleteMaterial = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/materials.php`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to delete material");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const importMaterials = async (materialsObject) => {
  try {
    const response = await fetch(`${API_BASE_URL}/materials.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "import",
        materials: materialsObject,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to import materials");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

// Material Links API
export const fetchMaterialLinks = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/material-links.php`);
    if (!response.ok) {
      throw new Error("Failed to fetch material links");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchMaterialLinksByTechnicalName = async (technicalName) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/material-links.php?technicalName=${technicalName}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch material links");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const fetchMaterialLinkById = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/material-links.php?id=${id}`);
    if (!response.ok) {
      throw new Error("Failed to fetch material link");
    }
    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const addMaterialLink = async (linkData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/material-links.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(linkData),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to add material link");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updateMaterialLink = async (linkData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/material-links.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(linkData),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to update material link");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const deleteMaterialLink = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/material-links.php`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to delete material link");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

// Custom Views API - keeping the existing implementation
export const fetchProductViews = async (productCode) => {
  try {
    // First try the dedicated views endpoint
    const response = await fetch(
      `${API_BASE_URL}/views.php?code=${productCode}`
    );

    if (!response.ok) {
      // If views.php fails, try to get views from the product directly
      const productResponse = await fetch(
        `${API_BASE_URL}/products.php?code=${productCode}`
      );

      if (!productResponse.ok) {
        throw new Error("Failed to fetch product views");
      }

      const product = await productResponse.json();
      return product.customViews || [];
    }

    return await response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const addProductView = async (productCode, viewData) => {
  try {
    // First try the dedicated views endpoint
    const response = await fetch(`${API_BASE_URL}/views.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productCode,
        view: viewData,
      }),
    });

    if (!response.ok) {
      // If views.php fails, update the product directly
      const productResponse = await fetch(
        `${API_BASE_URL}/products.php?code=${productCode}`
      );

      if (!productResponse.ok) {
        throw new Error("Failed to fetch product for view update");
      }

      const product = await productResponse.json();

      if (!product.customViews) {
        product.customViews = [];
      }

      // Check for duplicate view label
      if (product.customViews.some((view) => view.label === viewData.label)) {
        throw new Error("View with this label already exists");
      }

      product.customViews.push(viewData);

      // Update the product
      const updateResponse = await fetch(`${API_BASE_URL}/products.php`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
      });

      if (!updateResponse.ok) {
        throw new Error("Failed to update product with new view");
      }

      return { success: true, view: viewData };
    }

    return await response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updateProductView = async (productCode, viewLabel, viewData) => {
  try {
    // First try the dedicated views endpoint
    const response = await fetch(`${API_BASE_URL}/views.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productCode,
        viewLabel,
        view: viewData,
      }),
    });

    if (!response.ok) {
      // If views.php fails, update the product directly
      const productResponse = await fetch(
        `${API_BASE_URL}/products.php?code=${productCode}`
      );

      if (!productResponse.ok) {
        throw new Error("Failed to fetch product for view update");
      }

      const product = await productResponse.json();

      if (!product.customViews) {
        product.customViews = [];
      }

      // Find and update the view
      const viewIndex = product.customViews.findIndex(
        (view) => view.label === viewLabel
      );

      if (viewIndex === -1) {
        throw new Error("View not found");
      }

      product.customViews[viewIndex] = viewData;

      // Update the product
      const updateResponse = await fetch(`${API_BASE_URL}/products.php`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
      });

      if (!updateResponse.ok) {
        throw new Error("Failed to update product with modified view");
      }

      return { success: true, view: viewData };
    }

    return await response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const deleteProductView = async (productCode, viewLabel) => {
  try {
    // First try the dedicated views endpoint
    const response = await fetch(`${API_BASE_URL}/views.php`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productCode,
        viewLabel,
      }),
    });

    if (!response.ok) {
      // If views.php fails, update the product directly
      const productResponse = await fetch(
        `${API_BASE_URL}/products.php?code=${productCode}`
      );

      if (!productResponse.ok) {
        throw new Error("Failed to fetch product for view deletion");
      }

      const product = await productResponse.json();

      if (!product.customViews) {
        return { success: true }; // No views to delete
      }

      // Remove the view
      product.customViews = product.customViews.filter(
        (view) => view.label !== viewLabel
      );

      // Update the product
      const updateResponse = await fetch(`${API_BASE_URL}/products.php`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
      });

      if (!updateResponse.ok) {
        throw new Error("Failed to update product after view deletion");
      }

      return { success: true };
    }

    return await response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

// Reorder categories
export const reorderCategories = async (categoryOrder) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "reorderCategories",
        categoryOrder: categoryOrder,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to reorder categories");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

// Reorder families within a category
export const reorderFamilies = async (categoryName, familyOrder) => {
  try {
    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "reorderFamilies",
        category: categoryName,
        familyOrder: familyOrder,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to reorder families");
    }

    return response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

// Reorder products within a family
export const reorderProducts = async (
  categoryName,
  familyName,
  productOrder
) => {
  try {
    // Log the request info for debugging
    console.log("API reorderProducts request:", {
      categoryName,
      familyName,
      productOrderCount: productOrder.length,
    });

    const response = await fetch(`${API_BASE_URL}/products.php`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "reorderProducts",
        category: categoryName,
        family: familyName,
        productOrder: productOrder,
      }),
    });

    // If the response is not OK, try to provide detailed error information
    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server error response:", errorText);

      try {
        // Try to parse as JSON
        const error = JSON.parse(errorText);
        throw new Error(error.error || "Failed to reorder products");
      } catch (parseError) {
        // If parsing fails, return the raw text
        throw new Error(`Failed to reorder products: ${errorText}`);
      }
    }

    const result = await response.json();
    console.log("API reorderProducts success:", result);
    return result;
  } catch (error) {
    console.error("API Error in reorderProducts:", error);
    throw error;
  }
};
