// components/admin/TypeLinksComponent.js
import React, { useState, useEffect } from "react";

const TypeLinksComponent = ({
  customTypes,
  onChange,
  setLoading,
  initialLinks = [],
}) => {
  // State for type links
  const [typeLinks, setTypeLinks] = useState(initialLinks);

  // UI state
  const [showLinkForm, setShowLinkForm] = useState(false);
  const [editingLinkIndex, setEditingLinkIndex] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    name: "",
    linkedTechnicalNames: [],
  });

  // Initialize from props
  useEffect(() => {
    // This ensures we properly update the typeLinks from props
    if (
      initialLinks &&
      JSON.stringify(initialLinks) !== JSON.stringify(typeLinks)
    ) {
      setTypeLinks(initialLinks);
    }
  }, [initialLinks]);

  // Pass changes up to parent component, but only when typeLinks actually changes
  useEffect(() => {
    if (onChange && typeLinks !== initialLinks) {
      onChange(typeLinks);
    }
  }, [typeLinks, onChange, initialLinks]);

  // Check if link form is valid
  const isLinkFormValid = () => {
    return formData.name && formData.linkedTechnicalNames.length >= 2;
  };

  // Add a new link
  const handleAddLink = () => {
    setEditingLinkIndex(null);
    setFormData({
      name: "",
      linkedTechnicalNames: [],
    });
    setShowLinkForm(true);
  };

  // Edit an existing link
  const handleEditLink = (index) => {
    setEditingLinkIndex(index);
    setFormData({ ...typeLinks[index] });
    setShowLinkForm(true);
  };

  // Delete a link
  const handleDeleteLink = (index) => {
    if (window.confirm("Are you sure you want to delete this link?")) {
      const updatedLinks = [...typeLinks];
      updatedLinks.splice(index, 1);
      setTypeLinks(updatedLinks);
    }
  };

  // Handle input change in the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle checking/unchecking technical types
  const handleTypeToggle = (technicalName) => {
    setFormData((prev) => {
      const linkedTechnicalNames = [...prev.linkedTechnicalNames];
      const index = linkedTechnicalNames.indexOf(technicalName);

      if (index === -1) {
        linkedTechnicalNames.push(technicalName);
      } else {
        linkedTechnicalNames.splice(index, 1);
      }

      return {
        ...prev,
        linkedTechnicalNames,
      };
    });
  };

  // Save the form data
  const handleSaveForm = () => {
    if (!isLinkFormValid()) {
      alert("Please enter a name and select at least two technical types.");
      return;
    }

    const updatedLinks = [...typeLinks];

    if (editingLinkIndex !== null) {
      updatedLinks[editingLinkIndex] = formData;
    } else {
      updatedLinks.push(formData);
    }

    setTypeLinks(updatedLinks);
    setShowLinkForm(false);
  };

  return (
    <div>
      <div className="ay-flex ay-justify-between ay-items-center ay-mb-4">
        <h3 className="ay-text-lg ay-font-medium">Type Links</h3>
        <button
          onClick={handleAddLink}
          className="ay-px-3 ay-py-1 ay-bg-blue-500 ay-text-white ay-rounded ay-text-sm"
        >
          Add Link
        </button>
      </div>

      {/* Type Links List */}
      {typeLinks.length > 0 ? (
        <div className="ay-space-y-4">
          {typeLinks.map((link, index) => (
            <div
              key={index}
              className="ay-border ay-rounded-lg ay-p-4 ay-bg-white ay-shadow-sm"
            >
              <div className="ay-flex ay-justify-between ay-items-start">
                <div>
                  <h4 className="ay-font-medium ay-text-lg">{link.name}</h4>
                  <div className="ay-flex ay-flex-wrap ay-gap-1 ay-mt-1">
                    {link.linkedTechnicalNames.map((type) => {
                      const typeObj = customTypes.find(
                        (t) => t.technicalName === type
                      );
                      return (
                        <span
                          key={type}
                          className="ay-px-2 ay-py-1 ay-text-xs ay-bg-blue-100 ay-text-blue-800 ay-rounded"
                        >
                          {typeObj?.displayLabel || type}
                        </span>
                      );
                    })}
                  </div>
                </div>
                <div className="ay-flex ay-gap-2">
                  <button
                    onClick={() => handleEditLink(index)}
                    className="ay-px-2 ay-py-1 ay-text-xs ay-bg-yellow-500 ay-text-white ay-rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteLink(index)}
                    className="ay-px-2 ay-py-1 ay-text-xs ay-bg-red-500 ay-text-white ay-rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div className="ay-mt-3 ay-pt-2 ay-border-t ay-border-gray-200">
                <p className="ay-text-sm ay-text-gray-500">
                  This link will automatically apply the same material to all
                  linked types.
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="ay-bg-gray-50 ay-p-6 ay-rounded-lg ay-text-center ay-text-gray-500">
          No type links defined. Click "Add Link" to create your first type
          link.
        </div>
      )}

      {/* Link Form Modal */}
      {showLinkForm && (
        <div className="ay-fixed ay-inset-0 ay-bg-black ay-bg-opacity-50 ay-flex ay-items-center ay-justify-center ay-z-50">
          <div className="ay-bg-white ay-p-6 ay-rounded-lg ay-shadow-lg ay-w-full ay-max-w-4xl ay-max-h-screen ay-overflow-auto">
            <h3 className="ay-text-lg ay-font-bold ay-mb-4">
              {editingLinkIndex !== null ? "Edit" : "Add"} Type Link
            </h3>

            <div className="ay-mb-4">
              <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                Link Name *
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="ay-block ay-w-full ay-px-3 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm"
                required
              />
            </div>

            {/* Technical Types Selection */}
            <div className="ay-mb-4">
              <label className="ay-block ay-text-sm ay-font-medium ay-text-gray-700 ay-mb-1">
                Linked Technical Types *
              </label>
              <p className="ay-text-xs ay-text-gray-500 ay-mb-2">
                Select at least two technical types to link together. When the
                same material is available for these types, selecting it for one
                type will automatically apply it to all linked types.
              </p>

              <div className="ay-grid ay-grid-cols-2 md:ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-2 ay-p-3 ay-border ay-border-gray-300 ay-rounded-md">
                {customTypes.map((type) => (
                  <label
                    key={type.technicalName}
                    className="ay-flex ay-items-center ay-gap-2 ay-cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      checked={formData.linkedTechnicalNames.includes(
                        type.technicalName
                      )}
                      onChange={() => handleTypeToggle(type.technicalName)}
                      className="ay-h-4 ay-w-4 ay-text-blue-600 ay-rounded"
                    />
                    <span>{type.displayLabel || type.technicalName}</span>
                  </label>
                ))}

                {customTypes.length === 0 && (
                  <div className="ay-col-span-full ay-text-center ay-py-4 ay-text-gray-500">
                    No technical types available. Add material types first.
                  </div>
                )}
              </div>

              {formData.linkedTechnicalNames.length < 2 && (
                <p className="ay-text-xs ay-text-red-500 ay-mt-1">
                  Please select at least two technical types.
                </p>
              )}
            </div>

            <div className="ay-flex ay-justify-end ay-gap-3 ay-mt-6">
              <button
                type="button"
                onClick={() => setShowLinkForm(false)}
                className="ay-px-4 ay-py-2 ay-border ay-border-gray-300 ay-rounded-md ay-shadow-sm ay-bg-white ay-text-gray-700"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSaveForm}
                className="ay-px-4 ay-py-2 ay-border ay-border-transparent ay-rounded-md ay-shadow-sm ay-bg-blue-500 ay-text-white"
                disabled={!isLinkFormValid()}
              >
                {editingLinkIndex !== null ? "Update" : "Create"} Link
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TypeLinksComponent;
